import React, {useContext, useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "./style.css";
import { AssessmentContext } from "../../../pages/CreateAssessment/CreateAssessment"; // Import your context

import { courses } from "../data";

const ChooseAssessment = () => {
  const {assessmentData ,  updateAssessment} = useContext(AssessmentContext);
  
  
  useEffect(()=>{
      console.log(assessmentData);
  },[assessmentData])


  
  const [selectedCourse, setSelectedCourse] = useState(courses[0]);
  
  
  const onSelectCourse = (course) =>{
    updateAssessment({
      ...assessmentData,
      course
    })
  }


  return (
    <div className=''>
      <Swiper
        // direction="rtl"
        spaceBetween={15}
        // slidesPerGroupSkip={4}
        slidesPerView={"auto"}
        className='mySwiper w-100 '
      >
        {courses && courses.length >= 1 && Array.isArray(courses) ? (
          courses?.map((item, index) => {
            return (
              <SwiperSlide key={index} style={{width: "fit-content"}}>
                <div
                  className={`assessment_title ${
                    selectedCourse.title == item.title ? "active" : ""
                  }`}
                  onClick={() => setSelectedCourse(item)
                    
                  }
                >
                  {item.title}
                </div>
              </SwiperSlide>
            );
          })
        ) : (
          <div className='text-dark fs-6'>{"No Data"}</div>
        )}
      </Swiper>

        <div className={`assessmet_course_container`}>
            {
              selectedCourse?.courses?.map((item , index)=>{
                return(
                  <div  onClick={()=> onSelectCourse(item)} key={index} className={`assessment_course_level  ${assessmentData.course.id == item.id ? "active" : "" }`}>
                      <p>{selectedCourse.title}</p>
                      <h3>{item.level}</h3>
                  </div>
                )
              })
            }
        </div>


        


    </div>
  );
};

export default ChooseAssessment;
