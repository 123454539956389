import React from "react";
import "./style.css";

const QuizCard = ({data , onClick}) => {
  return (
    /* From Uiverse.io by vk-uiux */
    <div class='quiz_card' onClick={onClick}>
     <div className='wave_1'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='300'
            height='150'
            viewBox='0 0 1920 1080'
          >
            <path
              fill="#87d281"
              d='M724 4c2.333 108.213-54.232 210.731-130.154 286.343-75.922 75.611-211.058 99.029-325.376 167.326C154.152 525.967-13.913 690.72-92.063 700.13c-78.15 9.41-24.934-105.671-108.367-185.998-83.432-80.327-318.856-193.12-392.228-295.962-73.371-102.842-73.27-203.876-48-321.092C-615.387-220.14-539.13-409.731-441.03-485.131c98.1-75.4 266.282-80.687 388.968-70.194 122.687 10.493 241.831 100.42 347.15 133.152 105.318 32.732 213.274-7.788 284.76 63.24C651.331-287.903 721.666-104.213 724 4c2.333 108.213-54.232 210.731-130.154 286.343'
            ></path>
          </svg>
        </div>

      <h4>{data.name}</h4>
      <div className='row quiz_card_info'>
        <div className='col'>
          <div>Questions</div>
          <div className='mt-3'>{data.questions}</div>
        </div>
        <div className='col'>
          <div>Duration</div>
          <div className='mt-3'>{data.duration}</div>
        </div>
      </div>
    </div>
  );
};

export default QuizCard;
