import React from "react";
import "./style.css";
import SubjectsCard from "../../SubjcectsCard/SubjectsCard";
import { starIcon, Stars, videoIcon, wavyArrow, wavySVG } from "../../../assets/svgIcons";

import illustration from "./Learning-amico.svg";

import img from "../../../assets/images/video tutorial-13.jpg";
import { video } from './../../../assets/svgIcons/index';

import {
  BIO1,
  BIO2,
  mathIcon1,
  mathIcon2,
  chemist1,
  chemist2,
  phys2,
  phys1,
  psycho,
  economy1,
  economy2,
  business1,
  business2,
  env1,
  env2,
  english1,
  english2,
} from "./svgs";

const SubjectsSection = () => {
  const subjects = [
    {
      id: 1,
      title: "IB Mathematics",
      bg_color: "#E6E2FE",
      fill: "#D6CFFD",
      icon_1: mathIcon1,
      icon_2: mathIcon2,
      items: [
        {
          name: "AA SL",
          pathname: "/course",
        },
        {
          name: "AA HL",
          pathname: "/course",
        },
        {
          name: "AL SL",
          pathname: "/course",
        },
        {
          name: "AL HL",
          pathname: "/course",
        },
      ],
    },
    {
      id: 2,
      title: "IB Chemistry",
      icon_1: BIO1,
      icon_2: BIO2,
      bg_color: "#FDCFFD",
      fill: "#FCBCFC",
      items: [
        {
          name: "SL - 2024",
          pathname: "/course",
        },

        {
          name: "HL - 2024",
          pathname: "/course",
        },
        {
          name: "SL - 2025",
          pathname: "/course",
        },
        {
          name: "HL - 2025",
          pathname: "/course",
        },
      ],
    },
    {
      id: 3,
      icon_1: chemist1,
      icon_2: chemist2,
      title: "IB Chemistry",
      fill: "#A6F4C5",
      bg_color: "#ECFEF3",
      items: [
        {
          name: "SL - 2024",
          pathname: "/course",
        },

        {
          name: "HL - 2024",
          pathname: "/course",
        },
        {
          name: "SL - 2025",
          pathname: "/course",
        },
        {
          name: "HL - 2025",
          pathname: "/course",
        },
      ],
    },
    {
      id: 4,
      icon_1: phys1,
      icon_2: phys2,
      title: "IB Chemistry",
      fill: "#A6F4C5",
      bg_color: "#ECFEF3",
      items: [
        {
          name: "SL - 2024",
          pathname: "/course",
        },

        {
          name: "HL - 2024",
          pathname: "/course",
        },
        {
          name: "SL - 2025",
          pathname: "/course",
        },
        {
          name: "HL - 2025",
          pathname: "/course",
        },
      ],
    },
    {
      id: 5,
      icon_1: psycho,
      icon_2: psycho,
      title: "IB Psychology",
      fill: "#DBBCFC",
      bg_color: "#E5CFFD",
      items: [
        {
          name: "SL",
          pathname: "/course",
        },

        {
          name: "HL ",
          pathname: "/course",
        },
      ],
    },
    {
      id: 6,
      icon_1: economy1,
      icon_2: economy2,
      title: "IB Economics",
      fill: "#FCBCFC",
      bg_color: "#FDCFFD",
      items: [
        {
          name: "SL - 2024",
          pathname: "/course",
        },

        {
          name: "HL - 2024",
          pathname: "/course",
        },
      ],
    },
    {
      id: 7,
      icon_1: business1,
      icon_2: business2,
      title: "Business Management",
      fill: "#FCC8BB",
      bg_color: "#FDD7CE",
      items: [
        {
          name: "SL ",
          pathname: "/course",
        },

        {
          name: "HL ",
          pathname: "/course",
        },
      ],
    },
    {
      id: 7,
      icon_1: env1,
      icon_2: env2,
      title: "IB Environmental Systems and Societies",
      fill: "#A6F4C5",
      bg_color: "#ECFEF3",
      items: [
        {
          name: "SL - 2025 ",
          pathname: "/course",
        },
      ],
    },
    {
      id: 8,
      icon_1: english1,
      icon_2: english2,
      title: "IB English",
      fill: "#A6F4C5",
      bg_color: "#ECFEF3",
      items: [
        {
          name: "LangLit SL ",
          pathname: "/course",
        },
        {
          name: "LangLit HL ",
          pathname: "/course",
        },
        {
          name: "Lit SL ",
          pathname: "/course",
        },
        {
          name: "Lit HL ",
          pathname: "/course",
        },
      ],
    },
  ];

  return (
    <div className='subj_sections_container'>
      <div className='wave_1'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='900'
          height='600'
          viewBox='0 0 1920 1080'
        >
          <path
            fill='hsla(0, 0.00%, 90.60%, 0.96)'
            d='M724 4c2.333 108.213-54.232 210.731-130.154 286.343-75.922 75.611-211.058 99.029-325.376 167.326C154.152 525.967-13.913 690.72-92.063 700.13c-78.15 9.41-24.934-105.671-108.367-185.998-83.432-80.327-318.856-193.12-392.228-295.962-73.371-102.842-73.27-203.876-48-321.092C-615.387-220.14-539.13-409.731-441.03-485.131c98.1-75.4 266.282-80.687 388.968-70.194 122.687 10.493 241.831 100.42 347.15 133.152 105.318 32.732 213.274-7.788 284.76 63.24C651.331-287.903 721.666-104.213 724 4c2.333 108.213-54.232 210.731-130.154 286.343'
          ></path>
        </svg>
      </div>

      <div className='container banner_content'
        data-aos='zoom-in'
      >
        <div
          className='section_top'
        >
          <h1 className=''>
            The best resource for IB Mathematics just got better!
          </h1>
          <h4>
            Now available: IB Maths, Physics, Chemistry, Biology, ESS, Business
            Management, Economics, Psychology, and English!
          </h4>
          <p>
            We are excited to announce the expansion of our highly acclaimed IB resources! Whether you're a student aiming for top grades or a teacher looking for the best materials to support your students, our comprehensive and user-friendly resources are now available for an even wider range of subjects.
          </p>
          <h5>Voted #1 IB Mathematics Resource by IB Teachers & Students</h5>
          {/* <div className='start fs-3 text-warning d-flex align-items-center gap-2'>
            {starIcon}
            {starIcon}
            {starIcon}
            {starIcon}
          </div> */}
          <div className='main_button justify-content-start '>
            <button className="mt-0 btn btn-success"> Get started </button>
            <div className="vid_icon">{videoIcon}</div>
          </div>
        </div>

        <div
          className='section_right'
        >
          <img src={illustration} alt='Logo' />
        </div>
      </div>

      {/* 
        <div className='subjects'>
          <>
            <div className='wavy_arrow'>
              <div className='hand-font'>
                pick a
                <br />
                subject
              </div>
              {wavyArrow}
            </div>
            {subjects.map((subj, index) => {
              return (
                  <SubjectsCard key={index} index={index} data={subj} />
                )
            })}
          </>
        </div> */}
    </div>
  );
};

export default SubjectsSection;
