import './style.css';
import { useState } from 'react';
import faqData from './faqData'; // Adjust the import path as needed

export default function IBMFrequentlyAsked() {
    const [expanded, setExpanded] = useState(null);

    const handleToggle = (index) => {
        setExpanded(expanded === index ? null : index);
    };

    return (
        <div className='faq_container'>
            <h2 style={{ width: "fit-content", margin: "20px auto", color:"green", fontSize:"58px" }}>FAQ</h2>
            <div className='faq_box_container'>
                {faqData.map((item, index) => (
                    <div key={index} className={`faq_box ${expanded === index ? 'open' : ''}`}>
                        <div className="question" onClick={() => handleToggle(index)}>
                            <span>{expanded === index ? "-" : "+"}</span>
                            <h3>{item.question}</h3>
                        </div>
                        <div className='answer'>
                            <p>{item.answer}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
