import React, {useContext, useEffect, useState} from "react";
import "./style.css";
import {Steps} from "antd";
import SearchInput from "./../../SearchInput/SearchInput";
import CheckBox from "./../../CheckBox/CheckBox";
import {courses, topics} from "./../data";
import {AssessmentContext} from "../../../pages/CreateAssessment/CreateAssessment"; // Import your context

const AssessmentSelectSubtopics = () => {
  const {assessmentData, updateAssessment} = useContext(AssessmentContext);
  const [topicsState, setTopicsState] = useState(topics);
  const [topicsSearch, setTopicsSearch] = useState("");
  const [branchesSearch, setBranchesSearch] = useState("");
  const [clickedTopic, setClickedTopic] = useState({});
  const [filteredTopicBranches, setFilteredTopicBranches] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);

  useEffect(() => {
    setFilteredTopicBranches(clickedTopic.branches);
  }, [clickedTopic]);

  const steps = [
    {
      title: "Setup",
      description: "",
    },
    {
      title: "Select subtopics",
      description: "",
    },
    {
      title: "Select questions",
      description: "",
    },
    {
      title: "Review",
      description: "",
    },
    {
      title: "Summery",
      description: "",
    },
  ];

  const onSelectTopic = (e, topic) => {
    console.log(topic);
    if (e.target.checked) {
      updateAssessment({
        ...assessmentData,
        selectedTopicBranches: [...assessmentData.selectedTopicBranches, topic],
      });
    } else {
      const newData = assessmentData.selectedTopicBranches.filter(
        (item) => item?.topic !== topic?.topic
      );
      console.log(newData);
      updateAssessment({
        ...assessmentData,
        selectedTopicBranches: newData,
      });
    }
  };

  const onSelectTopicBranch = (e, branch) => {
    console.log(branch)
    const newData = topics?.find((topic) => {
      const data = topic.branches.find(
        (branchData) => branchData.id == branch.id
      );
      if (data) {
        return topic;
      }
    });
    console.log(newData);
    // return

    if(e.target.checked){
      const newAssessmentsData = assessmentData?.selectedTopicBranches.map(
        (topic) => {
          if (topic?.topic == newData?.topic) {
            const newBranchesData = topic.branches
            newBranchesData.push(branch)
            
            return {
              ...topic,
              branches: newBranchesData
            };
          }
          return topic;
        }
      );


      console.log(newAssessmentsData);
      // return

      updateAssessment({
        ...assessmentData,
        selectedTopicBranches: newAssessmentsData,
      });

      

    }else{
        const newAssessmentsData = assessmentData?.selectedTopicBranches.map(
          (topic) => {
            if (topic?.topic == newData?.topic) {
              const newBranchesData = topic.branches.filter(
                (branchData) => branch.id !== branchData.id
              );
              return {
                ...topic,
                branches: newBranchesData,
              };
            }
            return topic;
          }
        );
        updateAssessment({
          ...assessmentData,
          selectedTopicBranches: newAssessmentsData,
        });

    }

  };

  useEffect(() => {
    console.log(assessmentData);
  }, [assessmentData]);

  // topics search filterations
  useEffect(() => {
    if (topics && topics.length >= 1 && Array.isArray(topics)) {
      if (topicsSearch.length >= 1) {
        const newData = topics.filter((item) => {
          if (
            topicsSearch.length >= 1 &&
            !item.topic.toLowerCase().includes(topicsSearch.toLowerCase())
          ) {
            return false;
          }

          return true;
        });

        setTopicsState(newData);
      } else {
        setTopicsState(topics);
      }
    }
  }, [topicsSearch]);

  // branch search filterations
  useEffect(() => {
    if (
      clickedTopic.branches &&
      clickedTopic.branches.length >= 1 &&
      Array.isArray(clickedTopic.branches)
    ) {
      if (branchesSearch.length >= 1) {
        const newData = clickedTopic.branches.filter((item) => {
          if (
            branchesSearch.length >= 1 &&
            !item.topic.toLowerCase().includes(branchesSearch.toLowerCase())
          ) {
            return false;
          }
          return true;
        });
        setFilteredTopicBranches(newData);
      } else {
        setFilteredTopicBranches(clickedTopic.branches);
      }
    }
  }, [branchesSearch, clickedTopic]);

  return (
    <div className='row'>
      <div className='col-2'>
        {/* <Stepper options={options} antd={true} /> */}
        <Steps
          className='custom-steps'
          current={1}
          // onChange={(value) => setCurrent(value)}
          direction='vertical'
          items={steps}
        />
      </div>

      <div className='col-10'>
        <div className='ass_select_subtopics row'>
          <div className='col-4  border-end border-3'>
            <SearchInput
              value={topicsSearch}
              onChange={(e) => setTopicsSearch(e.target.value)}
              placeholder={"Search topics"}
            />
            <div className='topics'>
              {topicsState?.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => setClickedTopic(item)}
                    className={`d-flex align-items-center gap-2 px-2 py-1 rounded topic ${
                      item.topic == clickedTopic.topic ? "active" : ""
                    }`}
                  >
                    <CheckBox
                      checked={assessmentData?.selectedTopicBranches.find(
                        (topic) => item?.topic == topic?.topic
                      )}
                      onChange={(e) => onSelectTopic(e, item)}
                      id={item?.topic}
                    />
                    <div className='sttt text-dark'>{item?.topic}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='col-8'>
            <SearchInput
              value={branchesSearch}
              onChange={(e) => setBranchesSearch(e.target.value)}
              placeholder={"Search suptopics"}
            />
            <div className='topics'>
              {filteredTopicBranches?.map((branch, index) => {
                return (
                  <div
                    key={index}
                    className='d-flex align-items-center gap-2 px-2 py-1 rounded topic'
                  >
                    <CheckBox
                      checked={assessmentData?.selectedTopicBranches
                        ?.map((item) => item?.branches)
                        .flat(2)
                        .find((item) => item?.id === branch?.id)}
                      onChange={(e) => {
                        onSelectTopicBranch(e, branch);

                        console.log(
                          assessmentData?.selectedTopicBranches
                            .map((item) => item?.branches)
                            
                          // .find((item) => item.id == branch.id)
                        );
                      }}
                      id={branch.id}
                    />
                    <div className='sttt text-dark'>{branch.topic}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentSelectSubtopics;
