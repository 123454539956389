import React from 'react';
import './whyus.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { subjects } from './whychooseusdata';

function Whyus() {
  return (
    <div className="container-unique">
      <h1 style={{ margin: "20px auto", width: "fit-content", color: "green", fontWeight: "900" }}>Why Choose Our Extended Essay Resources?</h1>
      <h1 className="headline-amazing">Elevate Your Extended Essay with Our Expert Resources!</h1>
      <h4 className="subheadline-incredible">
        Explore a range of resources designed specifically for the Extended Essay in subjects like History, Biology, Literature, and more!
      </h4>
      <h5 className="award-winning">
        Voted #1 Extended Essay Resource by IB Teachers & Students
      </h5>
      <p className="description-detailed">
        We're thrilled to introduce our dedicated resources for the Extended Essay! Whether you're a student striving for academic excellence or a teacher aiming to guide your students through this critical component, our expertly crafted materials will provide the support you need to succeed.
      </p>
      <div className="card-grid">
        {subjects.map((subject, index) => (
          <div className="card" key={index}>
            <FontAwesomeIcon icon={subject.icon} className="card-icon" />
            <p className="cookieHeading"><b>{subject.title}</b></p>
            <p className="cookieDescription">{subject.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Whyus;
