import Teatchers from "../components/HomePage/Teachers/Teatchers";
import CreateAssessment from "../pages/CreateAssessment/CreateAssessment";
import GroupCoursesPage from "../pages/GroupCoursesPage/GroupCoursesPage";
import HomePage from "../pages/HomePage/HomePage";
import IBMath from "../pages/IBMath/IBMath";
import PopularQuizzesPage from "../pages/PopularQuizzesPage/PopularQuizzesPage";
import PracticeExams from "../pages/PracticeExams/PracticeExams";
import Profile from "../pages/profile/profile";
import QuizPage from "../pages/QuizPage/QuizPage";
import TeacherRegister from "../pages/registerTeacher";
import Schools from "../pages/Schools/Schools";
import SetPassword from "../pages/SetPassword/SetPassword";
import SignupPage from "../pages/SignupPage/SignupPage";
import SubjectsPage from "../pages/SubjectsPage/SubjectsPage";

export const routes = [
  {
    path: "/",
    component: <HomePage />,
  },
  {
    path: "/Profile",
    component: <Profile />,
  },
  {
    path: "/course",
    component: <IBMath />,
  },
  {
    path: "/schools",
    component: <Schools />,
  },
  {
    path: "/course/practice_exams",
    component: <PracticeExams />,
  },
  {
    path: "/course/practice_exams/:type",
    component: <PopularQuizzesPage />,
  },
  {
    path: "/course/practice_exams/:type/:topic/:quiz",
    component: <QuizPage />,
  },
  {
    path: "/TeacherRegister",
    component: <TeacherRegister />,
  },
  {
    path: "/signup",
    component: <SignupPage />,
  },
  {
    path: "/set_passwrod",
    component: <SetPassword />,
  },
  {
    path: "/Profile",
    component: <Profile />,
  },
  {
    path: "/subjects/:language/:groupId",
    component: <SubjectsPage />,
  },
  {
    path: "/teachers",
    component: <Teatchers />,
  },
  {
    path: "/group-courses/:groupName",
    component: <GroupCoursesPage />,
  },
  {
    path: "/assesmens/create",
    component: <CreateAssessment />,
  },




];
