import React, {useState} from "react";
import TeacherBanner from "../../../components/Teachers/TeacherBanner/TeacherBanner";
import TeachersCards from "../../../components/Teachers/TeachersCards/TeachersCards";
import TeacherCard2 from "../../../components/Teachers/TeacherCard2/TeacherCard2";

export default function Teachers() {
  const [teacherData, setTeacherData] = useState({
    country: "",
    subject: "",
  });
  const [searchValue, setSearchValue] = useState("");
  return (
    <div style={{overflow: "hidden"}}>
      <TeacherBanner
        setTeacherData={setTeacherData}
        teacherData={teacherData}
        setSearchValue={setSearchValue}
      />
      <TeachersCards
        setTeacherData={setTeacherData}
        teachers={teacherData}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
      />
    </div>
  );
}
