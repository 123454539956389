import React, {useContext, useState} from "react";
import "./style.css";
import {Collapse, Steps, Tabs, Tag} from "antd";
import CheckBoxContent from "./../../CheckBoxContent/CheckBoxContent";
import ProgressBar from "./../../progressBar/ProgressBar";
import Modal from "../../Modal/Modal";
import VideoPlayer from "../../VideoPlayer/VideoPlayer";
import {AssessmentContext} from "../../../pages/CreateAssessment/CreateAssessment"; // Import your context
import { courses , topics } from "../data";


const AssessmentSelect = () => {
  const onChange = (key) => {
    console.log(key);
  };

  const {assessmentData, updateAssessment} = useContext(AssessmentContext);

  const onSelectTopicBranch = (branch) => {
    updateAssessment({
      ...assessmentData,
      topicBranch: branch,
    });
  };

  const [type, setType] = useState("topic quizzes");
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [expandModal, setExpandModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [selectedVid, setSelectedVid] = useState("");

  const types = [
    {
      label: "Topic Quizzes",
      value: "topic quizzes",
    },
    {
      label: "Revision Ladder",
      value: "revision ladder",
    },
    {
      label: "Mock Exams",
      value: "mock exams",
    },
  ];

  const [current, setCurrent] = useState(1);
  const steps = [
    {
      title: "Setup",
      description: "",
    },
    {
      title: "Select",
      description: "",
    },
    {
      title: "Review",
      description: "",
    },
    {
      title: "Summary",
      description: "",
    },
  ];

  const videos = [
    "https://www.youtube.com/watch?v=vvR3JSXO2fo",
    "https://www.youtube.com/watch?v=ZZxhg0TvPkM",
  ];

  const tabs = [
    {
      key: "1",
      label: "QUESTION",
      children: (
        <div className='mark_scheme_container'>
          <div className=' queston_content_left_side katex_font '>
            <div className=' align-items-center justify-content-between'>
              <div className=''>[Maximum mark: {rowData?.marks}]</div>
            </div>
            <div className='q_title Katex_font'>{rowData?.question}</div>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "MARK SCHEME",
      children: (
        <div className='mark_scheme_container'>
          <div className=' queston_content_left_side katex_font border-end border-3'>
            <div className=' align-items-center justify-content-between'>
              <div className=''>[Maximum mark: {rowData?.marks}]</div>
            </div>
            <div className='q_title Katex_font'>{rowData?.question}</div>
          </div>
          <div className='mark_scheme_content'>
            Here will be the Mark Scheme
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: "VIDEO SOLUTIONS",
      children: (
        <>
          <div className='video_buttons d-flex align-items-center gap-3 my-4'>
            {videos?.map((item, index) => {
              return (
                <button
                  onClick={() => setSelectedVid(item)}
                  className='green_btn fit '
                  key={index}
                >
                  {index + 1}
                </button>
              );
            })}
          </div>
          {selectedVid && <VideoPlayer url={selectedVid} />}
        </>
      ),
    },
  ];

  return (
    <div className='row'>
      <div className='col-2'>
        {/* <Stepper options={options} antd={true} /> */}

        <Steps
          className='custom-steps'
          current={current}
          // onChange={(value) => setCurrent(value)}
          direction='vertical'
          items={steps}
        />
      </div>
      <div className='col-10'>
        <div className='assessment_select row'>
          <div className='topics_side col'>
            <div className='d-flex align-items-center w-100'>
              {types?.map((item, index) => {
                return (
                  <CheckBoxContent
                    key={index}
                    checked={item.value === type}
                    title={item.label}
                    id={item.value}
                    onChange={() => {
                      setType(item.value);
                    }}
                  />
                );
              })}
            </div>
            <Collapse
              accordion
              items={topics.map((item) => ({
                label: item.topic,
                children: (
                  <>
                    {item.branches?.map((branch, index) => {
                      return (
                        <div
                          key={index}
                          className={`topic_branch ${
                            assessmentData.topicBranch.topic == branch.topic
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            onSelectTopicBranch(branch);

                            setSelectedBranch(branch);
                          }}
                        >
                          <div className='sttt topic_name'>{branch.topic}</div>
                          <div className='topic_branch_infos'>
                            <Tag
                              icon={icons.question}
                              className='sttt'
                              color='success'
                            >
                              {branch.questions} questions
                            </Tag>
                            <Tag
                              icon={icons.check}
                              className='sttt'
                              color='processing'
                            >
                              {branch.marks} Marks
                            </Tag>
                            <Tag
                              icon={icons.timer}
                              className='sttt'
                              color='error'
                            >
                              {branch.time_minutes} min
                            </Tag>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ),
              }))}
            />
          </div>
          <div className='questions_side col'>
            <h4 className='sttt fw-boder '>Questions</h4>
            <div className='branch_questions_container '>
              {selectedBranch.questionsData?.map((item, index) => {
                return (
                  <div className='' key={index}>
                    <div className='branch_question '>
                      <div className='w-75 '>{item.question}</div>
                      <div className='w-25 '>
                        <Tag
                          color='geekblue'
                          style={{cursor: "pointer"}}
                          onClick={() => {
                            setExpandModal(true);
                            setRowData(item);
                          }}
                        >
                          {icons.expand}{" "}
                        </Tag>
                        <div className='my-2'></div>
                        <Tag color='geekblue'>{item.marks} marks</Tag>
                        <div className='my-2'></div>
                        <ProgressBar
                          title={<span className='st'> {item.difficulty}</span>}
                          progress={10}
                          bg_container={"#EBF7E8"}
                          bg_progress={"#97C48B"}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <Modal
        size={"90%"}
        show={expandModal}
        title={`Question`}
        showCloseBtn={true}
        animation={true}
        overlay={true}
        onClose={() => {
          setExpandModal(false);
        }}
        children={
          <>
            <Tabs defaultActiveKey='1' items={tabs} onChange={onChange} />
          </>
        }
      />
    </div>
  );
};

export default AssessmentSelect;

const icons = {
  question: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
    >
      <path
        fill='currentColor'
        d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10m-1-7v2h2v-2zm2-1.645A3.502 3.502 0 0 0 12 6.5a3.5 3.5 0 0 0-3.433 2.813l1.962.393A1.5 1.5 0 1 1 12 11.5a1 1 0 0 0-1 1V14h2z'
      ></path>
    </svg>
  ),

  check: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
    >
      <path
        fill='currentColor'
        d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10m-1-7v2h2v-2zm2-1.645A3.502 3.502 0 0 0 12 6.5a3.5 3.5 0 0 0-3.433 2.813l1.962.393A1.5 1.5 0 1 1 12 11.5a1 1 0 0 0-1 1V14h2z'
      ></path>
    </svg>
  ),
  timer: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
    >
      <path
        fill='currentColor'
        d='M6 2h12v6l-4 4l4 4v6H6v-6l4-4l-4-4zm10 14.5l-4-4l-4 4V20h8zm-4-5l4-4V4H8v3.5zM10 6h4v.75l-2 2l-2-2z'
      ></path>
    </svg>
  ),

  expand: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
    >
      <path
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M10 19H5v-5m9-9h5v5'
      ></path>
    </svg>
  ),
};
