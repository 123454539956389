import './style.css';

export default function IBMAwards() {
  return (
    <div className='ibMath_award_container'>
      <h2>Thank you Groot in IB Members</h2>
        <div className='ibMath_award_boxes'>
        <div className='ibMath_award_box'>
            <div className="ibMath_award_icon award_icon_1">
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path d="M5 8.999c0 1.902.765 3.627 2 4.89V21a.998.998 0 0 0 1.447.895L12 20.118l3.553 1.776a.992.992 0 0 0 .972-.043c.295-.183.475-.504.475-.851v-7.11a6.976 6.976 0 0 0 2-4.891C19 5.14 15.86 2 12 2S5 5.14 5 8.999m7.447 9.106a1 1 0 0 0-.895 0L9 19.382v-4.067c.911.434 1.926.685 3 .685s2.089-.25 3-.685v4.066zM12 4c2.756 0 5 2.242 5 4.999A5.006 5.006 0 0 1 12 14c-2.757 0-5-2.243-5-5.001A5.005 5.005 0 0 1 12 4"></path></svg>
            </div>
            <h3>#1 IB Math Resource</h3>
            <p>Groot in IB is ranked the #1 IB Math Resources by IB Students & Teachers.</p>
        </div>
        <div className='ibMath_award_box'>
            <div className="ibMath_award_icon award_icon_2">
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 32 32"><path d="M6 16c2.973 0 4.232-2.251 5.345-4.237C12.475 9.744 13.45 8 16 8s3.526 1.744 4.655 3.763C21.768 13.749 23.027 16 26 16h4v-2h-4c-1.713 0-2.483-1.22-3.6-3.214C21.209 8.654 19.724 6 16 6s-5.207 2.654-6.4 4.786C8.482 12.779 7.712 14 6 14H4V2H2v26a2 2 0 0 0 2 2h26v-2h-2v-6h-2v6h-4v-8h-2v8h-4V16h-2v12h-4v-8H8v8H4V16Z"></path></svg>
            </div>
            <h3>34% Grade Increase</h3>
            <p>Groot in IB students scored 34% greater than the IB Global Average in their exams (2021).</p>
        </div>
        <div className='ibMath_award_box'>
            <div className="ibMath_award_icon award_icon_3">
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 32 32"><path d="M16 4a5 5 0 1 1-5 5a5 5 0 0 1 5-5m0-2a7 7 0 1 0 7 7a7 7 0 0 0-7-7m10 28h-2v-5a5 5 0 0 0-5-5h-6a5 5 0 0 0-5 5v5H6v-5a7 7 0 0 1 7-7h6a7 7 0 0 1 7 7z"></path></svg>
            </div>
            <h3>80% of IB Students</h3>
            <p>More and more IB students are using Groot in IB to prepare for their IB Math Exams.</p>
        </div>
        </div>
    </div>
  )
}
