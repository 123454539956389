export const teachers = [
  {
      "id": 1,
      "name": "Jenna Quinn",
      "role": "Mathematics Teacher",
      "smallBio": "Jenna Quinn is a highly dedicated Mathematics teacher with over 15 years of experience in the field of education. Her passion for mathematics began in her own school years",
      "bio": "Jenna Quinn is a highly dedicated Mathematics teacher with over 15 years of experience in the field of education. Her passion for mathematics began in her own school years, where she excelled in algebra, calculus, and geometry. After earning her degree in Mathematics Education, Jane started her teaching career with a vision to make math accessible and enjoyable for all students. She employs a variety of teaching methods, including interactive problem-solving sessions, real-world applications, and technology integration, to cater to different learning styles. Jane is known for her ability to explain complex mathematical concepts in a way that is both understandable and engaging. She believes in fostering a growth mindset in her students, encouraging them to embrace challenges and view mistakes as opportunities for learning. Outside of the classroom, Jane is involved in several math-related extracurricular activities, including math clubs and competitions. She also participates in professional development workshops to stay updated with the latest educational trends and methodologies. Jane’s commitment to her students' success is evident in the positive feedback she receives from both students and parents. Her goal is to inspire a love for mathematics in her students and to help them develop the critical thinking and problem-solving skills necessary for success in their academic and professional lives.",
      "image": "https://res.cloudinary.com/duovxefh6/image/upload/v1722434211/Jenna-Photo-1_nchg2a.avif",
      "country": "USA",
      "city": "New York",
      "online": true,
      "subjects": [
          "Algebra",
          "Geometry",
          "Calculus",
          "Statistics"
      ]
  },
  {
      "id": 2,
      "name": "John Doe",
      "role": "Science Teacher",
      "smallBio": "John Doe is a seasoned Science teacher with a deep-rooted passion for chemistry and physics. With a degree in Chemical Engineering and a Master’s in Science Education",
      "bio": "John Doe is a seasoned Science teacher with a deep-rooted passion for chemistry and physics. With a degree in Chemical Engineering and a Master’s in Science Education, John has been teaching for over 20 years. His teaching philosophy revolves around making science both exciting and approachable. He integrates hands-on experiments and real-life scenarios into his lessons to help students understand complex scientific principles. John’s enthusiasm for science is contagious, and he often goes above and beyond to ensure his students are engaged and curious. He believes in fostering a scientific mindset, where students are encouraged to ask questions, conduct experiments, and explore scientific phenomena. John is also involved in mentoring students for science fairs and helping them with their research projects. His commitment to teaching extends beyond the classroom, as he actively participates in local science education forums and contributes to curriculum development. John’s ability to connect with students and make science relevant to their everyday lives has earned him accolades and recognition within the educational community. He is dedicated to inspiring the next generation of scientists and ensuring they have a solid foundation in scientific inquiry and critical thinking.",
      "image": "https://randomuser.me/api/portraits/men/1.jpg",
      "country": "USA",
      "city": "Los Angeles",
      "online": false,
      "subjects": [
          "Physics",
          "Chemistry",
          "Biology",
          "Environmental Science"
      ]
  },
  {
      "id": 3,
      "name": "Emily Johnson",
      "role": "English Teacher",
      "smallBio": "Emily Johnson is an English teacher with a fervent commitment to helping students enhance their language and literary skills. With a Bachelor’s degree in English Literature and a Master’s degree in Education",
      "bio": "Emily Johnson is an English teacher with a fervent commitment to helping students enhance their language and literary skills. With a Bachelor’s degree in English Literature and a Master’s degree in Education, Emily has been shaping young minds for over 12 years. Her teaching approach combines classical literature with contemporary texts to provide students with a well-rounded understanding of language arts. Emily focuses on developing students' reading comprehension, writing abilities, and critical thinking skills. She uses a variety of teaching techniques, including group discussions, creative writing exercises, and multimedia resources, to make literature engaging and accessible. Emily is also passionate about fostering a love for reading in her students and often organizes book clubs and literary events to encourage reading outside the classroom. She believes in creating a supportive and inclusive learning environment where students feel comfortable expressing their thoughts and ideas. Emily's dedication to her students is reflected in their academic achievements and the positive feedback she receives. Her goal is to inspire students to appreciate the beauty of language and to develop the skills necessary for effective communication and literary analysis.",
      "image": "https://randomuser.me/api/portraits/women/2.jpg",
      "country": "Canada",
      "city": "Toronto",
      "online": true,
      "subjects": [
          "Literature",
          "Composition",
          "Creative Writing",
          "Grammar"
      ]
  },
  {
      "id": 4,
      "name": "Michael Brown",
      "role": "History Teacher",
      "smallBio": "Michael Brown is an experienced History teacher who brings the past to life through engaging lessons and storytelling. With a Master’s degree in History and over 18 years of teaching experience",
      "bio": "Michael Brown is an experienced History teacher who brings the past to life through engaging lessons and storytelling. With a Master’s degree in History and over 18 years of teaching experience, Michael is passionate about helping students understand historical events and their impact on the present and future. His teaching style incorporates a variety of multimedia tools, including documentaries, historical reenactments, and interactive timelines, to make history lessons dynamic and captivating. Michael emphasizes the importance of historical context and encourages students to think critically about historical events and their significance. He also involves students in research projects and presentations to deepen their understanding of specific historical periods or figures. Michael’s enthusiasm for history is evident in his classroom, where he fosters a love for learning and curiosity about the past. He believes that understanding history is crucial for developing informed and engaged citizens. Michael is actively involved in organizing historical field trips and educational excursions to enhance students' learning experiences. His dedication to teaching history and his ability to connect with students make him a valued member of the educational community.",
      "image": "https://randomuser.me/api/portraits/men/2.jpg",
      "country": "Canada",
      "city": "Vancouver",
      "online": false,
      "subjects": [
          "Ancient History",
          "Modern History",
          "World History",
          "European History"
      ]
  },
  {
      "id": 5,
      "name": "Sarah Davis",
      "role": "Art Teacher",
      "smallBio": "Sarah Davis is an accomplished Art teacher with a strong background in visual arts and a passion for nurturing students' creative talents.",
      "bio": "Sarah Davis is an accomplished Art teacher with a strong background in visual arts and a passion for nurturing students' creative talents. She holds a degree in Fine Arts and a teaching certification, and she has been inspiring students for over 14 years. Sarah’s teaching philosophy centers around the idea that art is a powerful form of expression and communication. She encourages students to explore their creativity through various mediums, including painting, drawing, sculpture, and digital art. Sarah integrates art history and contemporary art practices into her lessons to provide students with a comprehensive understanding of the art world. She believes in creating a supportive and inclusive environment where students feel free to experiment and develop their unique artistic voices. Sarah also organizes art exhibitions and showcases to give students opportunities to display their work and gain confidence in their artistic abilities. Her dedication to art education and her ability to connect with students on a personal level have earned her recognition and admiration within the educational community. Sarah’s goal is to inspire a lifelong appreciation for art and to help students develop their creative potential.",
      "image": "https://randomuser.me/api/portraits/women/3.jpg",
      "country": "UK",
      "city": "London",
      "online": true,
      "subjects": [
          "Drawing",
          "Painting",
          "Sculpture",
          "Art History",
          "Drawing",
          "Painting",
          "Sculpture",
          "Art History"
      ]
  },
  {
      "id": 6,
      "name": "David Wilson",
      "role": "Physical Education Teacher",
      "smallBio": "David Wilson is a dedicated Physical Education teacher with over 10 years of experience in promoting health and fitness among students.",
      "bio": "David Wilson is a dedicated Physical Education teacher with over 10 years of experience in promoting health and fitness among students. With a degree in Sports Science and a passion for physical activity, David’s approach to teaching emphasizes the importance of physical well-being and healthy lifestyle choices. His classes are designed to be engaging and inclusive, offering a variety of activities such as team sports, individual fitness challenges, and recreational games. David focuses on building students’ physical skills, teamwork, and sportsmanship while fostering a positive attitude towards physical activity. He also incorporates education on nutrition and wellness into his lessons to help students make informed decisions about their health. David is actively involved in organizing school sports events, fitness competitions, and after-school sports clubs to provide students with additional opportunities for physical activity. His enthusiasm for sports and his commitment to students’ health make him a valuable member of the school community. David’s goal is to instill a lifelong love for physical activity and to help students develop habits that will contribute to their overall well-being.",
      "image": "https://randomuser.me/api/portraits/men/3.jpg",
      "country": "UK",
      "city": "Manchester",
      "online": false,
      "subjects": [
          "Sports Science",
          "Health Education",
          "Fitness Training",
          "Physical Activities"
      ]
  },
  {
      "id": 7,
      "name": "Laura Martinez",
      "role": "Geography Teacher",
      "smallBio": "Laura Martinez is a Geography teacher with a passion for exploring the world and helping students understand the complexities of our planet.",
      "bio": "Laura Martinez is a Geography teacher with a passion for exploring the world and helping students understand the complexities of our planet. With a degree in Geography and Environmental Science, Laura has been teaching for over 8 years and brings a wealth of knowledge and enthusiasm to her classroom. Her teaching approach combines traditional geography lessons with hands-on activities, field trips, and interactive projects to make learning about the world an exciting experience. Laura covers a wide range of topics, including physical geography, human geography, and environmental issues, to provide students with a comprehensive understanding of the world around them. She also emphasizes the importance of geographical literacy in addressing global challenges such as climate change and urbanization. Laura’s dedication to teaching extends beyond the classroom, as she actively participates in geography-related extracurricular activities and professional development opportunities. Her goal is to inspire a curiosity about the world and to help students develop the skills needed to analyze and address geographical and environmental issues.",
      "image": "https://randomuser.me/api/portraits/women/4.jpg",
      "country": "Australia",
      "city": "Sydney",
      "online": true,
      "subjects": [
          "Physical Geography",
          "Human Geography",
          "Environmental Geography",
          "Cartography"
      ]
  },
  {
      "id": 8,
      "name": "James Garcia",
      "role": "Computer Science Teacher",
      "smallBio": "James Garcia is a Computer Science teacher with a deep passion for technology and programming. Holding a degree in Computer Science and a Master’s in Education.",
      "bio": "James Garcia is a Computer Science teacher with a deep passion for technology and programming. Holding a degree in Computer Science and a Master’s in Education, James has been teaching for over 9 years and is dedicated to helping students develop their coding skills and understanding of computer systems. His teaching style incorporates a variety of programming languages, including Python, Java, and HTML/CSS, to provide students with a solid foundation in computer science. James also emphasizes the importance of problem-solving and logical thinking, and he encourages students to work on real-world projects and participate in coding competitions. He believes in creating a dynamic and interactive learning environment where students can explore their interests and develop their technical skills. Outside of the classroom, James is involved in organizing tech clubs and workshops to further engage students in the field of computer science. His commitment to fostering a love for technology and his ability to connect with students make him a highly regarded member of the educational community. James aims to inspire students to pursue careers in technology and to develop the skills needed for success in the digital age.",
      "image": "https://randomuser.me/api/portraits/men/4.jpg",
      "country": "Australia",
      "city": "Melbourne",
      "online": false,
      "subjects": [
          "Programming",
          "Data Structures",
          "Algorithms",
          "Software Engineering"
      ]
  },
  {
      "id": 9,
      "name": "Mia Anderson",
      "role": "Biology Teacher",
      "smallBio": "Mia Anderson is a dedicated Biology teacher with a passion for exploring the wonders of life and the natural world. With a degree in Biological Sciences and a Master’s in Science Education.",
      "bio": "Mia Anderson is a dedicated Biology teacher with a passion for exploring the wonders of life and the natural world. With a degree in Biological Sciences and a Master’s in Science Education, Mia has been teaching for over 11 years. Her approach to teaching biology is hands-on and interactive, incorporating lab experiments, field studies, and multimedia resources to help students understand complex biological concepts. Mia’s lessons cover a wide range of topics, including genetics, ecology, and human anatomy, with a focus on making science engaging and relevant. She encourages students to ask questions, conduct research, and participate in discussions to deepen their understanding of biological processes. Mia is also involved in mentoring students for science fairs and guiding them in their independent research projects. Her commitment to teaching and her ability to make biology exciting for students are reflected in their academic achievements and enthusiasm for the subject. Mia’s goal is to inspire a love for science and to help students develop a strong foundation in biology that will benefit them in their future studies and careers.",
      "image": "https://randomuser.me/api/portraits/women/5.jpg",
      "country": "Germany",
      "city": "Berlin",
      "online": true,
      "subjects": [
          "Genetics",
          "Ecology",
          "Human Anatomy",
          "Evolution"
      ]
  },
  {
      "id": 10,
      "name": "Daniel Taylor",
      "role": "Chemistry Teacher",
      "smallBio": "Daniel Taylor is a Chemistry teacher with a passion for making the study of chemical processes and reactions both accessible and exciting.",
      "bio": "Daniel Taylor is a Chemistry teacher with a passion for making the study of chemical processes and reactions both accessible and exciting. With a degree in Chemistry and over 13 years of teaching experience, Daniel is dedicated to helping students understand the principles of chemistry through interactive and engaging lessons. His teaching approach includes hands-on experiments, demonstrations, and real-world applications to illustrate chemical concepts and their relevance to everyday life. Daniel’s enthusiasm for chemistry is evident in his dynamic teaching style and his commitment to creating a stimulating learning environment. He encourages students to explore the world of chemistry through inquiry-based learning and problem-solving activities. Daniel also participates in professional development to stay current with advancements in the field and to continuously improve his teaching methods. His dedication to student success and his ability to make chemistry come alive in the classroom have earned him recognition and respect from students and colleagues alike. Daniel’s goal is to foster a deep understanding and appreciation of chemistry and to inspire students to pursue careers in science and related fields.",
      "image": "https://randomuser.me/api/portraits/men/5.jpg",
      "country": "Germany",
      "city": "Munich",
      "online": false,
      "subjects": [
          "Organic Chemistry",
          "Inorganic Chemistry",
          "Physical Chemistry",
          "Analytical Chemistry"
      ]
  },
  {
      "id": 11,
      "name": "Olivia Thomas",
      "role": "Physics Teacher",
      "smallBio": "Olivia Thomas is a Physics teacher with a profound dedication to helping students understand the fundamental principles of physics and their applications in the real world.",
      "bio": "Olivia Thomas is a Physics teacher with a profound dedication to helping students understand the fundamental principles of physics and their applications in the real world. With a degree in Physics and a Master’s in Education, Olivia has been teaching for over 15 years and has developed a reputation for her engaging and effective teaching methods. Her lessons include a mix of theoretical instruction, hands-on experiments, and problem-solving activities to make complex physics concepts more approachable. Olivia’s teaching philosophy emphasizes the importance of critical thinking and scientific inquiry, encouraging students to explore physical phenomena and develop a strong foundation in the subject. She also organizes physics-related extracurricular activities, such as science clubs and competitions, to further engage students in the field. Olivia’s commitment to her students is reflected in their strong performance in physics and their enthusiasm for the subject. Her goal is to inspire a love for physics and to help students develop the skills and knowledge necessary for success in their academic and professional pursuits.",
      "image": "https://randomuser.me/api/portraits/women/6.jpg",
      "country": "France",
      "city": "Paris",
      "online": true,
      "subjects": [
          "Mechanics",
          "Electromagnetism",
          "Quantum Physics",
          "Thermodynamics"
      ]
  },
  {
      "id": 12,
      "name": "Christopher Lee",
      "role": "Music Teacher",
      "smallBio": "Christopher Lee is a Music teacher with a deep passion for encouraging musical expression and skill development in students. With a degree in Music Education and over 12 years of teaching experience.",
      "bio": "Christopher Lee is a Music teacher with a deep passion for encouraging musical expression and skill development in students. With a degree in Music Education and over 12 years of teaching experience, Christopher is dedicated to helping students develop their musical talents and appreciation for music. His teaching approach includes a combination of practical instruction, music theory, and performance opportunities to provide students with a well-rounded music education. Christopher teaches various instruments and vocal techniques, and he emphasizes the importance of creativity and self-expression in music. He also organizes school concerts, recitals, and music festivals to give students opportunities to showcase their talents and gain confidence in their musical abilities. Christopher’s commitment to music education extends beyond the classroom, as he actively participates in professional development and stays current with trends in music education. His dedication to fostering a love for music and his ability to inspire students make him a valued member of the educational community. Christopher’s goal is to help students discover their musical potential and to cultivate a lifelong appreciation for music.",
      "image": "https://randomuser.me/api/portraits/men/6.jpg",
      "country": "France",
      "city": "Lyon",
      "online": false,
      "subjects": [
          "Music Theory",
          "Performance",
          "Composition",
          "Music History"
      ]
  },
  {
      "id": 13,
      "name": "Sophia Harris",
      "role": "Drama Teacher",
      "smallBio": "Sophia Harris is a Drama teacher with a strong commitment to fostering creativity and performance skills in students. With a degree in Theater Arts and over 10 years of experience in both teaching and performing.",
      "bio": "Sophia Harris is a Drama teacher with a strong commitment to fostering creativity and performance skills in students. With a degree in Theater Arts and over 10 years of experience in both teaching and performing, Sophia brings a wealth of knowledge and passion to her drama classes. Her teaching approach focuses on helping students develop their acting, improvisation, and stagecraft skills through a variety of exercises and performances. Sophia believes that drama is a powerful tool for building confidence, enhancing communication skills, and exploring personal expression. She also emphasizes the importance of collaboration and teamwork in theater, encouraging students to work together to create compelling and dynamic performances. Sophia organizes school plays, theater workshops, and drama festivals to provide students with opportunities to showcase their talents and gain experience in the performing arts. Her dedication to drama education and her ability to inspire students make her a respected and beloved teacher. Sophia’s goal is to nurture students’ artistic abilities and to help them develop a lifelong appreciation for the performing arts.",
      "image": "https://randomuser.me/api/portraits/women/7.jpg",
      "country": "Italy",
      "city": "Rome",
      "online": true,
      "subjects": [
          "Acting",
          "Directing",
          "Theater History",
          "Playwriting"
      ]
  },
  {
      "id": 14,
      "name": "Joshua Clark",
      "role": "Economics Teacher",
      "smallBio": "Joshua Clark is an Economics teacher with a deep understanding of economic theories and practical applications. With a degree in Economics and a Master’s in Education.",
      "bio": "Joshua Clark is an Economics teacher with a deep understanding of economic theories and practical applications. With a degree in Economics and a Master’s in Education, Joshua has been teaching for over 13 years and is dedicated to helping students understand the complexities of economic systems and their impact on society. His teaching style includes a mix of theoretical instruction, case studies, and real-world examples to make economic concepts more relevant and engaging. Joshua emphasizes the importance of critical thinking and analytical skills in studying economics, and he encourages students to explore current economic issues and trends. He also involves students in debates, simulations, and research projects to enhance their understanding of economic principles. Joshua’s commitment to teaching extends beyond the classroom, as he actively participates in professional development and contributes to curriculum development. His dedication to his students’ success and his ability to make economics accessible and interesting make him a valued member of the educational community. Joshua’s goal is to help students develop a strong foundation in economics and to prepare them for future academic and professional pursuits.",
      "image": "https://randomuser.me/api/portraits/men/7.jpg",
      "country": "Italy",
      "city": "Milan",
      "online": false,
      "subjects": [
          "Microeconomics",
          "Macroeconomics",
          "International Economics",
          "Economic Theory"
      ]
  },
  {
      "id": 15,
      "name": "Ava Lewis",
      "role": "Sociology Teacher",
      "smallBio": "Ava Lewis is a Sociology teacher with a passion for exploring the dynamics of societies and cultures. With a degree in Sociology and a Master’s in Education.",
      "bio": "Ava Lewis is a Sociology teacher with a passion for exploring the dynamics of societies and cultures. With a degree in Sociology and a Master’s in Education, Ava has been teaching for over 11 years and is dedicated to helping students understand the complexities of social behavior and cultural practices. Her teaching approach includes a combination of theoretical lessons, case studies, and interactive activities to make sociology engaging and relevant. Ava emphasizes the importance of understanding social structures and cultural differences, and she encourages students to critically analyze social issues and trends. She also organizes class discussions, group projects, and field trips to enhance students’ understanding of sociological concepts. Ava’s commitment to teaching extends beyond the classroom, as she actively participates in sociology-related research and professional development. Her dedication to her students and her ability to connect with them on a personal level make her a respected and admired teacher. Ava’s goal is to inspire a deeper understanding of society and to help students develop the skills needed to analyze and address social issues.",
      "image": "https://randomuser.me/api/portraits/women/8.jpg",
      "country": "Spain",
      "city": "Madrid",
      "online": true,
      "subjects": [
          "Social Theory",
          "Research Methods",
          "Cultural Sociology",
          "Social Issues"
      ]
  },
  {
      "id": 16,
      "name": "Ethan Walker",
      "role": "Philosophy Teacher",
      "smallBio": "Ethan Walker is a Philosophy teacher with a strong commitment to encouraging critical thinking and philosophical inquiry. With a degree in Philosophy and a Master’s in Education",
      "bio": "Ethan Walker is a Philosophy teacher with a strong commitment to encouraging critical thinking and philosophical inquiry. With a degree in Philosophy and a Master’s in Education, Ethan has been teaching for over 14 years and is dedicated to helping students explore fundamental questions about existence, knowledge, and ethics. His teaching approach includes a combination of lectures, discussions, and critical analysis to help students engage with complex philosophical concepts. Ethan emphasizes the importance of developing logical reasoning and analytical skills, and he encourages students to participate in debates and thought experiments. He also incorporates contemporary philosophical issues and real-world applications into his lessons to make philosophy relevant and engaging. Ethan’s dedication to teaching extends beyond the classroom, as he actively participates in philosophy-related conferences and contributes to academic publications. His ability to inspire students and his commitment to fostering a love for philosophy make him a valued member of the educational community. Ethan’s goal is to help students develop a deep understanding of philosophical ideas and to cultivate their critical thinking abilities.",
      "image": "https://randomuser.me/api/portraits/men/8.jpg",
      "country": "Spain",
      "city": "Barcelona",
      "online": false,
      "subjects": [
          "Ethics",
          "Metaphysics",
          "Epistemology",
          "Logic"
      ]
  },
  {
      "id": 17,
      "name": "Isabella Young",
      "role": "Engineering Teacher",
      "smallBio": "Isabella Young is an Engineering teacher with a strong focus on teaching practical engineering skills and innovative problem-solving. With a degree in Engineering and a Master’s in Education",
      "bio": "Isabella Young is an Engineering teacher with a strong focus on teaching practical engineering skills and innovative problem-solving. With a degree in Engineering and a Master’s in Education, Isabella has been teaching for over 12 years and is dedicated to providing students with hands-on experience in engineering projects. Her teaching approach includes a combination of theoretical instruction and practical application, encouraging students to work on real-world engineering challenges and develop their technical skills. Isabella emphasizes the importance of creativity and teamwork in engineering and encourages students to collaborate on projects and explore new ideas. She also organizes engineering competitions, workshops, and field trips to enhance students’ understanding of engineering principles and their applications. Isabella’s commitment to teaching extends beyond the classroom, as she actively participates in professional development and contributes to curriculum design. Her ability to inspire students and her dedication to fostering a passion for engineering make her a highly regarded teacher. Isabella’s goal is to help students develop the skills and knowledge needed for successful careers in engineering and related fields.",
      "image": "https://randomuser.me/api/portraits/women/9.jpg",
      "country": "Netherlands",
      "city": "Amsterdam",
      "online": true,
      "subjects": [
          "Mechanical Engineering",
          "Electrical Engineering",
          "Civil Engineering",
          "Computer Engineering"
      ]
  },
  {
      "id": 18,
      "name": "Mason Scott",
      "role": "Statistics Teacher",
      "smallBio": "Mason Scott is a Statistics teacher with a strong focus on helping students understand and apply statistical concepts. With a degree in Statistics and a Master’s in Education",
      "bio": "Mason Scott is a Statistics teacher with a strong focus on helping students understand and apply statistical concepts. With a degree in Statistics and a Master’s in Education, Mason has been teaching for over 9 years and is dedicated to making statistics engaging and accessible. His teaching approach includes a combination of theoretical lessons, hands-on activities, and real-world applications to help students grasp complex statistical concepts. Mason emphasizes the importance of critical thinking and data analysis in studying statistics, and he encourages students to work on data-driven projects and experiments. He also incorporates technology and software tools into his lessons to enhance students’ understanding of statistical methods and their applications. Mason’s commitment to teaching extends beyond the classroom, as he actively participates in professional development and contributes to statistical research. His ability to make statistics relevant and interesting for students has earned him recognition and respect from both students and colleagues. Mason’s goal is to help students develop strong analytical skills and a deep understanding of statistical principles.",
      "image": "https://randomuser.me/api/portraits/men/9.jpg",
      "country": "Netherlands",
      "city": "Rotterdam",
      "online": false,
      "subjects": [
          "Descriptive Statistics",
          "Inferential Statistics",
          "Probability",
          "Data Analysis"
      ]
  },
  {
      "id": 19,
      "name": "Charlotte Adams",
      "role": "Psychology Teacher",
      "smallBio": "Charlotte Adams is a Psychology teacher with a deep interest in exploring human behavior and mental processes. With a degree in Psychology and a Master’s in Education",
      "bio": "Charlotte Adams is a Psychology teacher with a deep interest in exploring human behavior and mental processes. With a degree in Psychology and a Master’s in Education, Charlotte has been teaching for over 10 years and is dedicated to helping students understand the complexities of the human mind. Her teaching approach includes a mix of theoretical instruction, case studies, and interactive activities to make psychology engaging and applicable. Charlotte emphasizes the importance of understanding psychological theories and their real-world applications, and she encourages students to participate in discussions and research projects. She also organizes psychology-related extracurricular activities, such as workshops and seminars, to further engage students in the field. Charlotte’s commitment to teaching extends beyond the classroom, as she actively participates in psychological research and professional development. Her ability to connect with students and her dedication to fostering a love for psychology make her a respected and admired teacher. Charlotte’s goal is to inspire students to explore the field of psychology and to help them develop a strong foundation in understanding human behavior.",
      "image": "https://randomuser.me/api/portraits/women/10.jpg",
      "country": "Belgium",
      "city": "Brussels",
      "online": true,
      "subjects": [
          "Cognitive Psychology",
          "Developmental Psychology",
          "Social Psychology",
          "Abnormal Psychology"
      ]
  },
  {
      "id": 20,
      "name": "Logan Baker",
      "role": "Political Science Teacher",
      "smallBio": "Logan Baker is a Political Science teacher with a strong commitment to analyzing political systems and theories. With a degree in Political Science and a Master’s in Education",
      "bio": "Logan Baker is a Political Science teacher with a strong commitment to analyzing political systems and theories. With a degree in Political Science and a Master’s in Education, Logan has been teaching for over 12 years and is dedicated to helping students understand the complexities of political structures and processes. His teaching approach includes a combination of lectures, discussions, and case studies to make political science relevant and engaging. Logan emphasizes the importance of critical thinking and analytical skills in studying political systems, and he encourages students to explore current political issues and participate in debates. He also organizes model United Nations events and political simulations to enhance students’ understanding of political concepts and their practical applications. Logan’s commitment to teaching extends beyond the classroom, as he actively participates in political science research and professional development. His dedication to his students and his ability to make political science engaging make him a respected member of the educational community. Logan’s goal is to help students develop a deep understanding of political systems and to prepare them for future academic and professional pursuits.",
      "image": "https://randomuser.me/api/portraits/men/10.jpg",
      "country": "Belgium",
      "city": "Antwerp",
      "online": false,
      "subjects": [
          "Comparative Politics",
          "International Relations",
          "Political Theory",
          "Public Policy"
      ]
  },
  {
      "id": 21,
      "name": "Amelia Gonzalez",
      "role": "Literature Teacher",
      "smallBio": "Amelia Gonzalez is a Literature teacher with a profound passion for exploring classical and modern literary works. With a degree in Literature and a Master’s in Education",
      "bio": "Amelia Gonzalez is a Literature teacher with a profound passion for exploring classical and modern literary works. With a degree in Literature and a Master’s in Education, Amelia has been teaching for over 15 years and is dedicated to helping students appreciate the richness of literature. Her teaching approach includes a mix of reading, analysis, and discussion of various literary genres, including novels, poetry, and drama. Amelia emphasizes the importance of understanding literary themes, character development, and narrative techniques, and she encourages students to express their interpretations and insights. She also organizes literature-related activities, such as book clubs and literary festivals, to further engage students in the study of literature. Amelia’s commitment to teaching extends beyond the classroom, as she actively participates in literary research and professional development. Her dedication to fostering a love for literature and her ability to inspire students make her a highly respected teacher. Amelia’s goal is to help students develop a deep appreciation for literature and to cultivate their analytical and critical thinking skills.",
      "image": "https://randomuser.me/api/portraits/women/11.jpg",
      "country": "Sweden",
      "city": "Stockholm",
      "online": true,
      "subjects": [
          "Classical Literature",
          "Modern Literature",
          "Poetry",
          "Drama"
      ]
  },
  {
      "id": 22,
      "name": "Liam Mitchell",
      "role": "Religious Studies Teacher",
      "smallBio": "Liam Mitchell is a Religious Studies teacher with a deep interest in understanding and discussing various religions and beliefs. With a degree in Religious Studies and a Master’s in Education",
      "bio": "Liam Mitchell is a Religious Studies teacher with a deep interest in understanding and discussing various religions and beliefs. With a degree in Religious Studies and a Master’s in Education, Liam has been teaching for over 10 years and is dedicated to helping students explore the diverse range of religious traditions and philosophical beliefs. His teaching approach includes a combination of lectures, discussions, and comparative studies to make religious studies engaging and insightful. Liam emphasizes the importance of respect and understanding in studying different belief systems, and he encourages students to explore the historical and cultural contexts of various religions. He also organizes interfaith dialogues and religious studies projects to enhance students’ understanding of religious diversity. Liam’s commitment to teaching extends beyond the classroom, as he actively participates in interfaith initiatives and professional development. His dedication to fostering a respectful and inclusive learning environment makes him a valued member of the educational community. Liam’s goal is to help students develop a broad understanding of world religions and to promote tolerance and mutual respect.",
      "image": "https://randomuser.me/api/portraits/men/11.jpg",
      "country": "Sweden",
      "city": "Gothenburg",
      "online": false,
      "subjects": [
          "Comparative Religion",
          "Philosophy of Religion",
          "Theology",
          "Ethics"
      ]
  },
  {
      "id": 23,
      "name": "Evelyn Roberts",
      "role": "Architecture Teacher",
      "smallBio": "Evelyn Roberts is an Architecture teacher with a passion for teaching the principles of architectural design and construction. With a degree in Architecture and a Master’s in Education",
      "bio": "Evelyn Roberts is an Architecture teacher with a passion for teaching the principles of architectural design and construction. With a degree in Architecture and a Master’s in Education, Evelyn has been teaching for over 11 years and is dedicated to helping students understand the complexities of architectural design. Her teaching approach includes a combination of theoretical instruction, practical projects, and site visits to provide students with a comprehensive understanding of architecture. Evelyn emphasizes the importance of creativity and innovation in architectural design and encourages students to develop their own design concepts. She also organizes design competitions, workshops, and guest lectures to expose students to various aspects of the field. Evelyn’s commitment to teaching extends beyond the classroom, as she actively participates in architectural research and professional development. Her dedication to inspiring students and her ability to make architecture accessible and engaging make her a highly respected teacher. Evelyn’s goal is to help students develop a strong foundation in architecture and to prepare them for careers in the field.",
      "image": "https://randomuser.me/api/portraits/women/12.jpg",
      "country": "Norway",
      "city": "Oslo",
      "online": true,
      "subjects": [
          "Architectural Design",
          "Building Construction",
          "Urban Planning",
          "Architectural History"
      ]
  },
  {
      "id": 24,
      "name": "Henry Carter",
      "role": "Graphic Design Teacher",
      "smallBio": "Henry Carter is a Graphic Design teacher with a deep passion for visual communication and design principles. With a degree in Graphic Design and over 14 years of teaching experience",
      "bio": "Henry Carter is a Graphic Design teacher with a deep passion for visual communication and design principles. With a degree in Graphic Design and over 14 years of teaching experience, Henry is dedicated to helping students develop their design skills and understanding of visual aesthetics. His teaching approach includes a mix of theoretical lessons, hands-on projects, and software training to provide students with a well-rounded education in graphic design. Henry emphasizes the importance of creativity and technical proficiency in design and encourages students to explore various design styles and techniques. He also organizes design exhibitions, workshops, and guest lectures to expose students to the latest trends and practices in the field. Henry’s commitment to teaching extends beyond the classroom, as he actively participates in design-related research and professional development. His ability to inspire students and his dedication to fostering a love for graphic design make him a highly respected teacher. Henry’s goal is to help students develop their design skills and prepare them for careers in graphic design and related fields.",
      "image": "https://randomuser.me/api/portraits/men/12.jpg",
      "country": "Norway",
      "city": "Bergen",
      "online": false,
      "subjects": [
          "Design Principles",
          "Typography",
          "Digital Media",
          "Illustration"
      ]
  },
  {
      "id": 25,
      "name": "Ella Evans",
      "role": "Dance Teacher",
      "smallBio": "Ella Evans is a Dance teacher with a passion for nurturing students' dance abilities and artistic expression. With a degree in Dance Education and over 9 years of teaching experience",
      "bio": "Ella Evans is a Dance teacher with a passion for nurturing students' dance abilities and artistic expression. With a degree in Dance Education and over 9 years of teaching experience, Ella is dedicated to helping students develop their dance techniques and performance skills. Her teaching approach includes a combination of classical and contemporary dance styles, focusing on technique, choreography, and creativity. Ella emphasizes the importance of physical expression and artistic interpretation in dance and encourages students to explore their own styles and movements. She also organizes dance recitals, workshops, and performances to provide students with opportunities to showcase their talents and gain confidence in their abilities. Ella’s commitment to teaching extends beyond the classroom, as she actively participates in dance-related professional development and stays current with trends in dance education. Her dedication to inspiring students and her ability to create a supportive and dynamic learning environment make her a valued member of the educational community. Ella’s goal is to help students develop a lifelong appreciation for dance and to foster their artistic growth.",
      "image": "https://randomuser.me/api/portraits/women/13.jpg",
      "country": "Denmark",
      "city": "Copenhagen",
      "online": true,
      "subjects": [
          "Ballet",
          "Modern Dance",
          "Jazz Dance",
          "Contemporary Dance"
      ]
  },
  {
      "id": 26,
      "name": "Mia Campbell",
      "role": "Art Teacher",
      "smallBio": "Mia Campbell is an Art teacher with a strong dedication to fostering creativity and artistic expression in students. With a degree in Fine Arts and over 11 years of teaching experience",
      "bio": "Mia Campbell is an Art teacher with a strong dedication to fostering creativity and artistic expression in students. With a degree in Fine Arts and over 11 years of teaching experience, Mia is committed to helping students explore various art forms and develop their artistic skills. Her teaching approach includes a mix of practical art projects, art history lessons, and exposure to different techniques and media. Mia emphasizes the importance of creativity and individual expression in art and encourages students to experiment with their own ideas and styles. She also organizes art exhibitions, workshops, and guest artist sessions to provide students with opportunities to showcase their work and gain inspiration. Mia’s commitment to teaching extends beyond the classroom, as she actively participates in art-related professional development and stays current with trends in art education. Her ability to inspire students and her dedication to fostering a love for art make her a highly respected teacher. Mia’s goal is to help students develop their artistic talents and to cultivate their appreciation for the arts.",
      "image": "https://randomuser.me/api/portraits/women/14.jpg",
      "country": "Denmark",
      "city": "Aarhus",
      "online": false,
      "subjects": [
          "Drawing",
          "Painting",
          "Sculpture",
          "Art History",
          "Drawing",
          "Painting",
          "Sculpture",
          "Art History"
      ]
  }
]