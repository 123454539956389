

import React , {useState} from 'react'
import "./style.css"

const CustomSlider = ({components}) => {

  const [currentIndex , setCurrentIndex] = useState(0)

  const handleNext = ()=>{

      if(currentIndex < components.length  - 1){
        setCurrentIndex(prev => prev + 1);
        return
      }
  }
  const handlePrevius = ()=>{
      if(currentIndex > 0){
        setCurrentIndex(prev => prev - 1);
      }
  }


  return (
    <div className='customSlider'>
        <h2 className='title fs-3 fw-bolder mb-3'>{components[currentIndex]?.title}</h2>
          <div className='cs_content'>
              {components[currentIndex]?.component}
          </div>

          <div className='cs_btns'>
              <button className='green_btn fit' onClick={handlePrevius} >Previus</button>
              <button className='green_btn fit' onClick={ handleNext} >Next</button>
          </div>
    </div>
  )
}

export default CustomSlider
