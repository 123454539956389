import './style.css';

export default function IBMResources() {
  return (
    <div className='ibm_resources_container'>
        <h2>More IB Math Resources</h2>
        <div className='ibm_resources_boxes'>
            <div className='ibm_resources_box'>
                <div className='ibm_resources_icon resource-icon-1'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
</svg>

                </div>
                <h3>Analysis & Approaches SL</h3>
            </div>
            <div className='ibm_resources_box'>
                <div className='ibm_resources_icon resource-icon-2'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
</svg>

                </div>
                <h3>Analysis & Approaches HL</h3>
            </div>
            <div className='ibm_resources_box'>
                <div className='ibm_resources_icon resource-icon-3'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path  d="M8 10h8v2H8zm0-4h12v2H8zm0-4h12v2H8z"></path><path  d="m4.711 28l5.631-9.996l7.434 6.49a2 2 0 0 0 3.084-.534l6.97-10.403l-1.661-1.114l-7 10.448l-.07.103l-7.435-6.49a2.003 2.003 0 0 0-3.08.53L4 25.183V2H2v26a2 2 0 0 0 2 2h26v-2Z"></path></svg>
                </div>
                <h3>Applications & Interpretation SL</h3>
            </div>
            <div className='ibm_resources_box'>
                <div className='ibm_resources_icon resource-icon-4'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="rgba(0, 128, 0, 0.664)" d="M8 10h8v2H8zm0-4h12v2H8zm0-4h12v2H8z"></path><path fill="rgba(0, 128, 0, 0.664)" d="m4.711 28l5.631-9.996l7.434 6.49a2 2 0 0 0 3.084-.534l6.97-10.403l-1.661-1.114l-7 10.448l-.07.103l-7.435-6.49a2.003 2.003 0 0 0-3.08.53L4 25.183V2H2v26a2 2 0 0 0 2 2h26v-2Z"></path></svg>
                </div>
                <h3>Applications & Interpretation HL</h3>
            </div>
        </div>
    </div>
  )
}
