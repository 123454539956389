import React from "react";
import "./style.css";
import {fileIcon, Stars, videoIcon} from "../../assets/svgIcons";

const CustomQButton = ({type, text , className , onClick}) => {
  return (
    <div
    onClick={onClick}
      className={`custom_q_button ${className}`}
      style={{
        backgroundColor:
          type == "formula"
            ? "#E9E3FF"
            : type == "mark"
            ? "#E1F0FC"
            : type == "video"
            ? "#FFF0E1"
            : "#F7E5E9",

        color:
          type == "formula"
            ? "#8A70D6"
            : type == "mark"
            ? "#579BE3"
            : type == "video"
            ? "#FBAB5E"
            : "#FF808B",
      }}
    >
      <div className='icon'>
        {type == "formula"
          ? fileIcon
          : type == "mark"
          ? fileIcon
          : type == "video"
          ? videoIcon
          : Stars}
      </div>
      <div className='text'>{text || ""}</div>
    </div>
  );
};

export default CustomQButton;
