import React from "react";
import "./style.css";
import Header from "./../Header/Header";
import Footer from "../Footer/Footer";
import Footer_2 from "../../pages/Footer_2/Footer_2";
import { useLocation } from "react-router-dom";

const DefaultLayout = ({ children }) => {
  const location = useLocation()
  return (
    <div className="defaultlayout">
      <Header />
      <div className="childs" style={{ marginBottom: "20px" }}>
        {children}
      </div>
      {/* <Footer /> */}
      {location?.pathname?.includes("TeacherRegister") ? null :<Footer_2 />}
    </div>
  );
};

export default DefaultLayout;
