import React from "react";
import "./style.css";

import questionSvg from "../../../assets/svgIcons/Questions-pana.svg"
// import solutions from "../../../assets/svgIcons/Business solution-pana.svg"
// import exam from "../../../assets/svgIcons/exam.svg"
import videos from "../../../assets/svgIcons/Videos.svg"
import AnimatedCard from "../../AnimatedCard/AnimatedCard";


import { examIcon, puzzle, questionMarkIcon, videoIcon } from "../../../assets/svgIcons";






function EffectiveRev() {

  const cardsData = [
    {
      title:'Questionbank',
      content:'The ultimate Questionbank, Thousands of exam-style questions, categorized by topic,',
      bgColor:'#EFE2FE',
      icon:questionMarkIcon
    },
    {
      title:'Past Papers',
      content:'Step-by-step solutions to Past Exams with experienced teachers.',
      bgColor:'#FEE2FE',
      icon:puzzle,
    },
    {
      title:'Practice Exams',
      content:'Test yourself with a library of  Practice Exams . Full Topic Exams, Difficulty Ladder Exams and more.',
      bgColor:'#ECFEF3',
      icon:examIcon,
    },
    {
      title:'Key Concepts',
      content:'Short summary videos that teach the key concepts,  Perfect for learning from home.',
      bgColor:'#FEE6E1',
      icon:videoIcon,
    },
  ]






  return (
    <div className='eff-rev-container'>
      <h2 className='title'>What makes Groot in IB so effective?</h2>
      <div className='container eff_rev_items'>


        {
          cardsData.map((item , index)=>{
            return(
             
      <AnimatedCard data={item} />
              

            )
          })
        } 


      </div>
    </div>
  );
}

export default EffectiveRev;




