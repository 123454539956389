import React from "react";
import JobApplicationForm from "./jopapp";

function TeacherRegister() {
  return (
    <div>
      <JobApplicationForm />
    </div>
  );
}

export default TeacherRegister;
