
import "./style.css"
export default function Subjects() {
  return (
    <div>
      <div className="SubjectsBanner">
        <video loop autoPlay muted src={require("./3191353-uhd_4096_2160_25fps.mp4")}></video>
        <div className="slide-content">
          <span
            className="wow fadeInUp sub-title"
            data-wow-delay="100ms"
            data-wow-duration="2000ms"
            style={{ visibility: 'visible', animationDuration: '2000ms', animationDelay: '100ms' }}
          >
            Educational Resources, Lessons
          </span>
          <h1
            className="title-slide wow slideInUp animated"
            data-wow-delay="50ms"
            data-wow-duration="200ms"
            style={{ visibility: 'visible', animationDuration: '200ms', animationDelay: '50ms' }}
          >
            Comprehensive Study Materials
          </h1>
          <p
            className="description wow fadeInUp"
            data-wow-delay="300ms"
            data-wow-duration="2000ms"
            style={{ visibility: 'visible', animationDuration: '2000ms', animationDelay: '300ms' }}
          >
            Find a wide range of study materials and resources to help you excel in your academic journey.
          </p>
          <div className="box">
            <div
              className="btn-main wow fadeInUp"
              data-wow-delay="400ms"
              data-wow-duration="2000ms"
              style={{ visibility: 'visible', animationDuration: '2000ms', animationDelay: '400ms' }}
            >
              <a className="button_main_inner" href="#" style={{ textDecoration: "none" }}>
                <span>Explore Resources</span>
              </a>
            </div>
            <div
              className="video-wrap wow fadeInUp"
              data-wow-delay="500ms"
              data-wow-duration="2000ms"
              style={{ visibility: 'visible', animationDuration: '2000ms', animationDelay: '500ms' }}
            >
              <a className="popup-youtube btn-video ml-28">
                <i className="icon-Polygon-6"></i>
              </a>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}
