import IBMAwards from "../../components/IBMathPages/IBMathAwards/IBMAwards";
import IBMathBanners from "../../components/IBMathPages/IBMathBanners/IBMathBanners";
import IBMathContent from "../../components/IBMathPages/IBMathContent/IBMathContent";
import IBMResources from "../../components/IBMathPages/IBMathResources/IBMResources";
import IBMathRevision from "../../components/IBMathPages/IBMathRevision/IBMathRevision";
import IBMathTips from "../../components/IBMathPages/IBMathTips/IBMathTips";
import IBMathTopics from "../../components/IBMathPages/IBMathTopics/IBMathTopics";
import IBMFrequentlyAsked from "../../components/IBMathPages/IBMFrequentlyAsked/IBMFrequentlyAsked";

export default function IBMath() {
  const bannerNavigations = [
    {
      name: "IB MATH",
      pathname: "",
    },
    {
      name: "IB Math AA SL",
      pathname: "",
    },
  ];
  return (
    <div>
      <IBMathBanners
        title={"IB Mathematics Analysis & Approaches SL"}
        paragraph={
          "Mathematics: Analysis and Approaches, Standard Level, is a rigorous course combining both pure and applied mathematics, although with more emphasis on pure mathematics than the Applications and Interpretation course. The Analysis and Approaches SL course does not contain the breadth nor depth of HL, but is nevertheless a demanding course that requires strong conceptual understanding, algebraic manipulation, and confidence with analytical approaches to problem solving. It is an excellent foundation for students looking to pursue further study of mathematics or science and keeps many options open for a variety of university courses."
        }
        navigations={bannerNavigations}
        button={
          <button style={{margin: "0 40px"}} className='btn btn-success'>
            Subscribe
          </button>
        }
      />

      <IBMathTopics />
      {/* <IBMathRevision /> */}
      <IBMathContent />
      <IBMathTips />
      <IBMAwards />
      <IBMResources />
      <IBMFrequentlyAsked />
    </div>
  );
}
