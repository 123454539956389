import React, {Fragment, useEffect, useState} from "react";
import IBMathBanners from "../../components/IBMathPages/IBMathBanners/IBMathBanners";
import CheckBoxContent from "../../components/CheckBoxContent/CheckBoxContent";
import ClickDropDown from "../../components/ClickDropDown/ClickDropDown";
import {chevron_down, userInCircle} from "../../assets/svgIcons";
import QuestionCard from "./../../components/questionCard/QuestionCard";
import {Link} from "react-scroll";

import pdf from "../../assets/pdfs/formulaBooklet.pdf";

import "./style.css";
import PdfViewer from "../../util/PdfViewer/PdfViewer";

const navigations = [
  {
    name: "IB MATH",
    bathname: "",
  },
  {
    name: "IB Math AA SL",
    bathname: "",
  },
  {
    name: "Practice Exams",
    bathname: "",
  },
];

const QuizPage = () => {
  const data = [
    {
      type: "extended",
      question_count: 1,
      paper: 1,
      difficulty: "hard",
      question: "Evaluate the limit: lim (x → 0) (sin(x)/x)^x.",
      options: [
        {option: "A", text: "1"},
        {option: "B", text: "e"},
        {option: "C", text: "0"},
        {option: "D", text: "∞"},
      ],
      videos: [
        "https://www.youtube.com/watch?v=vvR3JSXO2fo",
        "https://www.youtube.com/watch?v=ZZxhg0TvPkM"
      ],
      answer: "B",
      mark: 5,
      completed:false
    },
    {
      paper: 1,
      type: "extended",
      question_count: 2,
      difficulty: "hard",
      question: "Find the eigenvalues of the matrix [[1, 2], [3, 4]].",
      
      options: [
        {option: "A", text: "5, -1"},
        {option: "B", text: "2, 3"},
        {option: "C", text: "4, 1"},
        {option: "D", text: "3, 2"},
      ],
      answer: "A",
      mark: 5,
      completed:false

    },
    {
      paper: 1,
      type: "extended",
      question_count: 3,
      difficulty: "hard",
      question: "Solve the differential equation dy/dx = y^2 - x^2.",
      
      options: [
        {option: "A", text: "y = x + C"},
        {option: "B", text: "y = -x + C"},
        {option: "C", text: "y = (x^2 + C)^(1/2)"},
        {option: "D", text: "y = x^2 - C"},
      ],
      answer: "C",
      mark: 5,
      completed:false

    },
    {
      paper: 1,
      type: "extended",
      question_count: 4,
      difficulty: "hard",
      
      question:
        "Find the number of solutions for the equation x^4 + 4y^4 = z^4 over the integers.",
      options: [
        {option: "A", text: "Infinitely many"},
        {option: "B", text: "None"},
        {option: "C", text: "Exactly one"},
        {option: "D", text: "Exactly two"},
      ],
      answer: "B",
      mark: 5,
      completed:false

    },
    {
      paper: 2,
      type: "extended",
      difficulty: "hard",
      

      question_count: 5,
      question: "Compute the integral of the function ∫e^(x^2) dx from 0 to 1.",
      options: [
        {option: "A", text: "√π/2"},
        {option: "B", text: "e - 1"},
        {
          option: "C",
          text: "The integral cannot be expressed in terms of elementary functions",
        },
        {option: "D", text: "1"},
      ],
      answer: "C",
      mark: 5,
      completed:false

    },
    {
      paper: 2,
      type: "extended",
      question_count: 6,
      difficulty: "easy",
      

      question: "What is 2^3?",
      options: [
        {option: "A", text: "6"},
        {option: "B", text: "8"},
        {option: "C", text: "10"},
        {option: "D", text: "12"},
      ],
      answer: "B",
      mark: 1,
      completed:false

    },
    {
      paper: 2,
      type: "extended",
      difficulty: "easy",
      question_count: 7,
      

      question: "What is the value of 5 factorial (5!)?",
      options: [
        {option: "A", text: "60"},
        {option: "B", text: "120"},
        {option: "C", text: "100"},
        {option: "D", text: "24"},
      ],
      answer: "B",
      mark: 1,
      completed:false

    },
    {
      paper: 2,
      type: "extended",
      

      difficulty: "medium",
      question_count: 8,
      question: "Solve for x: 3x^2 - 12 = 0",
      options: [
        {option: "A", text: "x = 2 or -2"},
        {option: "B", text: "x = 4 or -4"},
        {option: "C", text: "x = 3 or -3"},
        {option: "D", text: "x = 1 or -1"},
      ],
      answer: "A",
      mark: 3,
      completed:false

    },
    {
      paper: 2,
      type: "extended",
      difficulty: "medium",
      question_count: 9,
      

      question: "Find the area of a triangle with base 10 and height 5.",
      options: [
        {option: "A", text: "25"},
        {option: "B", text: "50"},
        {option: "C", text: "75"},
        {option: "D", text: "100"},
      ],
      answer: "B",
      mark: 3,
      completed:false

    },
    {
      paper: 2,
      type: "extended",
      difficulty: "hard",
      

      question_count: 10,
      question: "Find the eigenvalues of the matrix [[2, -1], [-1, 2]].",
      options: [
        {option: "A", text: "3, 1"},
        {option: "B", text: "2, 2"},
        {option: "C", text: "1, 3"},
        {option: "D", text: "2, -1"},
      ],
      answer: "A",
      mark: 5,
      completed:false

    },
    {
      paper: 2,
      type: "extended",
      

      difficulty: "hard",
      question_count: 11,
      question: "Evaluate the integral ∫(x^2 * e^x) dx.",
      options: [
        {option: "A", text: "e^x * (x^2 - 2x + 2)"},
        {option: "B", text: "e^x * (x^2 + 2x + 1)"},
        {option: "C", text: "e^x * (x^2 + 2x - 2)"},
        {option: "D", text: "e^x * (x^2 - 2x - 2)"},
      ],
      answer: "A",
      mark: 5,
      completed:false

    },
    {
      paper: 1,
      type: "extended",
      question_count: 12,
      

      difficulty: "hard",
      question: "Solve the differential equation dy/dx = e^(x - y).",
      options: [
        {option: "A", text: "y = x + e^x + C"},
        {option: "B", text: "y = x - e^x + C"},
        {option: "C", text: "y = e^x - x + C"},
        {option: "D", text: "y = x + e^(-x) + C"},
      ],
      answer: "A",
      mark: 5,
      completed:false

    },
  ];

  
  const [questions, setQuestions] = useState([]);
  const [originalQuestions, setOriginalQuestions] = useState([]);
  const [type, setType] = useState("all");
  const [papers, setPapers] = useState([]);
  const [difficulty, setDifficulty] = useState([]);
  const [view, setView] = useState("all");
  const [completed, setCompleted] = useState([]);

  useEffect(() => {
    setQuestions(data);
    setOriginalQuestions(data);

  }, []);

  //filterations

  useEffect(() => {
    console.log(difficulty);
    if (
      originalQuestions &&
      originalQuestions.length >= 1 &&
      Array.isArray(originalQuestions)
    ) {
      if (
        type != "all" ||
        papers.length >= 1 ||
        difficulty.length >= 1 ||
        view != "all" ||
        completed.length >= 1
      ) {
        const newData = originalQuestions.filter((item) => {
          console.log("change");

          if (type !== "all" && item.type !== type) {
            console.log(item);
            return false;
          }
          if (papers.length >= 1 && !papers.includes(item.paper)) {
            return false;
          }
          if (difficulty.length >= 1 && !difficulty.includes(item.difficulty)) {
            return false;
          }
          if ( view !="all"  && item.completed !=  view) {
            return false;
          }

          return true;
        });
        setQuestions(newData);
      } else {
        setQuestions(originalQuestions);
      }
    }
  }, [type, papers, difficulty, view, completed , originalQuestions]);


  const handleCompleteQuestion = (e , question)=>{
    
      const newData = originalQuestions.map(item=>{
        if(item.question_count == question){
          return({
            ...item,
            completed :e.target.checked,
          })
        }
        return item
      })
      
      console.log(newData)
      setOriginalQuestions(newData);
  }

  return (
    <div className='quize_page'>
      <IBMathBanners
        navigations={navigations}
        title={""}
        button={
          <div>
            <Filterations
              type={type}
              setType={setType}
              papers={papers}
              setPapers={setPapers}
              view={view}
              questions={originalQuestions}
              setView={setView}
              difficulty={difficulty}
              setDifficulty={setDifficulty}
              completed={completed}
              setCompleted={setCompleted}
            />
          </div>
        }
        paragraph={
          <Fragment>
            <p
              className='fw-semiboldx'
              style={{
                fontSize: "13px",
              }}
            >
              IB MATHEMATICS AA HL - POPULAR QUIZZES
            </p>
            <h1 className='fs-1 fw-bolder'>Topic 1 All</h1>
          </Fragment>
        }
      />

      <div className='quiz_questions_container py-4 bg-body-tertiary'>
        {questions?.map((item, index) => {
          return <QuestionCard handleCompleteQuestion={handleCompleteQuestion} key={index} data={item} />;
        })}
      </div>
    </div>
  );
};

export default QuizPage;

const Filterations = ({
  type,
  setType,
  papers,
  setPapers,
  view,
  setView,
  difficulty,
  setDifficulty,
  questions,
  completed,
  setCompleted,
}) => {
  const handleChangePaper = (e, item) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      setPapers((prev) => {
        return [...prev, item];
      });
    } else {
      const newPapers = papers.filter((paper) => paper !== item);
      setPapers(newPapers);
    }
  };

  const handleChangeDifficulty = (e, value) => {
    if (e.target.checked) {
      console.log(value);
      setDifficulty((prev) => {
        return [...prev, value];
      });
    } else {
      const newDifficulty = difficulty.filter((diff) => diff !== value);
      setDifficulty(newDifficulty);
    }
  };

  return (
    <div className='filterations_container '>
      <div className='filter_item d-flex  gap-5 w-100'>
        <div>
          <h6>Question Type</h6>
          <div className=' d-flex '>
            {/* <CheckBoxContent id='0' /> */}
            <ClickDropDown
              title={
                <div className='customCheckBoxHolder'>
                  <div className='customCheckBox px-3'>
                    {type} {chevron_down}
                  </div>
                </div>
              }
            >
              {[
                {
                  label: "All",
                  value: "all",
                },
                {
                  label: "Extended Response Questions",
                  value: "extended",
                },
                {
                  label: "Short Answer Questions",
                  value: "short",
                },
              ].map((item, index) => {
                return (
                  <div
                    key={index}
                    className='c-dropdown-item fs-6 p-2'
                    onClick={() => setType(item.value)}
                  >
                    {item.label}
                  </div>
                );
              })}
            </ClickDropDown>
          </div>
        </div>
        <div>
          <h6>Paper</h6>
          <div className=' d-flex '>
            {[
              {
                label: "Paper 1",
                value: 1,
              },
              {
                label: "Paper 2",
                value: 2,
              },
            ].map((item, index) => {
              return (
                <CheckBoxContent
                  onChange={(e) => handleChangePaper(e, item.value)}
                  key={index}
                  title={item.label}
                  id={item.label}
                />
              );
            })}
          </div>
        </div>
        <div>
          <h6>Difficulty</h6>
          <div className=' d-flex '>
            {[
              {
                label: "Easy",
                value: "easy",
              },
              {
                label: "Medium",
                value: "medium",
              },
              {
                label: "Hard",
                value: "hard",
              },
            ].map((item) => {
              return (
                <CheckBoxContent
                  id={item.value}
                  title={item.label}
                  onChange={(e) => handleChangeDifficulty(e, item.value)}
                />
              );
            })}
          </div>
        </div>
        <div>
          <h6>View</h6>
          <div className=' d-flex gap-0'>
            {[
              {
                label:"all",
                value:"all",
              },
              {
                label:"completed",
                value:true,
              },
              {
                label:"incomplete",
                value:false,
              }
            ].map((item) => {
              return (
                <CheckBoxContent
                  id={item.label}
                  title={item.label}
                  checked={item.value == view ? true : false}
                  onChange={() => setView(item.value)}
                />
              );
            })}
          </div>
        </div>
        <div>
          <h6>{"Jump To "}</h6>
          <div className=' d-flex '>
            {/* <CheckBoxContent id='0' /> */}
            <ClickDropDown
              title={
                <div className='customCheckBoxHolder'>
                  <div className='customCheckBox px-3'>
                    Jump To {chevron_down}
                  </div>
                </div>
              }
            >
              <div className='hump_numbers_container'>
                {questions?.map((item, index) => {
                  return (
                    <Link
                      style={{textDecoration: "none"}}
                      to={String(item?.question_count)}
                      smooth={true}
                      duration={100}
                      // spy={true}
                      offset={-150} // Adjust this offset to leave space between the top and the scrolled-to element
                    >
                      <div
                        style={{
                          borderColor:
                            item.difficulty == "easy"
                              ? " #13a54d"
                              : item.difficulty == "medium"
                              ? "#F79009"
                              : "#b70707",
                        }}
                        className='jump_number'
                        key={index}
                      >
                        {item.question_count}
                      </div>
                    </Link>
                  );
                })}
              </div>

              {/* <div className='c-dropdown-item fs-6 p-2'>All</div>
              <div className='c-dropdown-item fs-6 p-2'>
                Extended Response Questions
              </div>
              <div className='c-dropdown-item fs-6 p-2'>
                Short Answer Questions
              </div> */}
            </ClickDropDown>
          </div>
        </div>
      </div>
    </div>
  );
};
