import { useState } from 'react';
import ProfileCard from './card';
import { teachers } from "./data";
import './style.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
export default function TeachersCards({ teacherData }) {
    const [filteredTeachers, setFilteredTeachers] = useState(teachers);
    // useEffect(() => {
    //     if (teacherData)
    //         setFilteredTeachers();
    // }, [teacherData, teachers])
    return (
        <div className='teachers_cards_container'>
            <div className="container-unique">
                <h1 style={{ margin: "20px auto", width: "fit-content", color: "green", fontWeight: "900" }}>Why Choose Our Extended Essay Resources?</h1>
                <h1 className="headline-amazing">Enhance Your Teaching with Our Expert Extended Essay Resources!</h1>
                <h4 className="subheadline-incredible">
                    Discover a comprehensive range of resources designed specifically to support your teaching of the Extended Essay in subjects like History, Biology, Literature, and more!
                </h4>
                <h5 className="award-winning">
                    Voted #1 Extended Essay Resource by IB Teachers & Students
                </h5>
                <p className="description-detailed">
                    We're thrilled to introduce our dedicated resources for the Extended Essay! As an educator striving for excellence, our expertly crafted materials will empower you to guide your students through this critical component with confidence and success.
                </p>
                <div className='teachers_cards'>
                    <Swiper
                        modules={[Pagination, Navigation]}
                        pagination={{ clickable: true }}
                        navigation
                        spaceBetween={30}
                        slidesPerView={5}
                        loop={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        {
                            filteredTeachers?.map((item, index) => {
                                return (
                                    index < 8 ?
                                        <SwiperSlide style={{ width: "fit-content" }}> <ProfileCard item={item} />    </SwiperSlide> : null
                                )
                            })
                        }
                    </Swiper>
                </div>



            </div>
        </div>
    )
}
