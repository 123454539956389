import {useNavigate} from "react-router-dom";
import "./style.css";
import IBTopicCard from "./IBTopicCard/IBTopicCard";

export default function IBMathTopics() {
  const navigate = useNavigate();

  const topics = [
    {
      title: "Questionbank",
      icon: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
          <path d='m20 15.18l-2.59-2.59L16 14l4 4l6-6l-1.41-1.41zM8 18h2v2H8zm4-9H7v2h4v2H8v3h2v-1h2a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1'></path>
          <path d='M17.736 30L16 29l4-7h6c1.107 0 2-.893 2-2V8c0-1.107-.893-2-2-2H6c-1.107 0-2 .893-2 2v12c0 1.107.893 2 2 2h9v2H6a4 4 0 0 1-4-4V8c0-2.21 1.79-4 4-4h20c2.21 0 4 1.79 4 4v12a4 4 0 0 1-4 4h-4.835z'></path>
        </svg>
      ),
      info: "All the questions you could need! Sorted by topic and arranged by difficulty, with mark schemes and video solutions for every question.",
      bathname: "",
    },
    {
      title: "Practice Exams",
      icon: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
          <path d='M21 5c0-1.103-.897-2-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2zM5 19V5h14l.002 14z'></path>
          <path d='M7 7h1.998v2H7zm4 0h6v2h-6zm-4 4h1.998v2H7zm4 0h6v2h-6zm-4 4h1.998v2H7zm4 0h6v2h-6z'></path>
        </svg>
      ),
      info: "Choose your revision tool! Contains topic quizzes for focused study, Groot in IB mock exams covering the whole syllabus, and the revision ladder to precisely target your learning.",
      bathname: "/course/practice_exams",
    },
    {
      title: "Key Concept",
      icon: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
          <path d='m20 15.18l-2.59-2.59L16 14l4 4l6-6l-1.41-1.41zM8 18h2v2H8zm4-9H7v2h4v2H8v3h2v-1h2a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1'></path>
          <path d='M17.736 30L16 29l4-7h6c1.107 0 2-.893 2-2V8c0-1.107-.893-2-2-2H6c-1.107 0-2 .893-2 2v12c0 1.107.893 2 2 2h9v2H6a4 4 0 0 1-4-4V8c0-2.21 1.79-4 4-4h20c2.21 0 4 1.79 4 4v12a4 4 0 0 1-4 4h-4.835z'></path>
        </svg>
      ),
      info: "Helpful refreshers summarizing exactly what you need to know about the most important concepts covered in the course.",
      bathname: ""

    },
    {
      title: "Past Papers",
      icon: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
          <path d='M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255c-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284c.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5m-1 14h2v2h-2z'></path>
        </svg>
      ),
      info: "Full worked solutions to all past paper questions, taught by experienced IB instructors",
      bathname: "",
    },
  ];


  return (
    <div className='ibMath_topic_container'>
      <div className='ibMath_topics_boxes'>
        {topics?.map((topic, index) => {
          return (
            <IBTopicCard onClick={()=>navigate(topic.bathname)} key={index} title={topic.title} icon={topic.icon} info={topic.info} />
          );
        })}
      </div>
    </div>
  );
}
