import React from "react";
import "./style.css";
import VideoPlayer from "./../../components/VideoPlayer/VideoPlayer";

import page1 from "../../assets/images/page_image_1.png";
import page2 from "../../assets/images/page_iamge_2.png";
import page3 from "../../assets/images/page_iamge_3.png";
import page4 from "../../assets/images/page_iamge_4.png";
import {Check, checkiocn2} from "../../assets/svgIcons";

const Schools = () => {
  const features = [
    {
      image: page1,
      title: "Formative Assessment and Task Workflows",
      info: "Create formative assessments and tasks that are automatically marked and provide students with personalised feedback on their answers.",
      options: [
        "Over 10,000 IB exam-style questions ",
        "Easy to understand markschemes ",
        "Detailed supplementary video solutions",
      ],
    },
    {
      image: page2,
      title: "Teacher-only IB Exam Style Questionbank",
      info: "Access a curated bank of original exam-style questions that are unavailable to students.",
      options: [
        "Craft original assessments to maximize exam readiness",
        "Teacher-only questionback for the Sciences coming soon",
      ],
    },
    {
      image: page3,
      title: "Insights & differentiated feedback",
      info: "Gain a deeper understanding of your students learning through rich data and insights.",
      options: [
        "At a glance view of performance",
        "Actionable insights to improve learning outcomes",
        "Identify improvement areas and set remedial tasks",
      ],
    },
    {
      image: page4,
      title: "Unleash the power of Newton AI",
      info: "Newton is RV’s proprietary AI tool, which is constantly being trained on our learning material. This enables Newton to provide highly accurate grading against IB style markschemes.",
      options: [
        "Let Newton do an initial pass of marking for you",
        "Edit Newton's feedback and marking any way you see fit",
        "Retain control - you approve all feedback students receive",
      ],
    },
  ];

  return (
    <div className='schools_page'>
      <div className='banners_section'>
        <section id='hero' className='hero section dark-background'>
          <div
            id='hero-carousel'
            style={{
              position: "relative",
              zIndex: 3,
            }}
            className='container carousel carousel-fade'
          >
            <div className='heroContent container row'>
              <div className='hero_left col d-flex align-items-center justify-content-center'>
                <VideoPlayer url={"https://youtu.be/_1elXcrEe3Y"} />
              </div>
              <div className='hero_right px-4 col'>
                <div className='sttt fw-bolder text-light'>
                  GROOT IN IB FOR EDUCATORS
                </div>
                <div
                  className='fs-1 lh-0'
                  style={{
                    lineHeight: 1.2,
                    marginBlock: "20px",
                    color: "#FFF",
                  }}
                >
                  The worold's best educators now have access to the world's
                  best toolkit
                </div>
                <div className='sttt text-light pe-5'>
                  Bring a new dimentsion to student engagement through rich data
                  and insights, automated marking , and access to the workd's
                  best teaching and learning resources.
                </div>
                <div className='py-2 mt-3 d-flex align-items-center gap-2'>
                  <div className='try_for_free'>Try it free</div>
                  <div className='try_for_free unlock'>Unlock Now</div>
                </div>
              </div>
            </div>
          </div>
          <svg
            className='hero-waves'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            viewBox='0 24 150 28 '
            preserveAspectRatio='none'
          >
            <defs>
              <path
                id='wave-path'
                d='M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z'
              />
            </defs>
            <g className='wave1'>
              <use xlinkHref='#wave-path' x={50} y={3} />
            </g>
            <g className='wave2'>
              <use xlinkHref='#wave-path' x={50} y={0} />
            </g>
            <g className='wave3'>
              <use xlinkHref='#wave-path' x={50} y={9} />
            </g>
          </svg>
        </section>

        <div className='pt-4 features_section'>
          {features?.map((item, index) => {
            return (
              <Features
                dir={index % 2 == 0 ? "ltr" : "rtl"}
                key={index}
                data={item}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Schools;

const Features = ({dir, data}) => {
  return (
    <div
      className='feat_container  container my-3'
      data-aos={dir == "rtl" ? "fade-right" : "fade-left"}
      style={{
        direction: dir,
      }}
    >
      <div className='row align-items-center justify-content-between'>
        <div className='left_feet col '>
          <div className=''>
            <h4 className='feet_title fs-3'>{data.title}</h4>
            <div className='fs-5 my-4'>{data.info}</div>
            {data.options.map((item, index) => {
              return (
                <div
                  key={index}
                  className='feet_option fs-5 d-flex align-items-center gap-2'
                >
                  {checkiocn2}
                  {item}
                </div>
              );
            })}
          </div>
        </div>
        <div className='right_feet  col'>
          <img src={page1} alt='' />
        </div>
      </div>
    </div>
  );
};
