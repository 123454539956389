import React, {Fragment, useContext, useEffect, useState} from "react";
import "./style.css";
import {Button, Dropdown, Steps, Tag} from "antd";
import ReactSelect from "react-select";
import CheckBox from "./../../CheckBox/CheckBox";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {DeleteIcon, dots_six, TrashIcon2} from "../../../assets/svgIcons";
import ProgressBar from "../../progressBar/ProgressBar";

import {AssessmentContext} from "../../../pages/CreateAssessment/CreateAssessment"; // Import your context

const ReviewAssessment = () => {
  const {assessmentData, updateAssessment} = useContext(AssessmentContext);

  const questionsData = [
    {
      question:
        "Expand (2x + 1)^4 in descending powers of x and simplify your answer.",
      marks: 4,
      difficulty: "EASY",
    },
    {
      question:
        "Expand (2x - 3)^4 in descending powers of x and simplify your answer.",
      marks: 4,
      difficulty: "EASY",
    },
    {
      question:
        "Consider the expansion of (2x^2 + ax)^6. The constant term is 960. Find the possible values of a.",
      marks: 5,
      difficulty: "MEDIUM",
    },
    {
      question:
        "In the expansion of (2x + 1)^n, the coefficient of the term in x^2 is 40n, where n ∈ Z+. Find n.",
      marks: 5,
      difficulty: "HARD",
    },
    {
      question:
        "Consider the expansion of (2x^6 + qx^2)^10, where q ≠ 0. The coefficient of the term in x^40 is twelve times the coefficient of the term in x^36. Find q.",
      marks: 5,
      difficulty: "HARD",
    },
  ];

  const duratoins = [
    "15 minutes",
    "30 minutes",
    "45 minutes",
    "60 minutes",
    "75 minutes",
    "90 minutes",
    "105 minutes",
    "120 minutes",
    "135 minutes",
    "150 minutes",
    "165 minutes",
    "180 minutes",
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#ececec",
      zIndex: "100000",
      width: "200px",
      borderRadius: "999px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#ffffff",
      paddingInline: "20px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#eaeaea"
        : state.isFocused
        ? "#f3f3f3"
        : "#ffffff",
      color: "#000000",
      marginBlock: "5px",
      borderRadius: "10px",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "1px solid green",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#000000",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#ff0000",
    }),
  };

  const [expandModal, setExpandModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [items, setItems] = useState(questionsData);

  // Handles the end of the drag event
  const onDragEnd = (result) => {
    const {source, destination} = result;

    // If no destination, do nothing
    if (!destination) return;

    // If the item is dropped in the same place
    if (source.index === destination.index) return;

    // Reorder the list
    const reorderedItems = Array.from(items);
    const [removed] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, removed);

    // Update state with the reordered list
    setItems(reorderedItems);
    updateAssessment({
      ...assessmentData,
      topicBranch: {
        ...assessmentData.topicBranch,
        questionsData: reorderedItems,
      },
    });
  };

  useEffect(() => {
    console.log(assessmentData);
  }, [assessmentData]);

  const onSelectMaxTime = (val) => {
    console.log(val);

    updateAssessment({
      ...assessmentData,
      maximum_time: val,
    });
  };

  const show_hide_cdTime = (e) => {
    updateAssessment({
      ...assessmentData,
      showCDTimer: e.target.checked,
    });
  };

  const handleRemoveQuestion = (question) => {
    const newData = items.filter((item) => item.question !== question.question);
    setItems(newData);
  };

  const steps =
    assessmentData.assessmentType.title == "Build a custom assessment"
      ? [
          {
            title: "Setup",
            description: "",
          },
          {
            title: "Select subtopics",
            description: "",
          },
          {
            title: "Select questions",
            description: "",
          },
          {
            title: "Review",
            description: "",
          },
          {
            title: "Summery",
            description: "",
          },
        ]
      : [
          {
            title: "Setup",
            description: "",
          },
          {
            title: "Select",
            description: "",
          },
          {
            title: "Review",
            description: "",
          },
          {
            title: "Summary",
            description: "",
          },
        ];

  return (
    <div className='row'>
      <div className='col-2'>
        {/* <Stepper options={options} antd={true} /> */}
        <Steps
          className='custom-steps'
          current={assessmentData.assessmentType.title == "Build a custom assessment" ? 3 : 2}
          // onChange={(value) => setCurrent(value)}
          direction='vertical'
          items={steps}
        />
      </div>
      <div className='col-10'>
        <div className='rev_assessment'>
          <div className='section_one row'>
            <div className='col-4'>
              <h4 className='stttt'>Totals</h4>

              <div className='d-flex flex-column gap-3'>
                <div className=''>
                  <span className='py-1 px-2 rounded text-light bg-warning'>
                    7
                  </span>{" "}
                  Questions
                </div>
                <div>
                  <span className='py-1 px-2 rounded text-light bg-warning'>
                    50
                  </span>{" "}
                  Marks
                </div>
              </div>
            </div>
            <div className='col-8' style={{zIndex: 10}}>
              <h4 className='stttt mb-3'>Time Management</h4>
              <div className='stt mb-2 fw-bolder text-dark'>Maximum time</div>
              <ReactSelect
                options={duratoins.map((item) => ({
                  value: item,
                  label: item,
                }))}
                value={assessmentData?.maximum_time}
                styles={customStyles}
                onChange={(val) => onSelectMaxTime(val)}
              />
              <div className='d-flex align-items-center gap-2 mt-3'>
                <div>
                  <CheckBox
                    checked={assessmentData.showCDTimer}
                    id={123}
                    onChange={(e) => show_hide_cdTime(e)}
                  />
                </div>

                <div>
                  <div className='stt text-dark fw-bolder'>
                    Show countdown timer
                  </div>
                  <div className='stt mt-1'>
                    Show a countdown timer once students start the assessment
                  </div>
                </div>
              </div>
            </div>
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <div className='stttt fw-semibold my-3'>
              Review and reorder questions
            </div>
            <Droppable droppableId='droppable'>
              {(provided) => (
                <div
                  className='droppable_container'
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{padding: 8, backgroundColor: "#f0f0f0"}}
                >
                  {items?.map((item, index) => (
                    <Draggable
                      key={item.question}
                      draggableId={item.question}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className='droppalble_item'
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          // {...provided.dragHandleProps} // Add dragHandleProps to the handle
                          // style={{
                          //   // ...provided.draggableProps.style,

                          // }}
                        >
                          <span
                            className='drop_handler'
                            {...provided.dragHandleProps}
                            style={{cursor: "grab"}}
                          >
                            {dots_six}
                          </span>
                          <div className='droppalbe_question_content'>
                            <div className='droppalble_branch'>
                              <div className='branch_question '>
                                <div className='w-75 '>{item.question}</div>
                                <div className='w-25 '>
                                  <div className='d-flex align-items-center justify-content-between'>
                                    <Tag
                                      color='geekblue'
                                      style={{cursor: "pointer"}}
                                      onClick={() => {
                                        setExpandModal(true);
                                        setRowData(item);
                                      }}
                                    >
                                      {icons.expand}{" "}
                                    </Tag>
                                    <Tag
                                      color='error'
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "20px",
                                      }}
                                      onClick={() => handleRemoveQuestion(item)}
                                    >
                                      {TrashIcon2}
                                    </Tag>
                                  </div>
                                  <div className='my-2'></div>
                                  <Tag color='geekblue'>{item.marks} marks</Tag>
                                  <div className='my-2'></div>
                                  <ProgressBar
                                    title={
                                      <span className='st'>
                                        {" "}
                                        {item.difficulty}
                                      </span>
                                    }
                                    progress={10}
                                    bg_container={"#EBF7E8"}
                                    bg_progress={"#97C48B"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
};

export default ReviewAssessment;

const icons = {
  question: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
    >
      <path
        fill='currentColor'
        d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10m-1-7v2h2v-2zm2-1.645A3.502 3.502 0 0 0 12 6.5a3.5 3.5 0 0 0-3.433 2.813l1.962.393A1.5 1.5 0 1 1 12 11.5a1 1 0 0 0-1 1V14h2z'
      ></path>
    </svg>
  ),

  check: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
    >
      <path
        fill='currentColor'
        d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10m-1-7v2h2v-2zm2-1.645A3.502 3.502 0 0 0 12 6.5a3.5 3.5 0 0 0-3.433 2.813l1.962.393A1.5 1.5 0 1 1 12 11.5a1 1 0 0 0-1 1V14h2z'
      ></path>
    </svg>
  ),
  timer: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
    >
      <path
        fill='currentColor'
        d='M6 2h12v6l-4 4l4 4v6H6v-6l4-4l-4-4zm10 14.5l-4-4l-4 4V20h8zm-4-5l4-4V4H8v3.5zM10 6h4v.75l-2 2l-2-2z'
      ></path>
    </svg>
  ),

  expand: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
    >
      <path
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M10 19H5v-5m9-9h5v5'
      ></path>
    </svg>
  ),
};
