import React from "react";
import "./style.css";

import SVGComponent from "./svgviewer-react-output";
import { arrow_right_tail, arrowRight } from "../../../assets/svgIcons";

const StudyingSection = () => {
  const st_items = [
    // {
    //   name: "GcSE",
    // },
    // {
    //   name: "IGCSE",
    // },
    // {
    //   name: "AS",
    // },
    // {
    //   name: "A Level",
    // },
    // {
    //   name: "O Level",
    // },
    {
      name: "IB",
    },
    // {
    //   name: "AP",
    // },
  ];

  return (
    <div className='studying_section'>
      <div className='container st_container'>


        <div className='st_svg'>
          <SVGComponent />
          <div className="svg_text">
          WHAT ARE YOU STUDYING?

          </div>
        </div>

        <h3
          className='title get_started_title st-title fs-1'
          style={{
            color: "#2e2e2e",
          }}
        >
          Get started - for free <span className="start_arrow">{arrow_right_tail}</span>
        </h3>

        <div className='st_items'>
          {st_items.map((item, index) => {
            return <div>
              <div className='st_item'>{item.name}</div>
              {index == 4 ? <div></div>:""}

            </div>

             ;
          })}
        </div>
      </div>
    </div>
  );
};

export default StudyingSection;
