import React from "react";
import IBMathBanners from "../../components/IBMathPages/IBMathBanners/IBMathBanners";
import RevisionTypesContainer from "../../components/RevisionTypesContainer/RevisionTypesContainer";
import "./style.css";
import QuizCard from "../../components/QuizCard/QuizCard";
import { useNavigate } from "react-router-dom";

const PopularQuizzesPage = () => {
  const navigations = [
    {
      name: "IB MATH",
      bathname: "",
    },
    {
      name: "IB Math AA SL",
      bathname: "",
    },
    {
      name: "Practice Exams",
      bathname: "",
    },
  ];


  const quizzes = [
    {
      topic: "Number & Algebra",
      subtopics: [
        {
          name: "All",
          questions: 20,
          duration: 190,
        },
        {
          name: "Sequences & Series",
          questions: 8,
          duration: 60,
        },
        {
          name: "Compound Interest",
          questions: 4,
          duration: 45,
        },
        {
          name: "Exponents & Logs",
          questions: 9,
          duration: 55,
        },
        {
          name: "The Binomial Theorem",
          questions: 7,
          duration: 45,
        },
        {
          name: "Counting Principles",
          questions: 6,
          duration: 35,
        },
        {
          name: "Complex Numbers",
          questions: 7,
          duration: 80,
        },
        {
          name: "Proofs",
          questions: 7,
          duration: 55,
        },
        {
          name: "Systems of Linear Equations",
          questions: 4,
          duration: 35,
        },
      ],
    },
    {
      topic: "Functions",
      subtopics: [
        {
          name: "All",
          questions: 21,
          duration: 180,
        },
        {
          name: "Properties of Functions",
          questions: 6,
          duration: 55,
        },
        {
          name: "Quadratics",
          questions: 5,
          duration: 60,
        },
        {
          name: "Rational Functions",
          questions: 5,
          duration: 60,
        },
        {
          name: "Exponential & Logarithmic Functions",
          questions: 7,
          duration: 70,
        },
        {
          name: "Transformations",
          questions: 6,
          duration: 60,
        },
        {
          name: "Polynomials",
          questions: 6,
          duration: 60,
        },
        {
          name: "Modulus Functions & Inequalities",
          questions: 4,
          duration: 45,
        },
      ],
    },
  ];

  const navigate = useNavigate()

  return (
    <div>
      <IBMathBanners
        navigations={navigations}
        title={"IB Mathematics AA SL - Practice Exams"}
        paragraph={
          "The IB Mathematics Analysis & Approaches (AA) Standard Level (SL) Practice exams are the perfect resource to customize your revision plan. You can hone your skills on important concepts using the Math AA SL Popular Quizzes, or ascend the Math AA SL Revision Ladder to reach the hardest questions in the IB! Finally, full length RV-exclusive Math AA SL Mock Exam Papers will simulate the experience of taking the actual exams from start to finish."
        }
      />

      <RevisionTypesContainer />
      <div className='pop_quizzes_container'>
        <h2 className='title fw-bolder fs-3 mt-3 '>Popular Quizzes</h2>
        <p className='text-center'>
          Perfect for a quick study session on any IB Mathematics AA HL topic or
          concept.
        </p>

        {quizzes?.map((item, index) => {
          return (
            <div className='topics_container'>
              <h5 className='topic_number'> Topic {index + 1}</h5>
              <h4 className='topic_name'>{item.topic}</h4>
              <div className='quizzes_container'>
                  {item.subtopics?.map((quiz, index) => {
                    return(

                      <QuizCard onClick={()=>navigate(`${item.topic.replaceAll(" " , "_").toLowerCase()}/${quiz.name.replaceAll(" " , "_").toLowerCase()}`)} data={quiz} key={index} />
                    )

                  })}
              </div>
            </div>
          );
        })}

      </div>

    </div>
  );
};

export default PopularQuizzesPage;
