import React from "react";
import "./style.css";
import {useNavigate} from "react-router-dom";
import {LockIcon2} from "../../assets/svgIcons";

const FlatCard = ({data, index}) => {
  const navigate = useNavigate();
  return (
    <div
      className='select-cat-card'
      onClick={() => navigate("/Subjects/en/" + data?.group_id)}
    >
      <div className='card-info'>
        <div className='card_title'>
          Groub#{index}: {data.group_name}
        </div>
        <div className='activities'>{data.description}</div>
        <div className='levelCardDesign levelGroupCardDesign'>
          <div>
            <span className="d-flex align-items-center gap-1" onClick={() => navigate("/Subjects/sp/" + data?.group_id)}>
              Spanish
              {LockIcon2}
            </span>
            <div className='stt mt-2 text-light'>comming soon</div>
          </div>
          <div>

          <span className="d-flex align-items-center gap-1" onClick={() => navigate("/Subjects/en/" + data?.group_id)}>
            English
            {LockIcon2}
          </span>
          <div className="stt mt-2 text-light">comming soon</div>
          </div>
          <div>

          <span className="d-flex align-items-center gap-1" onClick={() => navigate("/Subjects/fr/" + data?.group_id)}>
            French
            {LockIcon2}
          </span>
          <div className="stt mt-2 text-light">comming soon</div>
          </div>
        </div>
      </div>
      <div className='hover_animation'></div>

      <div className='wave_1'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='400'
          height='200'
          viewBox='0 0 1920 1080'
        >
          <path
            fill='hsla(138, 74.30%, 21.40%, 0.94)'
            d='M724 4c2.333 108.213-54.232 210.731-130.154 286.343-75.922 75.611-211.058 99.029-325.376 167.326C154.152 525.967-13.913 690.72-92.063 700.13c-78.15 9.41-24.934-105.671-108.367-185.998-83.432-80.327-318.856-193.12-392.228-295.962-73.371-102.842-73.27-203.876-48-321.092C-615.387-220.14-539.13-409.731-441.03-485.131c98.1-75.4 266.282-80.687 388.968-70.194 122.687 10.493 241.831 100.42 347.15 133.152 105.318 32.732 213.274-7.788 284.76 63.24C651.331-287.903 721.666-104.213 724 4c2.333 108.213-54.232 210.731-130.154 286.343'
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default FlatCard;
