import React, {createContext, useEffect, useState} from "react";
import "./style.css";
import CustomSlider from "../../components/CustomSlider/CustomSlider";
import ChooseAssessment from "../../components/CreatedAssessment/ChooseAssessment/ChooseAssessment";
import AssessmentType from "../../components/CreatedAssessment/AssessmentType/AssessmentType";
import Stepper from "../../components/Stepper/Stepper";
import AssessmentSetup from "../../components/CreatedAssessment/AssessmentSetup/AssessmentSetup";
import AssessmentSelect from "../../components/CreatedAssessment/AssessmentSelect/AssessmentSelect";
import ReviewAssessment from "../../components/CreatedAssessment/ReviewAssessment/ReviewAssessment";
import AssessMentSummery from "../../components/CreatedAssessment/AssessmentSummery/AssessMentSummery";
import AssessmentSelectSubtopics from "../../components/CreatedAssessment/AssessmentSelectSubtopics/AssessmentSelectSubtopics";
import SelectQuestions from "../../components/CreatedAssessment/SelectQuestions/SelectQuestions";

export const AssessmentContext = createContext();

const CreateAssessment = () => {
  const [assessmentData, setAssessmentData] = useState({
    course: {},
    assessmentType: "",
    taskName: "",
    availableFrom: "",
    availableTo: "",
    vidSolution: "",
    options: [],
    topicBranch: {},
    selectedTopicBranches: [],
    maximum_time: "",
    selectedQuestions: [],
    showCDTimer: false,
  });

  const [components , setComponents] = useState([])

  const commonComponents = [
    {
      title: "Select your course",
      component: <ChooseAssessment />,
    },
    {
      title: "How would you like to create your assessment?",
      component: <AssessmentType />,
    },
    {
      title: " ",
      component: <AssessmentSetup showSteps={true} />,
    },
  ];

  const practiceComponents = [
    {
      title: " ",
      component: <AssessmentSelect />,
    },

    {
      title: " ",
      component: <ReviewAssessment />,
    },
    {
      title: " ",
      component: <AssessMentSummery />,
    },
  ];

  const builCustomExamComponents = [
    {
      title: "",
      component: <AssessmentSelectSubtopics />,
    },
    {
      title: "",
      component: <SelectQuestions />,
    },
    {
      title: "",
      component: <ReviewAssessment />,
    },
    {
      title: "",
      component: <AssessMentSummery />,
    },
  ];



  useEffect(()=>{
    if(assessmentData.assessmentType.title == "Build a custom assessment"){
      setComponents([
        ...commonComponents,
        ...builCustomExamComponents,
      ])
    }else{
      setComponents([
        ...commonComponents,
        ...practiceComponents,
      ])

    }
  },[assessmentData])

  const updateAssessment = (newData) => {
    setAssessmentData(newData);
  };

  const value = {assessmentData, updateAssessment};

  return (
    <AssessmentContext.Provider value={value}>
      <div className='create_assesmemt_page'>
        {/* <h2 className='title fs-3 fw-bolder'>Select your course</h2> */}
        <div className='container'>
          <CustomSlider components={components} />
        </div>
      </div>
    </AssessmentContext.Provider>
  );
};

export default CreateAssessment;
