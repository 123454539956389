import { faCalculator, faAtom, faFlask, faDna, faLeaf, faBriefcase, faChartLine, faBrain, faBook } from '@fortawesome/free-solid-svg-icons';

export const subjects = [
    { icon: faCalculator, title: 'IB Mathematics', description: 'Master every topic with detailed guides, practice problems, and step-by-step solutions.' },
    { icon: faAtom, title: 'Physics', description: 'Explore the principles of physics with engaging tutorials, experiments, and real-world applications.' },
    { icon: faFlask, title: 'Chemistry', description: 'Understand the building blocks of matter with clear explanations, interactive exercises, and lab simulations.' },
    { icon: faDna, title: 'Biology', description: 'Dive into the study of life with comprehensive notes, diagrams, and review questions.' },
    { icon: faLeaf, title: 'Environmental Systems and Societies (ESS)', description: 'Learn about the interrelationships between ecosystems and human societies with case studies and project ideas.' },
    { icon: faBriefcase, title: 'Business Management', description: 'Develop a strong foundation in business principles, strategies, and real-world business scenarios.' },
    { icon: faChartLine, title: 'Economics', description: 'Gain insights into economic theories, models, and policies with our extensive resources.' },
    { icon: faBrain, title: 'Psychology', description: 'Understand human behavior and mental processes through detailed notes and research summaries.' },
    // { icon: faBook, title: 'English', description: 'Enhance your language and literature skills with analysis guides, writing tips, and practice exercises.' }
  ];
