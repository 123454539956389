import React, {useState} from "react";
import "./style.css";
import {pan_zoom} from "../../assets/svgIcons";
import Modal from "./../Modal/Modal";
import ProgressBar from "../progressBar/ProgressBar";
import CustomQButton from "../CustomQButton/CustomQButton";
import PdfViewer from "../../util/PdfViewer/PdfViewer";
import ReactPlayer from "react-player";
import VideoPlayer from "./../VideoPlayer/VideoPlayer";
import CheckBoxContent from "./../CheckBoxContent/CheckBoxContent";

const QuestionCard = ({data, handleCompleteQuestion}) => {
  const [modals, setModals] = useState({
    showQustion: false,
    booklet: false,
    markScheme: false,
    player: false,
  });

  const [selectedVideo, setSelectedVid] = useState("");

  return (
    <div
      className='question_card_container Katex_font'
      id={data.question_count}
    >
      <div className='d-flex align-items-center justify-content-between'>
        <h3 className='qc_title '>Question {data.question_count}</h3>

        <CheckBoxContent
          onChange={(e) => handleCompleteQuestion(e, data.question_count)}
          id={`questoin__${data.question_count}`}
          title={ data.completed ? "Mark as incomplete" : "Mark as complete"}
          checked={data.completed}
        />
      </div>
      <div className='queston_content Katex_font'>
        <div className='row'>
          <div className=' col-12 queston_content_left_side katex_font'>
            <div className='row align-items-center justify-content-between'>
              <div className='col'>[Maximum mark: {data.mark}]</div>
              <div className='col'>
                <ProgressBar
                  progress={data.difficulty}
                  title={"EASY"}
                  bg_container={"#EBF7E8"}
                  bg_progress={"#97C48B"}
                />
              </div>
            </div>
            <div className='q_title Katex_font'>
              {data.question}
              <div
                className='zoom_pan'
                onClick={() =>
                  setModals({
                    ...modals,
                    showQustion: true,
                  })
                }
              >
                {pan_zoom}
              </div>
            </div>

            <div className='answers'>
              <ol>
                {data.options.map((item, index) => {
                  return (
                    <li key={index}>
                      <div>
                        <div>{item.text}</div>
                        {/* <div>[2]</div> */}
                      </div>
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>

          <div className=' col-12 question_right_side'>
            <CustomQButton
              type={"formula"}
              onClick={() =>
                setModals({
                  ...modals,
                  booklet: true,
                })
              }
              text='Formula Booklet'
            />
            <CustomQButton
              type={"mark"}
              onClick={() =>
                setModals({
                  ...modals,
                  markScheme: true,
                })
              }
              text='Mark Scheme'
            />

            {data.videos?.map((item, index) => {
              return (
                <CustomQButton
                  type={"video"}
                  onClick={() => {
                    setModals({
                      ...modals,
                      player: true,
                    });

                    setSelectedVid(item);
                    console.log(item);
                  }}
                  text={`Video (${index + 1})`}
                />
              );
            })}

            <CustomQButton type={""} text='Check widh RV Newton' />
          </div>
        </div>
      </div>

      <Modal
        size={"500px"}
        show={modals.showQustion}
        showCloseBtn={true}
        animation={true}
        overlay={true}
        title={"Question 1"}
        onClose={() =>
          setModals({
            ...modals,
            showQustion: false,
          })
        }
        children={
          <>
            <div className=' queston_content_left_side katex_font'>
              <div className=' align-items-center justify-content-between'>
                <div className=''>[Maximum mark: {data.mark}]</div>
              </div>
              <div className='q_title Katex_font'>{data.question}</div>

              <div className='answers'>
                <ol>
                  {data.options.map((item, index) => {
                    return (
                      <li key={index}>
                        <div>
                          <div>{item.text}</div>
                          {/* <div>[2]</div> */}
                        </div>
                      </li>
                    );
                  })}
                </ol>
              </div>
            </div>
          </>
        }
      />
      <Modal
        size={"90%"}
        show={modals.booklet}
        showCloseBtn={true}
        animation={true}
        overlay={true}
        onClose={() =>
          setModals({
            ...modals,
            booklet: false,
          })
        }
        children={
          <>
            <PdfViewer file={require("../../assets/pdfs/formulaBooklet.pdf")} />
          </>
        }
      />

      <Modal
        size={"90%"}
        show={modals.markScheme}
        title={`Questoin ${data.question_count}`}
        showCloseBtn={true}
        animation={true}
        overlay={true}
        onClose={() =>
          setModals({
            ...modals,
            markScheme: false,
          })
        }
        children={
          <>
            <div className='mark_scheme_container'>
              <div className=' queston_content_left_side katex_font border-end border-3'>
                <div className=' align-items-center justify-content-between'>
                  <div className=''>[Maximum mark: {data.mark}]</div>
                </div>
                <div className='q_title Katex_font'>{data.question}</div>

                <div className='answers'>
                  <ol>
                    {data.options.map((item, index) => {
                      return (
                        <li key={index}>
                          <div>
                            <div>{item.text}</div>
                            {/* <div>[2]</div> */}
                          </div>
                        </li>
                      );
                    })}
                  </ol>
                </div>
              </div>
              <div className='mark_scheme_content'>
                Here will be the Mark Scheme
              </div>
            </div>
          </>
        }
      />
      <Modal
        size={"90%"}
        show={modals.player}
        title={`Questoin ${data.question_count}`}
        showCloseBtn={true}
        animation={true}
        overlay={true}
        onClose={() => {
          setModals({
            ...modals,
            player: false,
          });
          setSelectedVid("");
        }}
        children={
          <>
            <div className='video_buttons d-flex align-items-center gap-3 my-4'>
              {data.videos?.map((item, index) => {
                return (
                  <button
                    onClick={() => setSelectedVid(item)}
                    className='green_btn fit '
                    key={index}
                  >
                    {index + 1}
                  </button>
                );
              })}
            </div>
            {selectedVideo &&
              <VideoPlayer url={selectedVideo} />
              }
          </>
        }
      />
    </div>
  );
};

export default QuestionCard;
