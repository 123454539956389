import contentImg from '../../../assets/images/student4.jpeg';
import './style.css';

export default function IBMathContent() {
  return (
    <div className='ibMath_content_container'>
      <div className='ibMath_content_text-box'>
        <h2>Course Structure and Content</h2>
        <p>The Standard Level AA course is divided into five broad topics: Number & Algebra, Functions, Geometry & Trigonometry, Statistics & Probability, and Calculus. The focus of Analysis SL is spread fairly evenly across the five topics, although the connections between functions (including exponential and logarithmic), trigonometry, and calculus are particularly important .The approach to these topics is structured in such a way as to stimulate analysis, facilitate investigation, and invite theorizing and substantiation.</p>
      </div>

      <div className='ibMath_content_box'>
        <img src={contentImg} alt='content image'/>
        <div>
            <p>At the end of the program, all AA SL students sit paper 1 and paper 2. Both papers can cover the full breadth of the curriculum.</p>
            <p>Paper 1 is a 90 minute long non-calculator paper, with a focus on algebraic manipulation, inquiry, reasoning, and interpretation based on conceptual understanding.</p>
            <p>In paper 2, which is also 90 minutes long, a calculator is required. Therefore paper 2 has a greater focus on using technology to explore mathematical problems, and inquiry, reasoning and interpretation based on these approaches and findings.</p>
            <p>In AA Standard Level, both papers 1 and 2 consist of a short answer section and an extended response section.</p>
        </div>
      </div>

      <p className='ibMath_content_paragraph'>All SL analysis and approaches students are also required to submit an internal assessment - a written piece of work known as the exploration. This is an opportunity for students to apply mathematics learned in the course to a topic of interest to them.</p>
    </div>
  )
}
