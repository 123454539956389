import React, { useState, useEffect } from 'react';
import './style.css';
import ReactSelect from 'react-select';
import { subjects } from './data';

export default function TeacherBanner({ setTeacherData, teacherData, setSearchValue, searchValue }) {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    fetch('https://countriesnow.space/api/v0.1/countries')
      .then(response => response.json())
      .then(data => {
        const countryList = data?.data;
        setCountries(countryList);
      });
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      console.log(countries, countries?.filter(item => item?.country == selectedCountry)[0]?.cities)
      setCities(countries?.filter(item => item?.country == selectedCountry)[0]?.cities);

    }
  }, [selectedCountry]);
  useEffect(() => {
    console.log("teacherData", teacherData)
  }, [teacherData])
  function handleSearch() {
    setTeacherData({ country: selectedCountry, subject: "chemistry", online: 1 });
  }





  return (
    <div className="SubjectsBanner">
      <video loop autoPlay muted src={require("./stock-footage-zoom-in-time-lapse-of-smart-person-solving-scientific-problem-writing-formulas-on-chalkboard.webm")}></video>

      <div className='teacher-banner-search'>
        <div className="list">
          <label htmlFor="">Type</label>
          <select value={teacherData?.online} onChange={(e) => { setTeacherData({ ...teacherData, online: e.target.value }) }}>
            <option value={""}>All</option>
            <option value={"1"}>Online</option>
            <option value={"0"}>Offline</option>
          </select>
        </div>
        {teacherData?.online == "0" ? (
          <>
            <div className="list">
              <label htmlFor="">Country</label>
              <select onChange={(e) => setSelectedCountry(e.target.value)}>
                <option value="">Any Country</option>
                {countries.map(country => <option key={country?.country} value={country?.country}>{country?.country}</option>)}
              </select>
            </div>
            <div className="list">
              <label htmlFor="">City</label>
              <select onChange={(e) => setTeacherData({ ...teacherData, city: e.target.value })}>
                <option value="">Any City</option>
                {cities.map(city => <option key={city} value={city}>{city}</option>)}
              </select>
            </div>
          </>
        ) : null}
        <div style={{ minWidth: "200px" }}>
          {/* <ReactSelect
          // isMulti={true}
          placeholder="Subjects"
          options={subjects.map((city) => ({
            label: city,
            value: city,
          }))}
        /> */}
          <div className="list">
            <label htmlFor="">Subjects</label>
            <select>
              <option value="">Subjects</option>
              {subjects.map(city => <option key={city} value={city}>{city}</option>)}
            </select>
          </div>
        </div>
        <button onClick={handleSearch}>Search</button>
      </div>
    </div>
  )
}
