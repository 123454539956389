

import React from 'react'
import "./style.css"
import SubjectsSection from '../../components/HomePage/SubjectsSection/SubjectsSection'
import Teatchers from '../../components/HomePage/Teachers/Teatchers'
import PracticeStyle from '../../components/practiceStyle/practiceStyle'
import PowerSection from '../../components/HomePage/PowerSection/PowerSection'
import EffectiveRev from '../../components/HomePage/EffectvieRev/EffectiveRev'
import MoreIBMathResources from '../../components/HomePage/MoreIBMathResources/MoreIBMathResources'
import GetStarted from '../../components/HomePage/GetStrted/GetStarted'
import StudyingSection from './../../components/HomePage/Studying/StudyingSection';
import GroupCard from '../../components/HomePage/GroupsSection/GroupSection'
import WhyUs from './whyus'
import TeachersCards from '../../components/Teachers/TeachersCards/TeachersCardsHome'

const HomePage = () => {
  return (
    <div className='home_container'>
      <SubjectsSection />
      {/* <Teatchers /> */}
      {/* <PracticeStyle /> */}
      <GroupCard />
      <WhyUs />
      <StudyingSection />
      <PowerSection />
      <TeachersCards teachers={()=> null} setSearchValue={()=> null} searchValue={null}/>
      <EffectiveRev />
      <MoreIBMathResources />
      {/* <GetStarted /> */}

    </div>
  )
}

export default HomePage
