export const subjects = [
    "Algebra",
    "Geometry",
    "Calculus",
    "Statistics",
    "Physics",
    "Chemistry",
    "Biology",
    "Environmental Science",
    "Literature",
    "Composition",
    "Creative Writing",
    "Grammar",
    "Ancient History",
    "Modern History",
    "World History",
    "European History",
    "Drawing",
    "Painting",
    "Sculpture",
    "Art History",
    "Sports Science",
    "Health Education",
    "Fitness Training",
    "Physical Activities",
    "Physical Geography",
    "Human Geography",
    "Environmental Geography",
    "Cartography",
    "Programming",
    "Data Structures",
    "Algorithms",
    "Software Engineering",
    "Genetics",
    "Ecology",
    "Human Anatomy",
    "Evolution",
    "Organic Chemistry",
    "Inorganic Chemistry",
    "Physical Chemistry",
    "Analytical Chemistry",
    "Mechanics",
    "Electromagnetism",
    "Quantum Physics",
    "Thermodynamics",
    "Music Theory",
    "Performance",
    "Music History",
    "Acting",
    "Directing",
    "Theater History",
    "Playwriting",
    "Microeconomics",
    "Macroeconomics",
    "International Economics",
    "Economic Theory",
    "Social Theory",
    "Research Methods",
    "Cultural Sociology",
    "Social Issues",
    "Ethics",
    "Metaphysics",
    "Epistemology",
    "Logic",
    "Mechanical Engineering",
    "Electrical Engineering",
    "Civil Engineering",
    "Computer Engineering",
    "Descriptive Statistics",
    "Inferential Statistics",
    "Probability",
    "Data Analysis",
    "Cognitive Psychology",
    "Developmental Psychology",
    "Social Psychology",
    "Abnormal Psychology",
    "Comparative Politics",
    "International Relations",
    "Political Theory",
    "Public Policy",
    "Classical Literature",
    "Modern Literature",
    "Poetry",
    "Drama",
    "Comparative Religion",
    "Philosophy of Religion",
    "Theology",
    "Architectural Design",
    "Building Construction",
    "Urban Planning",
    "Architectural History",
    "Design Principles",
    "Typography",
    "Digital Media",
    "Illustration",
    "Ballet",
    "Modern Dance",
    "Jazz Dance",
    "Contemporary Dance"
];