import './style.css';
export default function IBMathTips() {
  return (
    <div className='ibMath_tips_container'>
        <h2>Tips for Success</h2>
        <ol>
            <li>Accurate and concise mathematical communication is vital - show all your steps of working clearly.</li>
            <li>Always simplify as much as possible - it often makes next steps easier, non-simplified answers can lose marks and it is good practice for your algebraic manipulation!</li>
            <li>Particularly in long questions with multiple parts, look for information or solutions from previous parts to help you.</li>
        </ol>
    </div>
  )
}
