import React, { useEffect, useState } from "react";
import "./style.css";
import logo from "../../assets/images/logo.png";
import { chevron_down, userInCircle } from "../../assets/svgIcons";
import { NavLink, useNavigate } from "react-router-dom";
import ClickDropDown from "../../components/ClickDropDown/ClickDropDown";
import { groupsData } from "../../components/HomePage/GroupsSection/data";



const Header = () => {
  const educators = [
    { name: 'School' },
    { name: 'School Partnerships' },
    { name: 'Teachers',

      pathname:'/teachers'

     },
    { name: 'Study Sessions' },
    { name: 'Careers' },
  ];

  const students = [
    { name: "Study Sessions" },
    { name: "Become a member" },
    { name: "Get RV Gold" },
  ];

  const subjects = [
    { name: "Math" },
    { name: "Science" },
    { name: "History" },
    { name: "Languages" },
  ];

  const navigate = useNavigate()
  const [selectedGroup , setSelectedGroup] = useState("");

  useEffect(()=>{
    console.log(selectedGroup)
  },[selectedGroup])



  return (
    <div className='header_container'>
      <div className='header_content'>
        <div className='left_side'>
          <div className='logo' onClick={()=>navigate("/")}>
            <img src={logo} alt='logo' />
          </div>
          <div className='left_side_items'>
            {/* <NavLink to={"/Subjects"}>Subjects</NavLink> */}
            <div className='dropdown'>
              <div className='item'>Subjects {chevron_down}</div>
              <div className='mega_menu subjects w-100' style={{
                width:"1000px"
              }}>
                <div className="mega_menu_column">
                  <div className="row">
                    <div className="col-3 topics">
                      {
                        groupsData?.map((item , index)=>{
                          return(
                            <div 
                            key={index}
                            onClick={() => setSelectedGroup(item)}
                            className={`d-flex align-items-center gap-2 px-2 py-1 rounded topic ${
                              item.group_id == selectedGroup.group_id ? "active" : ""
                            }`}
                            >
                               <div className='sttt text-dark'>{item?.group_name}</div>
                            </div>
                          )
                        })
                      }
                    </div>
                    <div className="col-9 ">
                      <div className="header_subjects">
                      {
                        selectedGroup?.subjects?.map((item , index)=>{
                          return(
                            <>
                            <div key={index} className="d-flex flex-column gap-2">
                              <div className="sttt fw-bolder">{item?.subject_name} HL</div>
                              <div>Question Banck</div>
                              <div>Key Concpets</div>
                              <div>Practice Exams</div>
                              <div>Past Papers</div>
                            </div>
                            <div  className="d-flex flex-column gap-2">
                              <div className="sttt fw-bolder">{item?.subject_name} SL</div>
                              <div>Question Banck</div>
                              <div>Key Concpets</div>
                              <div>Practice Exams</div>
                              <div>Past Papers</div>
                            </div>
                            </>
                          )
                        })
                      }
                    </div>
                            </div>
                  </div>




                  
                </div>
              </div>
            </div>
            <NavLink to={"/assesmens/create"}>Assessments</NavLink>
            <div className='dropdown'>
              <div className='item'>Educators {chevron_down}</div>
              <div className='mega_menu'>
                <div className="mega_menu_column">
                  {educators.map((item, index) => (
                    <div className="mega_menu_item" key={index} 
                     onClick={()=>{
                      if(item.pathname) navigate(item?.pathname)
                     }}
                    
                    >{item.name}</div>
                  ))}
                </div>
              </div>
            </div>
            {/* <div className='dropdown'>
              <div className='item'>Students {chevron_down}</div>
              <div className='mega_menu'>
                <div className="mega_menu_column">
                  {students.map((item, index) => (
                    <div className="mega_menu_item" key={index}>{item.name}</div>
                  ))}
                </div>
              </div>
            </div>
            <div className='dropdown'>
              <div className='item'>Subjects {chevron_down}</div>
              <div className='mega_menu'>
                <div className="mega_menu_column">
                  {subjects.map((item, index) => (
                    <div className="mega_menu_item" key={index}>{item.name}</div>
                  ))}
                </div>
              </div>
            </div>
            <NavLink to={"/Resources"}>Resources</NavLink>
            <NavLink to={"/About"}>About Us</NavLink>
            <NavLink to={"/Contact"}>Contact</NavLink> */}
          </div>
        </div>
        <div className='right_side'>

        {/* <div className='dropdown'>
              <div className='item'>
              <div className='login'>{userInCircle}</div>
              </div>
              <div className='mega_menu'>
                <div className="mega_menu_column">
              <div className="mega_menu_item" >Log in</div>
              <div className="mega_menu_item" onClick={()=> navigate("/signup")}>Sign Up</div>
                </div>
              </div>
            </div> */}


            <ClickDropDown  title={<div className='login'>{userInCircle}</div>}>
                <div className="c-dropdown-item">Login</div>
                <div className="c-dropdown-item" onClick={()=> navigate("/signup")}>Sign Up</div>
            </ClickDropDown>
          
       
          
          

          <button className='btn_member'  onClick={()=>window.location.href = "/TeacherRegister"}>Become A Teacher s</button>
        </div>
      </div>
    </div>
  );
};

export default Header;
