import { useNavigate } from "react-router-dom";
import "./style.css";

const PowerSection = () => {
  const navigate = useNavigate()
  const powerItems = [
    {
      icon: CupIcon,
      title: " #1 IB Math Resource",
      info: "Groot in IB is ranked the #1 IB Math Resources by IB Students & Teachers.",
    },
    {
      icon: graidIcon,
      title: "34% Grade Increase",
      info: "Groot in IB students scored 34% greater than the IB Global Average in their exams (2021).",
    },
    {
      icon: manIcon,
      title: "80% of IB Students",
      info: "More and more IB students are usingGROOTINIB to prepare for their IB Math Exams.",
    },
  ];

  return (
    <div className='power_section_container'>
      <div className='power_section_content'>
        <h2 className='title' data-aos='zoom-in'>
          Over 80% of IB students globally are experiencing the power of Groot
          in IB
        </h2>
        <div className='power_items_container container'>
          {powerItems?.map((item, index) => {
            return (
              <div
                key={index}
                data-aos='zoom-in'
                // data-aos-delay={200 * index}
                className='power_item'
                style={{
                  animationDelay:` ${50 * index}ms`
                }}
              >
                <div className='wave_1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='450'
                    height='300'
                    viewBox='0 0 1920 1080'
                    style={{zIndex:-1}}
                  >
                    <path
                      fill='hsla(138, 89.60%, 30.20%, 0.77)'
                      d='M724 4c2.333 108.213-54.232 210.731-130.154 286.343-75.922 75.611-211.058 99.029-325.376 167.326C154.152 525.967-13.913 690.72-92.063 700.13c-78.15 9.41-24.934-105.671-108.367-185.998-83.432-80.327-318.856-193.12-392.228-295.962-73.371-102.842-73.27-203.876-48-321.092C-615.387-220.14-539.13-409.731-441.03-485.131c98.1-75.4 266.282-80.687 388.968-70.194 122.687 10.493 241.831 100.42 347.15 133.152 105.318 32.732 213.274-7.788 284.76 63.24C651.331-287.903 721.666-104.213 724 4c2.333 108.213-54.232 210.731-130.154 286.343'
                    ></path>
                  </svg>
                </div>
                <div style={{
                  zIndex:4
                }}>
                  <div className='power_item_icon'>{item.icon}</div>
                  <div className='power_item_title fs-3 fw-semibold my-2'>
                    {item.title}
                  </div>
                  <div className='power_item_info'>{item.info}</div>

                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className='main_button container'>
        <button data-aos='fade-right'>Students - Get started now</button>
        <button data-aos='fade-left'  onClick={()=>navigate("/TeacherRegister")}>Teachers - Get started now</button>
      </div>
    </div>
  );
};

export default PowerSection;

const CupIcon = (
  <svg
    className=''
    style={{
      backgroundColor: "#EDECFF",
    }}
    fill='#EDECFF'
    focusable='false'
    aria-hidden='true'
    viewBox='0 0 256 256'
    xmlns='http://www.w3.org/2000/svg'
    stroke='#3B732D'
    strokeWidth={"5px"}
  >
    <path
      className='g'
      d='m167.52,140.4c-7.77.15-8.61-11.99-.77-12.79,37.54-4.56,42.7-35.97,39.27-52.98,0,0-38.48,0-38.48,0-8.32.14-8.34-12.98,0-12.84,0,0,38.48,0,38.48,0,28.74,6.76,15.56,73.74-38.49,78.62Z'
    />
    <path
      className='g'
      d='m88.48,140.4c7.77.15,8.61-11.99.77-12.79-37.54-4.56-42.7-35.97-39.27-52.98,0,0,38.48,0,38.48,0,8.32.14,8.35-12.98,0-12.84,0,0-38.48,0-38.48,0-28.74,6.76-15.56,73.74,38.49,78.62Z'
    />
    <rect className='f' x={120.02} y={112.16} width={15.93} height={104.33} />
    <path
      className='f'
      d='m140.72,216.49c-37.7,1.08-22.56,1.54-25.47-34.38,37.7-1.08,22.56-1.54,25.47,34.38Z'
    />
    <path
      className='g'
      d='m133.82,42.48s-62.18,0-62.18,0c-2.8,44.25-1.23,124.11,62.18,117.23,54.92-4.69,51.75-76.77,50.51-117.22,0,0-50.52,0-50.52,0h0Z'
    />
    <path
      className='f'
      d='m130.23,64.01c.98,1.32,7.91,18.2,9.97,17.78,1.97,1.55,24.76.15,19.5,6.87-.96,1.33-14.86,13.15-13.83,14.98,6.52,32.84,1.91,18.21-19.04,12.15-22.24,12.27-21.55,12.92-16.72-12.15,1.11-1.77-13.02-13.7-13.83-14.98-5.24-6.68,17.48-5.37,19.5-6.87,4.71-.26,8.19-24.69,14.46-17.77Z'
    />
    <path
      className='g'
      d='m175.8,218.43c-2.81-3.39-107.18,8.34-95.63-7.67-1.88-26.92,67.54-10.65,80.78-14.85,12.22-.16,16.26,12.26,14.84,22.52Z'
    />
  </svg>
);

const graidIcon = (
  <svg
    className=''
    style={{
      backgroundColor: "#EDECFF",
    }}
    fill='#EDECFF'
    focusable='false'
    aria-hidden='true'
    viewBox='0 0 256 256'
    xmlns='http://www.w3.org/2000/svg'
    stroke='#3B732D'
    strokeWidth={"5px"}
  >
    <line className='h' x1={128} y1={125.89} x2={128} y2={49.13} />
    <path
      className='i'
      d='m116.09,110.63c14.57,18.43,9.27,18.34,23.81,0h-23.81Z'
    />
    <path
      className='i'
      d='m139.91,64.39c-14.57-18.43-9.27-18.34-23.81,0h23.81Z'
    />
    <line className='k' x1={79.48} y1={133.52} x2={85.48} y2={133.52} />
    <line className='f' x1={97.63} y1={133.52} x2={164.45} y2={133.52} />
    <line className='k' x1={170.52} y1={133.52} x2={176.52} y2={133.52} />
    <line className='k' x1={87.57} y1={37.44} x2={93.57} y2={37.44} />
    <line className='g' x1={104.56} y1={37.44} x2={165.02} y2={37.44} />
    <line className='k' x1={170.52} y1={37.44} x2={176.52} y2={37.44} />
    <rect className='j' x={50.52} y={133.52} width={37.04} height={85.03} />
    <rect className='h' x={168.43} y={37.44} width={37.04} height={181.11} />
    <line className='h' x1={50.52} y1={218.56} x2={205.48} y2={218.56} />
  </svg>
);

const manIcon = (
  <svg
    className=''
    style={{
      backgroundColor: "#EDECFF",
    }}
    fill='#EDECFF'
    focusable='false'
    aria-hidden='true'
    viewBox='0 0 256 256'
    xmlns='http://www.w3.org/2000/svg'
    stroke='#3B732D'
    strokeWidth={"5px"}
  >
    <path
      className='h'
      d='m173.79,71.47c3.17-40.93-32.16-38.25-61.85-37.29-19.95-.18-32.25,18.59-29.72,37.29-14.39,10.41-6.18,35.5,11.98,34.84,16.37-5.48,38.65-3.58,56.12-3.76,19.84,15.09,43.33-16.2,23.48-31.08Z'
    />
    <path
      className='f'
      d='m74.29,160.47s53.71-11.85,53.71-11.85c19.47,7.72,79.74,6.29,79.91,35.85,2.41,17.65-4.39,37.5-26.19,37.36,0,0-107.43,0-107.43,0-36.19-.64-36.07-58.28,0-61.35Z'
    />
    <path
      className='g'
      d='m127.98,132.53c-23.87-.89-15.32-.44-16.79,19.86,0,0,16.79,16.75,16.79,16.75l16.82-16.75c-1.47-20.36,7.08-20.73-16.82-19.86Z'
    />
    <path
      className='i'
      d='m91.97,95.93c17.61-.26,17.61,27.4,0,27.14-17.61.26-17.61-27.4,0-27.14Z'
    />
    <path
      className='i'
      d='m163.51,95.93c17.61-.26,17.61,27.4,0,27.14-17.61.26-17.61-27.4,0-27.14Z'
    />
    <path
      className='g'
      d='m93.65,75.17c22.05,7.4,45.5,12.52,69.86,6.07,2.12,26.15.63,66.03-34.91,65.52-38.52.59-36.13-44.74-34.95-71.59Z'
    />
    <line className='j' x1={161.46} y1={197.06} x2={187.22} y2={197.06} />
  </svg>
);
