import {useState} from "react";
import "./style.css";

export default function IBMathBanners({
  title,
  paragraph,
  navigations,
  button,
  children,
}) {
  const [openDropDown, setOpenDropDown] = useState(false);
  return (
    <div className='ibMath_banners'>
      <div
        className=''
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          position:"absolute"
        }}
      >
        <div className='wave_1'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='1000'
            height='800'
            viewBox='0 0 1920 1080'
          >
            <path
              fill='#9dd39d'
              d='M724 4c2.333 108.213-54.232 210.731-130.154 286.343-75.922 75.611-211.058 99.029-325.376 167.326C154.152 525.967-13.913 690.72-92.063 700.13c-78.15 9.41-24.934-105.671-108.367-185.998-83.432-80.327-318.856-193.12-392.228-295.962-73.371-102.842-73.27-203.876-48-321.092C-615.387-220.14-539.13-409.731-441.03-485.131c98.1-75.4 266.282-80.687 388.968-70.194 122.687 10.493 241.831 100.42 347.15 133.152 105.318 32.732 213.274-7.788 284.76 63.24C651.331-287.903 721.666-104.213 724 4c2.333 108.213-54.232 210.731-130.154 286.343'
            ></path>
          </svg>
        </div>
      </div>

      <div className='IB_banner_content'>
        {navigations && (
          <div className='ibMath_banners_link'>
            <a className='home-icon'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='#FFF'
              >
                <path d='M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z' />
                <path d='m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z' />
              </svg>
              /
            </a>

            {navigations?.map((nav, index) => {
              return (
                <>
                  <a className='active_link'>
                    {nav.name} {index != navigations.length - 1 ? " / " : ""}{" "}
                  </a>
                </>
              );
            })}
            {/* <a className='current_page'>
            <span>IB Math AA SL</span>
            </a> */}
          </div>
        )}
        <div className='ibMath_banners_box'>
          <h1>{title}</h1>
          <p>{paragraph}</p>
          <div className='ib_banner_button mt-4'>{button && button}</div>
        </div>
      </div>
    </div>
  );
}
