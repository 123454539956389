import React from "react";
import "./style.css";

function MoreIBMathResources() {



 const data = [
  {
    title:'Analysis & Approaches SL',
    icon:analisisIcon,
  },
  {
    title:'Analysis & Approaches HL',
    icon:analisisIcon,
  },
  {
    title:'Applications & Interpretation SL',
    icon:bargal,
  },
  {
    title:'Applications & Interpretation HL',
    icon:bargal,
  },
 ]



  return (
    <div className='more_m_r_container'>
      <h5 className='title'>What makesGROOTINIB so effective?</h5>
      <div className='container mm_content'>
        
      {
        data?.map((item , index)=>{
          return(
            <div key={index} className='mm_item'>
              <div className='mm_icon'>{item.icon}</div>
              <div className='mm_title fs-4 mt-3 fw-semibold my-2'>
                {
                  item.title
                }
              </div>
              <div className="mm_overlay"></div>
            </div>

          )
        })
      }

       
      </div>
    </div>
  );
}

export default MoreIBMathResources;

const analisisIcon = (
  <svg
    className=''
    style={{
      backgroundColor: "#EDECFF",
    }}
    fill='#EDECFF'
    focusable='false'
    aria-hidden='true'
    viewBox='0 0 256 256'
    xmlns='http://www.w3.org/2000/svg'
    stroke='#3B732D'
    strokeWidth={"5px"}
  >
    <path className='i' d='m38.71,202.78c11.79.29,175.93-1.14,189.56,0' />
    <path className='i' d='m38.71,54.5c-.71,26.29.29,53.04,0,71.2' />
    <path
      className='h'
      d='m213.8,215.17c19.35-15.48,19.26-9.34,0-24.79v24.79Z'
    />
    <path
      className='h'
      d='m51.1,68.68c-15.48-19.35-9.34-19.26-24.79,0h24.79Z'
    />
    <path className='i' d='m197.19,106.17c8.57-9.61,10.7-17.96,10.7-17.96' />
    <path className='g' d='m38.71,202.78s0-81.98,0-81.98' />
    <path
      className='g'
      d='m38.71,120.81s-.02.04,0,0c.7-.81,6.75-8.29,18.2-15.1,1.7-1.2,3.39-2.23,5.11-3.1h.01c2.29-1.2,4.6-2.11,6.92-2.76,0,0,0-.01.01,0,5.58-1.62,11.2-1.85,16.88-1.13,3.13.39,6.28,1.07,9.43,1.97,5.01,1.42,10.02,3.38,15.04,5.59h.01c12.58,5.5,25.15,12.51,37.39,16.27.39.13.79.24,1.18.36,6.16,1.78,12.24,2.71,18.19,2.17,2.78-.24,5.52-.81,8.24-1.76,6.97-2.43,13.74-7.36,20.25-15.82.42,11.5.8,86.22,1.06,95.28H38.75l-.04-81.96Z'
    />
    <path
      className='f'
      d='m71.02,98.61v104.16h-12.49c2.54-5.15-2.05-43.5-1.03-72.24.47-13.11,2.12-24.22,6.14-29.25h.01c2.29-1.2,4.6-2.11,6.92-2.76,0,0,0-.01.01,0,.14.02.29.05.44.09Z'
    />
    <path
      className='f'
      d='m96.89,99.36v103.41h-12.49s.07-.05.1-.08c2.63-2.37-.47-31.61-1.5-58.98-.78-20.56-.39-40.07,4.46-46.32,3.13.39,6.28,1.07,9.43,1.97Z'
    />
    <path
      className='f'
      d='m124.39,111.65v91.12h-12.49v-97.72c4.33,2.3,8.48,4.53,12.49,6.6Z'
    />
    <path
      className='f'
      d='m150.26,122.44v80.33h-12.49v-84.71c4.28,1.83,8.43,3.33,12.49,4.37Z'
    />
    <path
      className='f'
      d='m176.94,121.2v81.57h-12.49c3.63-9.62-10.5-88.07,12.49-81.57Z'
    />
    <path
      className='h'
      d='m181.97,63.15c-2.3,3.58-8.7,1.55-12.66,2.06-4.3,8.05-.38,44.48,10.34,24.32,5.47-1.74,2.88,11.59-2.92,12.62-21.73,7.36-15.71-24.51-15.47-36.88-8.62-.8-8.53.35-8,8.63-.71,8.72,2.72,18.72-2.09,26.54-3.51,5.61-11.57.99-10.13-4.51,3.94-10.69,13.87-40.07-7.48-27.92-3.65-3.95,8.02-13.51,13.38-12.78,8.56,2.67,39.59-7.05,35.03,7.93Z'
    />
    <line className='i' x1={221.74} y1={169.28} x2={213.9} y2={177.12} />
    <line className='i' x1={213.9} y1={169.28} x2={221.74} y2={177.12} />
    <line className='i' x1={68.7} y1={56.82} x2={60.86} y2={64.66} />
    <line className='i' x1={60.86} y1={56.82} x2={64.78} y2={60.74} />
    <line className='h' x1={38.71} y1={197.43} x2={38.71} y2={215.17} />
    <line className='h' x1={45.87} y1={202.77} x2={26.83} y2={202.77} />
  </svg>
);



const bargal = (
  <svg
  className=''
    style={{
      backgroundColor: "#EDECFF",
    }}
    fill='#EDECFF'
    focusable='false'
    aria-hidden='true'
    viewBox='0 0 256 256'
    xmlns='http://www.w3.org/2000/svg'
    stroke='#3B732D'
    strokeWidth={"5px"}
  >
    <path
      className="f"
      d="m134.87,138.21l-70.42,70.42c96.74-1.17,154.56,1.52,154.56,1.52,0,0-2.01-62.53-.77-155.3-10.16,10.16-74.92,74.92-83.37,83.37Zm61.96,27.15v21.86h-78.96l78.96-78.96v57.09Z"
    />
    <line className="f" x1={194.51} y1={198.82} x2={194.51} y2={208.62} />
    <line className="f" x1={169.41} y1={198.82} x2={169.41} y2={208.62} />
    <line className="f" x1={117.87} y1={198.82} x2={117.87} y2={208.62} />
    <line className="f" x1={143.87} y1={198.82} x2={143.87} y2={208.62} />
    <line className="f" x1={218.24} y1={186.06} x2={208.44} y2={186.06} />
    <line className="f" x1={218.24} y1={160.96} x2={208.44} y2={160.96} />
    <line className="f" x1={218.24} y1={109.42} x2={208.44} y2={109.42} />
    <line className="f" x1={218.24} y1={135.41} x2={208.44} y2={135.41} />
    <path
      className="g"
      d="m75.12,69.72c-4,2.58-9.46,1.42-11.65-2.92-1.92-6.82-18.18-24.93-6.64-30.33,4-2.58,9.46-1.42,11.65,2.92,1.92,6.82,18.18,24.93,6.64,30.33Z"
    />
    <path
      className="g"
      d="m83.41,53.76c30.52,36.44,83.21,78.59,101.42,122.52l-107.48-102.49"
    />
    <path
      className="g"
      d="m57.55,67c11.61,45.5,15.14,113.58,40.12,153.91l-20.32-147.12"
    />
    <path
      className="h"
      d="m96.47,60.38c14.07,26.52-27.9,48.01-41.19,21.08-14.07-26.52,27.9-48.01,41.19-21.08Z"
    />
    <path
      className="g"
      d="m87.31,65.07c7.81,14.72-15.49,26.66-22.87,11.71-7.81-14.72,15.49-26.65,22.87-11.71Z"
    />
  </svg>
)
