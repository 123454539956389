import React, {useContext} from "react";
import "./style.css";

import {AssessmentContext} from "../../../pages/CreateAssessment/CreateAssessment"; // Import your context




const AssessmentType = () => {
  const {assessmentData, updateAssessment} = useContext(AssessmentContext);

  const onSelectType = (type) => {
    updateAssessment({
      ...assessmentData,
      assessmentType:type,
    });

    console.log(type)
  };

  
  const types = [
    {
      id:1,
      icon: icons.exam,
      title: "Select from Practice Exams",
    },
    {
      id:2,
      icon: icons.build,
      title: "Build a custom assessment",
    },
  ];

  return (
    <div className='assessment_type_container'>
      {types.map((item, index) => {
        return (
          <div
            onClick={() => onSelectType(item)}
            className={`assessment_type_card  ${item.id == assessmentData?.assessmentType?.id ? "active":""}`}
            key={index}
          >
            <>{item.icon}</>
            <h3>{item?.title}</h3>
          </div>
        );
      })}
    </div>
  );
};

export default AssessmentType;

const icons = {
  build: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2m4.9 13.49l-1.4 1.4c-.2.2-.51.2-.71 0l-3.41-3.41c-1.22.43-2.64.17-3.62-.81a3.47 3.47 0 0 1-.59-4.1l2.35 2.35l1.41-1.41l-2.35-2.34c1.32-.71 2.99-.52 4.1.59c.98.98 1.24 2.4.81 3.62l3.41 3.41c.19.19.19.51 0 .7'
      ></path>
    </svg>
  ),

  exam: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
    >
      <g
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
      >
        <path
          strokeDasharray={62}
          strokeDashoffset={62}
          d='M20 6V5C20 4.45 19.55 4 19 4H5C4.45 4 4 4.45 4 5V19C4 19.55 4.45 20 5 20H19C19.55 20 20 19.55 20 19z'
        >
          <animate
            fill='freeze'
            attributeName='stroke-dashoffset'
            dur='0.6s'
            values='62;124'
          ></animate>
        </path>
        <g strokeDasharray={10} strokeDashoffset={10}>
          <path d='M8 8h8'>
            <animate
              fill='freeze'
              attributeName='stroke-dashoffset'
              begin='0.7s'
              dur='0.2s'
              values='10;0'
            ></animate>
          </path>
          <path d='M8 12h8'>
            <animate
              fill='freeze'
              attributeName='stroke-dashoffset'
              begin='0.9s'
              dur='0.2s'
              values='10;0'
            ></animate>
          </path>
        </g>
        <path strokeDasharray={7} strokeDashoffset={7} d='M8 16h5'>
          <animate
            fill='freeze'
            attributeName='stroke-dashoffset'
            begin='1.1s'
            dur='0.2s'
            values='7;0'
          ></animate>
        </path>
      </g>
    </svg>
  ),
};
