import React, { useState } from "react";
import "./style.css";
import FlatCard from './../../FlatCard/FlatCard';
import { groupsData } from "./data";

const GroupSection = () => {

  return <div className='group_section_container container'>
    <h2 className='title' data-aos='zoom-in'>
      Our Groups
    </h2>

    <div className="groups_content">

      {
        groupsData?.map((item, index) => {
          return (
            <FlatCard key={index} data={item} index={index+1}/>
          )
        })
      }

    </div>



  </div>;
};

export default GroupSection;
