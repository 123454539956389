


import React, { useState , useRef , useEffect} from 'react';
import './style.css';

function ClickDropDown({title , children}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    console.log("click")
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
      <div className="c-dropdown" ref={dropdownRef}>
        <button onClick={toggleDropdown} className="c-dropdown-button">
          {title}
        </button>
        {isOpen && (
          <div className="c-dropdown-menu">
            {children}
          </div>
        )}
    </div>
  );
}

export default ClickDropDown;
