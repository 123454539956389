import React from "react";
import "./style.css";
import FormGroup from "./../../components/FormGroup/FormGroup";

const SignupPage = () => {
  return (
    <div className='signup_page container'>
      <h2 className='title' data-aos='zoom-in'>
          Create an account
        </h2>

        <p>Already have an account? <span className="text-decoration-underline pointer fw-bolder">Log in</span></p>

      <div className='signup_content'>
        <div className='wave_1'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='900'
            height='500'
            viewBox='0 0 1920 1080'
          >
            <path
              fill='hsla(116, 74.70%, 82.90%, 0.84)'
              d='M724 4c2.333 108.213-54.232 210.731-130.154 286.343-75.922 75.611-211.058 99.029-325.376 167.326C154.152 525.967-13.913 690.72-92.063 700.13c-78.15 9.41-24.934-105.671-108.367-185.998-83.432-80.327-318.856-193.12-392.228-295.962-73.371-102.842-73.27-203.876-48-321.092C-615.387-220.14-539.13-409.731-441.03-485.131c98.1-75.4 266.282-80.687 388.968-70.194 122.687 10.493 241.831 100.42 347.15 133.152 105.318 32.732 213.274-7.788 284.76 63.24C651.331-287.903 721.666-104.213 724 4c2.333 108.213-54.232 210.731-130.154 286.343'
            ></path>
          </svg>
        </div>
        

        <FormGroup
          gap={10}
          rowCount={2}
          Buttons={
            <>
              <button
                className='green_btn w-100'
                onClick={() => (window.location.href = "/TeacherRegister")}
              >
                Become A Teacher s
              </button>
            </>
          }
        >
          <FormGroup.Input
            required={true}
            label={"First Name"}
            placeholder=''
          />
          <FormGroup.Input required={true} label={"Last Name"} placeholder='' />
          <FormGroup.Input
            required={true}
            placeholder=''
            label={"Email"}
            col={4}
          />
          <FormGroup.Input
            required={true}
            placeholder=''
            label={"Confirm Email"}
            col={4}
          />
        </FormGroup>
      </div>
    </div>
  );
};

export default SignupPage;
