export const faqData = [
    {
      question: "What topics are covered in the IB Math AA SL course?",
      answer: "The IB Mathematics Analysis and Approaches SL course covers several key topics, including:\n" +
        "- **Algebra**: Polynomial functions, algebraic manipulation, and equations.\n" +
        "- **Functions**: Types of functions, transformations, and their properties.\n" +
        "- **Calculus**: Limits, derivatives, and integrals.\n" +
        "- **Statistics and Probability**: Descriptive statistics, probability distributions, and inferential statistics.\n" +
        "- **Geometry and Trigonometry**: Geometric shapes, trigonometric functions, and their applications.\n" +
        "- **Number and Algebra**: Sequences, series, and logarithms."
    },
    {
      question: "How is the IB Math AA SL course assessed?",
      answer: "The IB Mathematics Analysis and Approaches SL course is assessed through:\n" +
        "- **Paper 1 (40%)**: A written exam consisting of short-response and extended-response questions. No calculators are allowed.\n" +
        "- **Paper 2 (40%)**: A written exam with similar question types, but a graphing calculator is allowed.\n" +
        "- **Mathematical Exploration (20%)**: An internally assessed individual project where students investigate a mathematical topic of their choice, presenting their findings in a written report."
    },
    {
      question: "What are the main differences between IB Math AA SL and IB Math AI SL?",
      answer: "The main differences between IB Mathematics Analysis and Approaches SL (AA SL) and IB Mathematics Applications and Interpretation SL (AI SL) are:\n" +
        "- **AA SL**: Focuses on developing mathematical understanding and theoretical problem-solving skills. It’s more abstract and suited for students pursuing higher-level mathematics in future studies.\n" +
        "- **AI SL**: Emphasizes practical applications of mathematics and is more focused on real-world contexts. It’s ideal for students who need to use mathematics in practical situations or other disciplines."
    },
    {
      question: "How can students prepare effectively for the IB Math AA SL exams?",
      answer: "Effective preparation strategies for the IB Math AA SL exams include:\n" +
        "- **Regular Practice**: Solve a variety of problems and past exam papers to build familiarity with question types.\n" +
        "- **Conceptual Understanding**: Focus on understanding core concepts rather than rote memorization.\n" +
        "- **Use of Resources**: Utilize textbooks, online tutorials, and study groups to reinforce learning.\n" +
        "- **Time Management**: Practice under timed conditions to improve exam-taking skills and manage time effectively during the actual exams.\n" +
        "- **Seek Help**: Don’t hesitate to ask for help from teachers or tutors if you struggle with specific topics."
    },
    {
      question: "What resources are recommended for IB Math AA SL students?",
      answer: "Recommended resources for IB Math AA SL students include:\n" +
        "- **Textbooks**: Official IB textbooks and study guides.\n" +
        "- **Online Resources**: Websites offering practice problems and tutorials, such as Khan Academy or IB-specific platforms.\n" +
        "- **Study Groups**: Joining or forming study groups with classmates for collaborative learning.\n" +
        "- **Tutoring**: Seeking help from tutors or teachers for difficult concepts.\n" +
        "- **Past Papers**: Practicing with past exam papers and mark schemes."
    }
  ];
  
  export default faqData;
  