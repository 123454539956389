import React, {useContext, useEffect, useState} from "react";
import "./style.css";
import Stepper from "../../Stepper/Stepper";
import FormGroup from "../../FormGroup/FormGroup";
import CheckBoxContent from "./../../CheckBoxContent/CheckBoxContent";
import CheckBox from "./../../CheckBox/CheckBox";
import {Steps} from "antd";
import { AssessmentContext } from "../../../pages/CreateAssessment/CreateAssessment"; // Import your context





const AssessmentSetup = ({showSteps}) => {

  const  {assessmentData ,  updateAssessment} = useContext(AssessmentContext)



  const options = [
    {
      count: 1,
      stepName: "Setup",
      active: true,
    },
    {
      count: 2,
      stepName: "Select",
    },
    {
      count: 3,
      stepName: "Review",
    },
    {
      count: 4,
      stepName: "Summary",
    },
  ];

  const steps =
  assessmentData.assessmentType.title == "Build a custom assessment"
    ? [
        {
          title: "Setup",
          description: "",
        },
        {
          title: "Select subtopics",
          description: "",
        },
        {
          title: "Select questions",
          description: "",
        },
        {
          title: "Review",
          description: "",
        },
        {
          title: "Summery",
          description: "",
        },
      ]
    : [
        {
          title: "Setup",
          description: "",
        },
        {
          title: "Select",
          description: "",
        },
        {
          title: "Review",
          description: "",
        },
        {
          title: "Summary",
          description: "",
        },
      ];



  const [current, setCurrent] = useState(0);
  const [vidSolution, setVidSolution] = useState("never");





  const onChangeTaskName = (value) =>{
    updateAssessment({
      ...assessmentData,
      taskName:value

    })
  }


  const onChangeAvailableFrom = (value ) =>{
    updateAssessment({
      ...assessmentData,
      availableFrom:value
    })
  }
  const onChangeAvailableTo = (value) =>{
    updateAssessment({
      ...assessmentData,
      availableTo:value
    })
  }

  const onChangeVidSolution = (value) =>{
    updateAssessment({
      ...assessmentData,
      vidSolution:value
    })
  }
  
  
  
  const onChangeOptions = (e , item) => {
    if(e.target.checked){
      console.log(assessmentData)
      updateAssessment({
        ...assessmentData,
        options:[...assessmentData.options , item]
      })
    }else{
      const newData = assessmentData.options.filter(option => item.title !== option.title);
      updateAssessment({
        ...assessmentData,
        options:newData
      })
    }
  }

  useEffect(()=>{
    console.log(assessmentData)
  },[assessmentData])







  return (
    <div className='assessment_data  row'>
      {showSteps && (
        <div className='col-2'>
          {/* <Stepper options={options} antd={true} /> */}
          <Steps
            className='custom-steps'
            current={0}
            // onChange={(value) => setCurrent(value)}
            direction='vertical'
            items={steps}
          />
        </div>
      )}
      <div className={showSteps ? `col-10` : "col"}>
        <FormGroup gap={16} rowCount={2} Buttons={""}>
          <FormGroup.Input
            value={assessmentData.taskName}
            col={3}
            required={true}
            label={"Task name"}
            placeholder=''
            onChange={(e)=>onChangeTaskName(e.target.value)}
            
          />
          <FormGroup.Input
            value={assessmentData.availableFrom}
            required={true}
            label={"Available from"}
            type='datetime-local'
            placeholder=''
            onChange={(e)=> onChangeAvailableFrom(e.target.value)}
          />

          <FormGroup.Input
            required={true}
            label={"Available to"}
            type='datetime-local'
            placeholder=''
            value={assessmentData.availableTo}
            onChange={(e)=>onChangeAvailableTo(e.target.value)}
          />
        </FormGroup>

        <div className='mt-3'>
          <label htmlFor=''>Show video solutions</label>
          <div className='d-flex align-items-cetner gap-1'>
            {[
              {
                label: "Never",
                value: "never",
              },
              {
                label: "After",
                value: "after",
              },
              {
                label: "Always",
                value: "always",
              },
            ].map((item, index) => {
              return (
                <CheckBoxContent
                  checked={assessmentData.vidSolution == item.value}
                  onChange={() => {

                      onChangeVidSolution(item.value)


                    setVidSolution(item.value)
                  }}
                  id={item.value}
                  title={item.label}
                />
              );
            })}
          </div>
        </div>

        <div className='switches d-flex flex-column gap-3 mt-3'>
          {[
            {
              title: "Require student responses (recommended)",
              info: "Students will be required to submit answers to each question.",
            },
            {
              title: "Auto mark assessment",
              info: "Use Newton AI to recommend marks and feedback.No feedback will be sent to students without teacher oversight.",
            },
            {
              title: "Only provide feedback",
              info: "Students will only receive feedback and no marks for each answer.",
            },
          ].map((item, index) => {
            return (
              <div className='d-flex align-items-center gap-2'>
                <div>
                  <CheckBox checked={assessmentData.options.find(option=> option.title == item.title)} onChange={(e)=> onChangeOptions(e , item)}/>
                </div>
                <div className=''>
                  <div className='stt text-dark fw-bolder'>{item.title}</div>
                  <div className='stt '>{item.info}</div>
                </div>
              </div>
            );
          })}

          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentSetup;
