import {Steps} from "antd";
import React, {useContext, useState} from "react";
import "./style.css";
import AssessmentSetup from "./../AssessmentSetup/AssessmentSetup";
import ReactSelect from "react-select";
import CheckBox from './../../CheckBox/CheckBox';
import {AssessmentContext} from "../../../pages/CreateAssessment/CreateAssessment"; // Import your context

const AssessMentSummery = () => {
  const {assessmentData, updateAssessment} = useContext(AssessmentContext);

  const [current, setCurrent] = useState(3);
  const steps =
  assessmentData.assessmentType.title == "Build a custom assessment"
    ? [
        {
          title: "Setup",
          description: "",
        },
        {
          title: "Select subtopics",
          description: "",
        },
        {
          title: "Select questions",
          description: "",
        },
        {
          title: "Review",
          description: "",
        },
        {
          title: "Summery",
          description: "",
        },
      ]
    : [
        {
          title: "Setup",
          description: "",
        },
        {
          title: "Select",
          description: "",
        },
        {
          title: "Review",
          description: "",
        },
        {
          title: "Summary",
          description: "",
        },
      ];
  const duratoins = [
    "15 minutes",
    "30 minutes",
    "45 minutes",
    "60 minutes",
    "75 minutes",
    "90 minutes",
    "105 minutes",
    "120 minutes",
    "135 minutes",
    "150 minutes",
    "165 minutes",
    "180 minutes",
  ];
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#ececec",
      zIndex: "100000",
      width: "200px",
      borderRadius: "999px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#ffffff",
      paddingInline: "20px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#eaeaea"
        : state.isFocused
        ? "#f3f3f3"
        : "#ffffff",
      color: "#000000",
      marginBlock: "5px",
      borderRadius: "10px",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "1px solid green",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#000000",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#ff0000",
    }),
  };

  return (
    <div className='row'>
      <div className='col-2'>
        <Steps
          className='custom-steps'
          current={assessmentData.assessmentType.title == "Build a custom assessment" ? 4 : 3}
          // onChange={(value) => setCurrent(value)}
          direction='vertical'
          items={steps}
        />
      </div>
      <div className='col-10 d-flex gap-4'>
        <div className='col border-end border-2 pe-3' style={{zIndex: 100}}>
          <AssessmentSetup showSteps={false} />
          

         
          <ReactSelect
            options={duratoins.map((item) => ({
              value: item,
              label: item,
            }))}
            styles={customStyles}
          />
           <div className='d-flex align-items-center gap-2 mt-3'>
                <div>
                  <CheckBox id={123} />
                </div>

                <div>
                  <div className='stt text-dark fw-bolder'>
                    Show countdown timer
                  </div>
                  <div className='stt mt-1'>
                    Show a countdown timer once students start the assessment
                  </div>
                </div>
              </div>
        </div>

        <div className='col'>
          <QuestionDistribution />
        </div>
      </div>
    </div>
  );
};

export default AssessMentSummery;

const QuestionDistribution = () => {
  return (
    <div className='question_dist_container'>
      <div className='question_dist'>
        <div className='sttt fw-bolder text-dark'>Question Distribution</div>
        <div className='d-flex align-items-center gap-3'>
          <div className='d-flex align-items-center gap-2'>
            <span className='bg-success p-1 stt  px-2 text-light rounded'>
              7
            </span>{" "}
            questions
          </div>
          <div className='d-flex align-items-center gap-2'>
            <span className='bg-success p-1 px-2 stt  text-light rounded'>
              50
            </span>{" "}
            marks
          </div>
        </div>
      </div>

      <div className='question_dist_table_container'>
        <table>
          <thead>
            <tr>
              <th className=''></th>
              <th className=''>Easy</th>
              <th className=''>Medium</th>
              <th className=''>Hard</th>
            </tr>
          </thead>
          <tbody>
            <tr className='topic_row'>
              <td>Number & Algebra</td>
              <td className='text-success'>2/12</td>
              <td className='text-warning'>3/19</td>
              <td className='text-danger'>2/19</td>
            </tr>
            <tr>
              <td>Sequences & Series</td>
              <td className='text-success'>2/12</td>
              <td className='text-warning'>3/19</td>
              <td className='text-danger'>2/19</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td className='sttt fw-bolder text-dark'>Total</td>
              <td className='text-success'>2/12</td>
              <td className='text-warning'>3/19</td>
              <td className='text-danger'>2/19</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
