import React, { useState } from 'react';
import './ProfileCard.css'; // Assuming your CSS is saved in this file
import { useNavigate } from 'react-router-dom';

const ProfileCard = ({ item }) => {
  const navigate = useNavigate()

  return (
    <div className="strange-card" style={{ cursor: "pointer" }} onClick={() => navigate("/Profile")}>
      <div className="text">
        <img src={item?.image} alt="" className="img" />
        <h3 className="name">{item?.name}</h3>
        <p className="role">{item?.role}</p>
        <p className="bio">
          {item?.smallBio}
        </p>
      </div>
      <div className="subjscardteach">
        {
          item?.subjects?.map((s_item, index) => (
            index < item.subjects.length - 1 ? (
              <a href="#" key={index}>{s_item} {" | "}</a>
            ) : (
              <a href="#" key={index}>{s_item}</a>
            )
          ))
        }

      </div>
      {/* <div className="links">
        <a target="_blank" rel="noopener noreferrer" href="https://codepen.io/l-e-e/" className="link"><i className="fab fa-codepen"></i></a>
        <a target="_blank" rel="noopener noreferrer" href="https://github.com/Leena26" className="strange-link"><i className="fab fa-github"></i></a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCPOyUi82bRcPTdpDuSHVNbw" className="link"><i className="fab fa-youtube"></i></a>
      </div> */}
    </div>

  );
};

export default ProfileCard;
