import React from "react";
import "./style.css";
import IBMathBanners from "../../components/IBMathPages/IBMathBanners/IBMathBanners";
import IBTopicCard from "../../components/IBMathPages/IBMathTopics/IBTopicCard/IBTopicCard";
import {Route, Routes, useNavigate} from "react-router-dom";
import RevisionTypesContainer from "../../components/RevisionTypesContainer/RevisionTypesContainer";

const PracticeExams = () => {
  /**
   * 
   * /
IB MATH /
IB Math AA SL
   * 
   */
  const navigations = [
    {
      name: "IB MATH",
      bathname: "",
    },
    {
      name: "IB Math AA SL",
      bathname: "",
    },
    {
      name: "Practice Exams",
      bathname: "",
    },
  ];

  const topics = [
    {
      icon: icons.quiz,
      title: "Topic Quizzes",
      info: "Test Yourself",
      bathname:"/topicQuiz"
    },
    {
      icon: icons.ladder,
      title: "Revision Ladder",
      info: "Exams by Difficulty",
      bathname:"/topicQuiz"
    },
    {
      icon: icons.paper,
      title: "Mock Exam Papers",
      info: "Official 2024 Trail Exams",
      bathname:"/topicQuiz"
    },
    {
      icon: icons.prediction,
      title: "Prediction Exam",
      info: "November 2024 Prediction Exam",
      bathname:"/topicQuiz"
    },
  ];

  const navigate = useNavigate()
  
  return (
    <div className='practice_exams_page'>
      <IBMathBanners
        navigations={navigations}
        title={"IB Mathematics AA SL - Practice Exams"}
        paragraph={
          "The IB Mathematics Analysis & Approaches (AA) Standard Level (SL) Practice exams are the perfect resource to customize your revision plan. You can hone your skills on important concepts using the Math AA SL Popular Quizzes, or ascend the Math AA SL Revision Ladder to reach the hardest questions in the IB! Finally, full length RV-exclusive Math AA SL Mock Exam Papers will simulate the experience of taking the actual exams from start to finish."
        } 

        />

     <RevisionTypesContainer />
    </div>
  );
};

export default PracticeExams;


// /////////

const icons = {
  quiz: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 48 48'
    >
      <g fill='currentColor'>
        <path d='M20 15a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1m1 3a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2zm-1 10a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1m1 3a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2z'></path>
        <path
          fillRule='evenodd'
          d='M10 27a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1zm2 1v3h3v-3z'
          clipRule='evenodd'
        ></path>
        <path d='M17.707 15.707a1 1 0 0 0-1.414-1.414L13 17.586l-1.293-1.293a1 1 0 0 0-1.414 1.414L13 20.414z'></path>
        <path
          fillRule='evenodd'
          d='M10 6a4 4 0 0 0-4 4v28a4 4 0 0 0 4 4h20a4 4 0 0 0 4-4V10a4 4 0 0 0-4-4zm-2 4a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2v28a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2zm28 6a3 3 0 1 1 6 0v20.303l-3 4.5l-3-4.5zm3-1a1 1 0 0 0-1 1v2h2v-2a1 1 0 0 0-1-1m0 22.197l-1-1.5V20h2v15.697z'
          clipRule='evenodd'
        ></path>
      </g>
    </svg>
  ),
  ladder: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
    >
      <path
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M8 3v18m8-18v18m-8-7h8m-8-4h8M8 6h8M8 18h8'
      ></path>
    </svg>
  ),
  paper: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 32 32'
    >
      <g fill='none' stroke='currentColor'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M10 9h4m-4 7h12m-12 4h12m-12 4h4m-6 5h16a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v22a2 2 0 0 0 2 2'
        ></path>
        <circle cx={22} cy={9} r={0.5} fill='currentColor'></circle>
      </g>
    </svg>
  ),
  prediction: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 20 20'
    >
      <path
        fill='currentColor'
        d='M14.5 1c.276 0 .5.222.5.497c0 .463.073 1.219.472 1.846c.375.588 1.083 1.136 2.528 1.136c.276 0 .5.222.5.497a.5.5 0 0 1-.5.497c-.369 0-1.14.223-1.82.742c-.663.505-1.18 1.241-1.18 2.24a.5.5 0 0 1-.5.496a.5.5 0 0 1-.5-.497c0-.5-.155-1.261-.606-1.884c-.432-.596-1.157-1.097-2.394-1.097a.5.5 0 0 1-.5-.497c0-.275.224-.497.5-.497c.512 0 1.255-.01 1.873-.367C13.437 3.785 14 3.1 14 1.497c0-.275.224-.497.5-.497m.04 2.73a2.95 2.95 0 0 1-1.328 1.329c.409.254.736.576.994.93q.194.271.334.554a4.2 4.2 0 0 1 1.03-1.117q.237-.18.485-.325a3.22 3.22 0 0 1-1.514-1.37m-7.255 6.8A4.46 4.46 0 0 1 5.5 6.964c0-2.47 2.015-4.472 4.5-4.472c.9 0 1.739.263 2.442.715c.11-.077.22-.19.315-.374c.06-.12.122-.28.167-.5A5.5 5.5 0 0 0 10 1.498c-3.038 0-5.5 2.447-5.5 5.466c0 1.666.75 3.157 1.931 4.16l-1.218 4.439c-.17.62.077 1.345.74 1.64c1.637.727 4.65 1.351 7.97.009c.71-.288 1.02-1.064.814-1.75l-1.258-4.168l-.028-.073a5.5 5.5 0 0 0 1.162-1.28a1.5 1.5 0 0 1-1.013-.294a4.5 4.5 0 0 1-3.6 1.79a4.5 4.5 0 0 1-2.715-.905m5.3 1.26l1.194 3.958c.073.24-.044.466-.234.542c-3.013 1.22-5.739.647-7.184.004c-.143-.063-.245-.245-.183-.47l1.124-4.096a5.5 5.5 0 0 0 2.698.7c.934 0 1.814-.232 2.585-.64'
      ></path>
    </svg>
  ),
};
