import React, { useState } from "react";
import "./profile.css"; // Adjust path as needed
import { Modal } from "antd";
const Profile = () => {
  const [openViewer, setOpenViewer] = useState(null)
  return (
    <div>
      <div className="author">
        <div
          className="author-container"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="authorFlex">
            <div className="author-img">
              <img src={require("./Jenna-Photo-1.avif")} alt="" />
            </div>

            <div className="authorName">
              <h1>Jenna Quinn</h1>
              <h2>Head of New Subjects</h2>
            </div>
          </div>
          <button className="btn btn-primary">Register With Teacher</button>
        </div>
      </div>

      <div className="aboutContainer">
        <div className="capFlex">
          <div className="col-1-cap">
            <div className="cap">
              <div className="text">
                <h3 className="authorTitle">About</h3>
                <ul className="authorList">
                  <li>
                    <p>
                      Before joining Save My Exams in 2019, Jenna worked as a
                      science teacher for 10 years, initially in the UK, and
                      later at a British International school in South Korea.
                      She’s taught thousands of students preparing for their
                      GCSE, IGCSE, A Level & IB Diploma exams. Her favourite
                      topics to teach are respiration, moles and momentum.
                    </p>
                  </li>
                  <li>
                    <p>
                      Jenna has also held a variety of roles outside of the
                      classroom, including Subject Lead for Biology, Lead
                      Practitioner for Science and Head of KS3 Science. Her most
                      fulfilling school-based role was that of Assistant
                      Housemistress, where she was responsible for the care,
                      safety & wellbeing of over 40 boarding students over a
                      two-year period.
                    </p>
                  </li>
                  <li>
                    <p>
                      Jenna has also mentored & supported trainee teachers (PGCE
                      & TeachFirst), all of whom have gone on to achieve QTS.
                      She has frequently contributed to CPD, leading workshops,
                      learning communities and has launched a whole-school
                      initiatives embedding skills in the classroom and
                      supporting literacy development.
                    </p>
                  </li>
                  <li>
                    <p>
                      Jenna discovered Save My Exams in 2018 and since joining
                      the team has held a variety of roles, from Content Creator
                      to her current role as Head of Humanities & Social
                      Sciences.
                    </p>
                  </li>
                  <li>
                    <p>
                      In her spare time, Jenna is a prolific traveller (40
                      countries and counting!) and is always on the hunt for
                      really good coffee.
                    </p>
                  </li>
                </ul>
              </div>

              <div className="text">
                <h3 className="authorTitle">Education</h3>
                <ul className="authorList">
                  <li>
                    <p>
                      Jenna attended Cardiff University where she studied many
                      variations of biology before she settled on taking a
                      degree in Genetics (BSc.)
                    </p>
                  </li>
                  <li>
                    <p>
                      After debating whether to become a doctor, lawyer or
                      teacher, she completed her PGCE with QTS at the University
                      of Bath.
                    </p>
                  </li>
                </ul>
              </div>

              <div className="text">
                <h3 className="authorTitle">Certificate</h3>
                <div className="certificate-flex">
                  <img
                    onClick={() =>
                      window.open(require("./certificate-1-1-0850a4305aca9c343232371019131f33.png"), "_blanck")
                    }
                    src={require("./certificate-1-1-0850a4305aca9c343232371019131f33.png")}
                    alt=""
                  />
                  <img
                    onClick={() =>
                      window.open(require("./certificate-1-2-ee1d9987bf333055bbb90086cf8c0542.png"), "_blanck")

                     
                    }
                    src={require("./certificate-1-2-ee1d9987bf333055bbb90086cf8c0542.png")}
                    alt=""
                  />
                </div>
              </div>

              <div className="text">
                <h3 className="authorTitle">Expertise</h3>
                <ul className="authorList">
                  <li>
                    <p>
                      Jenna has taught AQA & OCR GCSE Biology, Chemistry &
                      Physics, CIE IGCSE Biology, AQA & Edexcel A Level Biology
                      (A & B) & IB Diploma Biology (standard level & higher
                      level)
                    </p>
                  </li>
                  <li>
                    <p>
                      She has also written numerous mock exams for students in
                      KS3 and in Years 10, 11, 12 & 13
                    </p>
                  </li>
                  <li>
                    <p>
                      Jenna has supported over 50 students completing practical
                      coursework at A Level
                    </p>
                  </li>
                </ul>
              </div>

              <div className="text">
                <h3 className="authorTitle">Top revision quote for students</h3>
                <p className="quote">
                  "My top revision technique for all students is to verbalise
                  their revision. If you can’t confidently explain a concept
                  like photosynthesis or reversible reactions to a
                  friend/parent/carer/teacher, then you don’t know it well
                  enough. It takes less time to say something than to write it,
                  so it’s a super effective & impactful revision tool to
                  identify areas to focus on to maximise your use of time!"
                </p>
              </div>
            </div>
          </div>

          {/* <div className="col-2-cap">
            <div className="col-2-container">
              <div className="authorSocial">
                <p>social links</p>
                <ul className="social-media">
                  <li>
                    <a href="">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}

          <div className="col-3-cap">
            <div className="border-bottom"></div>
            <h3 className="article">video from teacher</h3>
            <div className="vid-div">
              <video
                controls
                muted
                src={require("./stock-footage-zoom-in-time-lapse-of-smart-person-solving-scientific-problem-writing-formulas-on-chalkboard.webm")}
              ></video>
            </div>
          </div>

          <div className="col-4-cap">
            <div className="border-bottom"></div>
            <h3 className="article">Jenna Quinn’s courses</h3>
            <div className="subjectFlex">
              <div className="col-subject">
                <div className="subjectContainer">
                  <div className="subjectImg">
                    <img
                      src={require("./dbca02091cef90abc2d460020aea0f92.jpg")}
                      alt=""
                    />
                  </div>
                  <p className="sub">Chemistry</p>
                  <div className="studyFlex">
                    <div className="rateFlex">
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <p className="rate">5</p>
                    </div>
                    <p className="student">(328)</p>
                  </div>
                  <div className="arrow">
                    <div className="arrowDiv">
                      <i className="fa-solid fa-arrow-right"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-subject">
                <div className="subjectContainer">
                  <div className="subjectImg">
                    <img
                      src={require("./dbca02091cef90abc2d460020aea0f92.jpg")}
                      alt=""
                    />
                  </div>
                  <p className="sub">Chemistry</p>
                  <div className="studyFlex">
                    <div className="rateFlex">
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <p className="rate">5</p>
                    </div>
                    <p className="student">(328)</p>
                  </div>
                  <div className="arrow">
                    <div className="arrowDiv">
                      <i className="fa-solid fa-arrow-right"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-subject">
                <div className="subjectContainer">
                  <div className="subjectImg">
                    <img
                      src={require("./dbca02091cef90abc2d460020aea0f92.jpg")}
                      alt=""
                    />
                  </div>
                  <p className="sub">Chemistry</p>
                  <div className="studyFlex">
                    <div className="rateFlex">
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <p className="rate">5</p>
                    </div>
                    <p className="student">(328)</p>
                  </div>
                  <div className="arrow">
                    <div className="arrowDiv">
                      <i className="fa-solid fa-arrow-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={openViewer} onCancel={()=>setOpenViewer(false)} closable={true}>
        <img src={openViewer} style={{width:"300px", height:"400px", objectFit:"contain"}}/>
      </Modal>
    </div>
  );
};

export default Profile;
