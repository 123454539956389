



import React from 'react'
import "./style.css"
import { starIcon } from './../../assets/svgIcons/index';
import { useNavigate } from 'react-router-dom';

const SubjectCard = ({ data, index }) => {
  const navigate = useNavigate()
  return (
    <div className="s_card">
      <div className="top-section">
        <div className="s_border"></div>
        <div className="ratings">
        </div>
        <img src={data.image_url} alt="" />
      </div>
      <div className="bottom-section">
        <span className="s_title">{data.subject_name}</span>
        <div className="levelCardDesign">
          <span onClick={() => navigate("/course")}>{data.subject_name} - SL</span>
          <span onClick={() => navigate("/course")}>{data.subject_name} - HL</span>
        </div>
        <div className="row row1">
          <div className="item">
            <span className="big-text">2626</span>
            <span className="regular-text">examp</span>
          </div>
          <div className="item">
            <span className="big-text">100</span>
            <span className="regular-text">quizzes</span>
          </div>
          <div className="item">
            <span className="big-text">38,631</span>
            <span className="regular-text">Contributers</span>
          </div>
        </div>
      </div>


    </div>
  )
}

export default SubjectCard
