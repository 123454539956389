import logo from "./logo.svg";
import "./App.css";
import DefaultLayout from "./Layout/DefaltLayout/DefaultLayout";
import AppRoutes from "./Routes/Routes";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
import "antd/dist/reset.css";
import { useLocation } from "react-router-dom";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 500,
      once: true,
    });
  }, []);
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
      });
    }, 150);
  }, [location.pathname]);
  return (
    <div className="App">
      <DefaultLayout>
        <AppRoutes />
      </DefaultLayout>
    </div>
  );
}

export default App;
