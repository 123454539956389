


import React from 'react'
import "./style.css"

const CheckBox = ({checked , id , onChange}) => {
  return (
    <label className="checkeBox__container">
  <input checked={checked}  onChange={onChange ? onChange : null} id={id} type="checkbox" />
  <div className="checkmark__"></div>
</label>
  )
}

export default CheckBox
