export const courses = [
  {
    title: "IB Mathematics",
    courses: [
      {
        id: 1,
        level: "AA SL",
        remainingAssessments: 4,
      },
      {
        id: 2,
        level: "AA HL",
        remainingAssessments: 4,
        completedAssessments: 1,
      },
      {
        id: 3,
        level: "AI SL",
        remainingAssessments: 4,
        completedAssessments: 1,
      },
      {
        id: 4,
        level: "AI HL",
        remainingAssessments: 4,
        completedAssessments: 1,
      },
    ],
  },
  {
    title: "IB Biology",
    courses: [
      {
        id: 5,
        level: "SL",
        year: "2024",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
      {
        id: 6,
        level: "HL",
        year: "2024",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
      {
        id: 7,
        level: "SL",
        year: "2025",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
      {
        id: 8,
        level: "HL",
        year: "2025",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
    ],
  },
  {
    title: "IB Chemistry",
    courses: [
      {
        id: 9,
        level: "SL",
        year: "2024",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
      {
        id: 10,
        level: "HL",
        year: "2024",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
      {
        id: 11,
        level: "SL",
        year: "2025",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
      {
        id: 12,
        level: "HL",
        year: "2025",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
    ],
  },
  {
    title: "IB physics",
    courses: [
      {
        id: 13,
        level: "SL",
        year: "2024",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
      {
        id: 14,
        level: "HL",
        year: "2024",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
      {
        id: 15,
        level: "SL",
        year: "2025",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
      {
        id: 16,
        level: "HL",
        year: "2025",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
    ],
  },
  {
    title: "IB Psychology",
    courses: [
      {
        id: 17,
        level: "SL",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
      {
        id: 18,
        level: "HL",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
    ],
  },
  {
    title: "IB Economics",
    courses: [
      {
        id: 19,
        level: "SL",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
      {
        id: 20,
        level: "HL",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
    ],
  },
  {
    title: "IB Business Management",
    courses: [
      {
        id: 21,
        level: "SL",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
      {
        id: 22,
        level: "HL",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
    ],
  },
  {
    title: "IB Environmental Systems and Societies",
    courses: [
      {
        id: 23,
        level: "SL",
        year: "2025",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
    ],
  },
  {
    title: "IB English",
    courses: [
      {
        id: 24,
        course: "English",
        type: "LangLit",
        level: "SL",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
      {
        id: 25,
        course: "English",
        type: "LangLit",
        level: "HL",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
      {
        id: 26,
        course: "English",
        type: "Lit",
        level: "SL",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
      {
        id: 27,
        course: "English",
        type: "Lit",
        level: "HL",
        remainingAssessments: 5,
        completedAssessments: 5,
      },
    ],
  },
];

export const topics = [
  {
    topic: "Number & Algebra",
    branches: [
      {
        id: 1,
        topic: "Sequences & Series",
        questions: 7,
        marks: 50,
        time_minutes: 55,
        time_per_question_minutes: 55 / 7,
        questionsData: [
          {
            question:
              "Expand (2x + 1)^4 in descending powers of x and simplify your answer.",
            marks: 4,
            difficulty: "EASY",
          },
          {
            question:
              "Expand (2x - 3)^4 in descending powers of x and simplify your answer.",
            marks: 4,
            difficulty: "EASY",
          },
          {
            question:
              "Consider the expansion of (2x^2 + ax)^6. The constant term is 960. Find the possible values of a.",
            marks: 5,
            difficulty: "MEDIUM",
          },
          {
            question:
              "In the expansion of (2x + 1)^n, the coefficient of the term in x^2 is 40n, where n ∈ Z+. Find n.",
            marks: 5,
            difficulty: "HARD",
          },
          {
            question:
              "Consider the expansion of (2x^6 + qx^2)^10, where q ≠ 0. The coefficient of the term in x^40 is twelve times the coefficient of the term in x^36. Find q.",
            marks: 5,
            difficulty: "HARD",
          },
        ],
      },
      {
        id: 26,
        topic: "Compound Interest",
        questions: 5,
        marks: 46,
        time_minutes: 55,
        time_per_question_minutes: 55 / 5,
        questionsData: [
          {
            question: "Find the value of log10 100",
            type: "EASY",
            marks: 2,
          },
          {
            question: "Find the value of log10 50 + log10 2",
            type: "EASY",
            marks: 2,
          },
          {
            question: "Find the value of log10 4 − log10 40",
            type: "EASY",
            marks: 3,
          },
          {
            question:
              "Consider a = log63 64 × log62 63 × log61 62 × ⋯ × log2 3. Given that a ∈ Z, find the value of a.",
            type: "MEDIUM",
            marks: 5,
          },
          {
            question:
              "Write the expression 4 ln 2 − ln 8 in the form of ln k, where k ∈ Z.",
            type: "MEDIUM",
            marks: 3,
          },
          {
            question: "Solve 4 ln 2 − ln 8 = − ln (2x)",
            type: "MEDIUM",
            marks: 6,
          },
          {
            question: "Find the values of x when 2^(7x+2) = (1/9)^(2x+4)",
            type: "MEDIUM",
            marks: 5,
          },
          {
            question: "Find the value of log5 75 − log5 3",
            type: "MEDIUM",
            marks: 2,
          },
          {
            question: "Find the value of 25 log5 8",
            type: "MEDIUM",
            marks: 4,
          },
          {
            question: "Solve the equation 9^(x+2) ⋅ 3^(x+1) = 1",
            type: "MEDIUM",
            marks: 5,
          },
          {
            question:
              "Solve the simultaneous equations: 1 + 2 log5 x = log5 7y and log7 (6x − 1) = 1 + log7 y",
            type: "HARD",
            marks: 7,
          },
        ],
      },
      {
        id: 2,
        topic: "The Binomial Theorem",
        questions: 5,
        marks: 25,
        time_minutes: 30,
        time_per_question_minutes: 30 / 5,
        questionsData: [
          {
            id: 12,
            questionsData:
              "Expand (2x + 1)^4 in descending powers of x and simplify your answer.",
            type: "EASY",
            marks: 4,
          },
          {
            id: 13,
            questionsData:
              "Expand (2x − 3)^4 in descending powers of x and simplify your answer.",
            type: "EASY",
            marks: 4,
          },
          {
            id: 14,
            questionsData:
              "Consider the expansion of (x^2/2 + ax)^6. The constant term is 960. Find the possible values of a.",
            type: "MEDIUM",
            marks: 5,
          },
          {
            id: 15,
            questionsData:
              "In the expansion of (2x + 1)^n, the coefficient of the term in x^2 is 40n, where n ∈ Z+. Find n.",
            type: "HARD",
            marks: 5,
          },
        ],
      },
      {
        id: 3,
        topic: "Exponents & Logs",
        questions: 7,
        marks: 41,
        time_minutes: 50,
        time_per_question_minutes: 50 / 7,
        questionsData: [
          {
            question:
              "Expand (2x + 1)^4 in descending powers of x and simplify your answer.",
            marks: 4,
            difficulty: "EASY",
          },
          {
            question:
              "Expand (2x - 3)^4 in descending powers of x and simplify your answer.",
            marks: 4,
            difficulty: "EASY",
          },
          {
            question:
              "Consider the expansion of (2x^2 + ax)^6. The constant term is 960. Find the possible values of a.",
            marks: 5,
            difficulty: "MEDIUM",
          },
          {
            question:
              "In the expansion of (2x + 1)^n, the coefficient of the term in x^2 is 40n, where n ∈ Z+. Find n.",
            marks: 5,
            difficulty: "HARD",
          },
          {
            question:
              "Consider the expansion of (2x^6 + qx^2)^10, where q ≠ 0. The coefficient of the term in x^40 is twelve times the coefficient of the term in x^36. Find q.",
            marks: 5,
            difficulty: "HARD",
          },
        ],
      },
      {
        id: 27,
        topic: "Proofs",
        questions: 4,
        marks: 17,
        time_minutes: 20,
        time_per_question_minutes: 20 / 4,
        questionsData: [
          {
            question: "Find the value of log10 100",
            type: "EASY",
            marks: 2,
          },
          {
            question: "Find the value of log10 50 + log10 2",
            type: "EASY",
            marks: 2,
          },
          {
            question: "Find the value of log10 4 − log10 40",
            type: "EASY",
            marks: 3,
          },
          {
            question:
              "Consider a = log63 64 × log62 63 × log61 62 × ⋯ × log2 3. Given that a ∈ Z, find the value of a.",
            type: "MEDIUM",
            marks: 5,
          },
          {
            question:
              "Write the expression 4 ln 2 − ln 8 in the form of ln k, where k ∈ Z.",
            type: "MEDIUM",
            marks: 3,
          },
          {
            question: "Solve 4 ln 2 − ln 8 = − ln (2x)",
            type: "MEDIUM",
            marks: 6,
          },
          {
            question: "Find the values of x when 2^(7x+2) = (1/9)^(2x+4)",
            type: "MEDIUM",
            marks: 5,
          },
          {
            question: "Find the value of log5 75 − log5 3",
            type: "MEDIUM",
            marks: 2,
          },
          {
            question: "Find the value of 25 log5 8",
            type: "MEDIUM",
            marks: 4,
          },
          {
            question: "Solve the equation 9^(x+2) ⋅ 3^(x+1) = 1",
            type: "MEDIUM",
            marks: 5,
          },
          {
            question:
              "Solve the simultaneous equations: 1 + 2 log5 x = log5 7y and log7 (6x − 1) = 1 + log7 y",
            type: "HARD",
            marks: 7,
          },
        ],
      },
    ],
  },
  {
    topic: "Functions",
    branches: [
      {
        id: 4,
        topic: "Properties of Functions",
        questions: 6,
        marks: 36,
        time_minutes: 40,
        time_per_question_minutes: 40 / 6,
      },
      {
        id: 5,
        topic: "Quadratics",
        questions: 7,
        marks: 51,
        time_minutes: 60,
        time_per_question_minutes: 60 / 7,
      },
      {
        id: 6,

        topic: "Rational Functions",
        questions: 5,
        marks: 48,
        time_minutes: 55,
        time_per_question_minutes: 55 / 5,
      },
      {
        id: 7,
        topic: "Exponential & Logarithmic Functions",
        questions: 6,
        marks: 38,
        time_minutes: 45,
        time_per_question_minutes: 45 / 6,
      },
      {
        id: 8,
        topic: "Transformations",
        questions: 4,
        marks: 42,
        time_minutes: 50,
        time_per_question_minutes: 50 / 4,
      },
    ],
  },
  {
    topic: "Geometry & Trigonometry",
    branches: [
      {
        id: 9,
        topic: "Geometry of Triangles",
        questions: 7,
        marks: 59,
        time_minutes: 70,
        time_per_question_minutes: 70 / 7,
      },
      {
        id: 10,
        topic: "Geometry of Circles, Sectors & Arcs",
        questions: 5,
        marks: 29,
        time_minutes: 35,
        time_per_question_minutes: 35 / 5,
      },
      {
        id: 11,
        topic: "Geometry of 3D Shapes",
        questions: 5,
        marks: 38,
        time_minutes: 45,
        time_per_question_minutes: 45 / 5,
      },
      {
        id: 12,
        topic: "Trig Identities & Solving Equations",
        questions: 6,
        marks: 37,
        time_minutes: 45,
        time_per_question_minutes: 45 / 6,
      },
      {
        id: 28,
        topic: "Trigonometric Functions & Graphs",
        questions: 6,
        marks: 71,
        time_minutes: 80,
        time_per_question_minutes: 80 / 6,
      },
    ],
  },
  {
    topic: "Statistics & Probability",
    branches: [
      {
        id: 13,
        topic: "Descriptive Statistics",
        questions: 6,
        marks: 33,
        time_minutes: 40,
        time_per_question_minutes: 40 / 6,
      },
      {
        id: 14,
        topic: "Bivariate Statistics",
        questions: 5,
        marks: 37,
        time_minutes: 45,
        time_per_question_minutes: 45 / 5,
      },
      {
        id: 15,
        topic: "Probability",
        questions: 5,
        marks: 44,
        time_minutes: 50,
        time_per_question_minutes: 50 / 5,
      },
      {
        id: 16,
        topic: "Discrete Probability Distribution",
        questions: 5,
        marks: 29,
        time_minutes: 35,
        time_per_question_minutes: 35 / 5,
      },
      {
        id: 17,
        topic: "Binomial Distribution",
        questions: 5,
        marks: 49,
        time_minutes: 55,
        time_per_question_minutes: 55 / 5,
      },
      {
        id: 18,
        topic: "Normal Distribution",
        questions: 8,
        marks: 58,
        time_minutes: 65,
        time_per_question_minutes: 65 / 8,
      },
    ],
  },
  {
    topic: "Calculus",
    branches: [
      {
        id: 19,
        topic: "Differentiation",
        questions: 7,
        marks: 54,
        time_minutes: 60,
        time_per_question_minutes: 60 / 7,
      },
      {
        id: 20,
        topic: "Tangents & Normals",
        questions: 6,
        marks: 40,
        time_minutes: 45,
        time_per_question_minutes: 45 / 6,
      },
      {
        id: 21,
        topic: "Optimization",
        questions: 4,
        marks: 39,
        time_minutes: 45,
        time_per_question_minutes: 45 / 4,
      },
      {
        id: 22,
        topic: "Interpreting Graphs",
        questions: 4,
        marks: 34,
        time_minutes: 40,
        time_per_question_minutes: 40 / 4,
      },
      {
        id: 23,
        topic: "Integration & Areas",
        questions: 9,
        marks: 63,
        time_minutes: 60,
        time_per_question_minutes: 60 / 9,
      },
      {
        id: 24,
        topic: "Integration by Substitution",
        questions: 6,
        marks: 38,
        time_minutes: 45,
        time_per_question_minutes: 45 / 6,
      },
      {
        id: 25,
        topic: "Kinematics",
        questions: 6,
        marks: 63,
        time_minutes: 70,
        time_per_question_minutes: 70 / 6,
      },
    ],
  },
];
