

import React from 'react'
import "./style.css"

const GroupCoursesPage = () => {
  return (
    <div className='groupCourses'>
      
    </div>
  )
}

export default GroupCoursesPage
