import React from "react";
import "./style.css"

const IBTopicCard = ({title , icon , info , onClick}) => {
  return (
    <div className='ibMath_topic_box' onClick={()=> onClick ? onClick() : null}>
      <div className='ibMath_topic_icon topic-icon_1'>{icon}</div>
      <h3>{title}</h3>
      <p>{info}</p>
    </div>
  );
};

export default IBTopicCard;
