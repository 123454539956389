

import React from 'react'
import "./style.css"

const ProgressBar = ({title , titleStyles , bg_container , bg_progress , progress}) => {
  return (
    <div className='progress_container'>
      <div className='prog_title' style={{
        fontSize:'15px',
        fontWeight:600,
        color:bg_progress || "#222",
        ...titleStyles

      }}>
        {title || "Hard"}
      </div>
      <div className='porgress_bar' style={{
        backgroundColor:bg_container? bg_container : "plue"
      }}>
        <div className='flag_bar'
          style={{
            backgroundColor:bg_progress?bg_progress: "red",
            width:`${progress? progress: progress}%`
          }}
        ></div>
    </div>

    </div>
  )
}

export default ProgressBar 
