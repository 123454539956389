import React, { useEffect, useState } from "react";
import "./style.css";
import SubjectCard from './../../components/subjectCard/SubjectCard';
import { subjects } from "./data";
import Subjects from "../../components/SubjectsPage/subjects";
import { useParams } from "react-router-dom";
import { groupsData } from "../../components/HomePage/GroupsSection/data";

const SubjectsPage = () => {
  const [subjects, setSubjects] = useState([]);
  const { groupId } = useParams();
  useEffect(() => {
    if (groupId) {
      setSubjects(groupsData?.filter(item => item?.group_id == groupId)[0]?.subjects)
    } else {

    }
  }, [groupId])
  return (
    <div className='subjects_page'>
      <Subjects subjects={subjects}/>
      <div className='filters container'>

        <div className="select">
          <select name='' id=''>
            <option value='John Mitchell'>John Mitchell</option>
            <option value='Elizabeth Harper'>Elizabeth Harper</option>
            <option value='David Thompson'>David Thompson</option>
          </select>
        </div>


        <div className="search">
          <input type='text' placeholder="Seach for a subject..." />
          <button>Search</button>
        </div>
      </div>

      <div className="subjects_container container">

        {
          subjects.map((item, index) => {
            return (
              <SubjectCard data={item} index={index} />
            )
          })
        }

      </div>

      <div className='container'></div>
    </div>
  );
};

export default SubjectsPage;
