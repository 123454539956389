import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import ReactSelect from "react-select";
import { subjects } from "../../components/Teachers/TeacherBanner/data";
import { Modal } from "antd";
import "./style.css";
const App = () => {
  const [state, setState] = useState({
    online: false,
    offline: false,
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [viewedCities, setViewedCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [openViewer, setOpenViewer] = useState(null);
  const [items, setItems] = useState([{ file: null, text: "" }]);

  const handleFileChange = (index, event) => {
    const newItems = [...items];
    newItems[index].file = event.target.files[0];
    newItems[index].link = URL.createObjectURL(event.target.files[0]);
    setItems(newItems);
  };

  const handleTextChange = (index, event) => {
    const newItems = [...items];
    newItems[index].text = event.target.value;
    setItems(newItems);
  };

  const addItem = () => {
    setItems([...items, { file: null, text: "" }]);
  };

  const removeItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  useEffect(() => {
    fetch("https://countriesnow.space/api/v0.1/countries")
      .then((response) => response.json())
      .then((data) => {
        const countryList = data?.data;
        setCountries(countryList);
      });
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      setCities(
        countries?.filter((item) => item?.country == selectedCountry)[0]?.cities
      );
    }
  }, [selectedCountry]);
  return (
    <div>
      <div className="container-fluid">
        <div className="row row-height">
          <div className="col-xl-4 col-lg-4 content-left">
            <div className="content-left-wrapper">
              <a href="index.html" id="logo">
                <img
                  src={require("./img/logo.png")}
                  alt=""
                  width="45"
                  height="45"
                />
              </a>

              {/* /social */}
              <div>
                <figure>
                  <img
                    src={require("./img/logo.png")}
                    alt=""
                    className="img-fluid"
                    width="270"
                    height="270"
                  />
                </figure>
                <h2>Become A teacher</h2>
                <p>You Should Fill This form to apply toGROOTINIB!</p>
                <a href="#start" className="btn_1 rounded mobile_btn yellow">
                  Start Now!
                </a>
              </div>
              <div className="copy">© 2024GROOTINIB</div>
            </div>
            {/* /content-left-wrapper */}
          </div>
          {/* /content-left */}
          <div className="col-xl-8 col-lg-8 content-right" id="start">
            <div id="wizard_container">
              <div id="top-wizard">
                <span id="location"></span>
                <div id="progressbar"></div>
              </div>
              {/* /top-wizard */}
              <form id="wrapped" method="post" encType="multipart/form-data">
                <input id="website" name="website" type="text" value="" />
                {/* Leave for security protection, read docs for details */}
                <div id="middle-wizard">
                  <div className="step">
                    <h2 className="section_title">Presentation</h2>
                    <h3 className="main_question">Personal info</h3>
                    <div className="form-group add_top_30">
                      <label htmlFor="name">First and Last Name</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="form-control required"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email Address</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control required"
                      />
                    </div>
                   
                    <div className="form-group">
                      <label htmlFor="phone">Phone</label>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        className="form-control required"
                      />
                    </div>

                    <div className="input_group">
                      <label>Bio *</label>

                      <ReactQuill
                        theme="snow"
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline", "strike"],
                            ["blockquote", "code-block"],
                            [{ header: 1 }, { header: 2 }],
                            [{ list: "ordered" }, { list: "bullet" }],
                            [{ script: "sub" }, { script: "super" }],
                            [{ indent: "-1" }, { indent: "+1" }],
                            [{ direction: "rtl" }],
                            [{ size: ["small", true, "large", "huge"] }],
                            ["link", "image"],
                            [{ color: [] }, { background: [] }],
                            [{ font: [] }],
                            [{ align: [] }],
                          ],
                        }}
                        style={{
                          minHeight: "100px",
                          color: "black",
                          maxWidth: "100%",
                          width: "100%",
                        }}
                      />
                    </div>

                    <div className="input_group">
                      <label>Education *</label>

                      <ReactQuill
                        theme="snow"
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline", "strike"],
                            ["blockquote", "code-block"],
                            [{ header: 1 }, { header: 2 }],
                            [{ list: "ordered" }, { list: "bullet" }],
                            [{ script: "sub" }, { script: "super" }],
                            [{ indent: "-1" }, { indent: "+1" }],
                            [{ direction: "rtl" }],
                            [{ size: ["small", true, "large", "huge"] }],
                            ["link", "image"],
                            [{ color: [] }, { background: [] }],
                            [{ font: [] }],
                            [{ align: [] }],
                          ],
                        }}
                        style={{
                          minHeight: "100px",
                          color: "black",
                          maxWidth: "100%",
                          width: "100%",
                        }}
                      />
                    </div>


                    <div className="input_group">
                      <label>Expertise *</label>

                      <ReactQuill
                        theme="snow"
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline", "strike"],
                            ["blockquote", "code-block"],
                            [{ header: 1 }, { header: 2 }],
                            [{ list: "ordered" }, { list: "bullet" }],
                            [{ script: "sub" }, { script: "super" }],
                            [{ indent: "-1" }, { indent: "+1" }],
                            [{ direction: "rtl" }],
                            [{ size: ["small", true, "large", "huge"] }],
                            ["link", "image"],
                            [{ color: [] }, { background: [] }],
                            [{ font: [] }],
                            [{ align: [] }],
                          ],
                        }}
                        style={{
                          minHeight: "100px",
                          color: "black",
                          maxWidth: "100%",
                          width: "100%",
                        }}
                      />
                    </div>
                    <label>Gender</label>
                    <div className="form-group radio_input">
                      <label className="container_radio me-3">
                        Male
                        <input
                          type="radio"
                          name="gender"
                          value="Male"
                          className="required"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="container_radio">
                        Female
                        <input
                          type="radio"
                          name="gender"
                          value="Female"
                          className="required"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    {/* <div className="form-group add_bottom_30 add_top_20">
                      <label>
                        Upload Certificates
                        <br />
                        <small>(File accepted: .pdf, .png, .jpg)</small>
                      </label>
                      <div className="fileupload">
                        <input
                          type="file"
                          name="fileupload"
                          accept=".pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          className="required"
                        />
                      </div>
                    </div> */}
                    <div className="form-group add_bottom_30 add_top_20">
                      <label>
                        Upload IB Certificates
                        <br />
                        <small>(File accepted: .pdf, .png, .jpg)</small>
                      </label>
                      <div className="fileupload">
                        <input
                          type="file"
                          name="fileupload"
                          className="required"
                          multiple
                        />
                      </div>
                    </div>

                    <div className="form-group add_bottom_30 add_top_20">
                      <label>
                        Upload Legal Document
                        <br />
                      </label>
                      <div className="fileupload">
                        <input
                          type="file"
                          name="fileupload"
                          className="required"
                        />
                      </div>
                    </div>
                    <div className="form-group add_bottom_30 add_top_20">
                      <label>
                        Upload Video
                        <br />
                      </label>
                      <div className="fileupload">
                        <input
                          type="file"
                          name="fileupload"
                          className="required"
                        />
                      </div>
                    </div>
                  </div>
                  <span
                    className="btn btn-primary"
                    onClick={() => setOpenViewer(true)}
                  >
                    Another Certificates
                  </span>
                  <table id="styled-table">
                    <thead>
                      <tr>
                        <th>Certificate</th>
                        <th>Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items?.map((item, index) =>
                        item?.link && item?.link?.length ? (
                          <tr key={index}>
                            <td>
                              <img
                                onClick={() =>
                                  window.open(item?.link, "_blanck")
                                }
                                width={50}
                                src={item?.link}
                                alt="certificate"
                              />
                            </td>
                            <td>{item?.text}</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  </table>
                  {/* /step*/}
                  <div className="form-group">
                    <label htmlFor="email">IB Code</label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      className="form-control required"
                    />
                  </div>
                  <div>
                    <div className="checkbox-group">
                      <label>
                        <input
                          type="checkbox"
                          name="online"
                          checked={state.online}
                          onChange={handleCheckboxChange}
                        />
                        Online
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          name="offline"
                          checked={state.offline}
                          onChange={handleCheckboxChange}
                        />
                        Offline
                      </label>
                    </div>
                  </div>
                  {/* /Start Branch ============================== */}
                  {selectedCities &&
                  selectedCities?.length &&
                  selectedCountry &&
                  selectedCountry?.length ? (
                    <div className="xCountry">
                      <span>{selectedCountry}</span> *{" "}
                      <span className="xContent">
                        {viewedCities?.map((item) => (
                          <span style={{ display: "flex", gap: "10px" }}>
                            <span> {item} </span>
                            <span
                              onClick={() => {
                                setViewedCities(
                                  viewedCities?.filter(
                                    (c_item) => c_item != item
                                  )
                                );
                                setSelectedCities(
                                  selectedCities?.filter(
                                    (c_item) => c_item?.value != item
                                  )
                                );
                              }}
                              className="text-danger"
                              style={{ cursor: "pointer" }}
                            >
                              x
                            </span>
                          </span>
                        ))}
                      </span>
                    </div>
                  ) : null}
                  <div className="step" data-state="branchtype">
                    {state.offline ? (
                      <>
                        {" "}
                        <h2
                          style={{ marginTop: "10px" }}
                          className="section_title"
                        >
                          Country
                        </h2>
                        <>
                          <ReactSelect
                            onChange={(e) => {
                              setSelectedCountry(e?.value);
                              setViewedCities([]);
                              setSelectedCities([]);
                            }}
                            options={countries.map((country) => ({
                              label: country?.country,
                              value: country?.country,
                            }))}
                          ></ReactSelect>
                          {cities && cities?.length ? (
                            <>
                              <h2
                                style={{ marginTop: "10px" }}
                                className="section_title"
                              >
                                City
                              </h2>
                              <ReactSelect
                                isMulti={true}
                                value={selectedCities}
                                onChange={(e) => {
                                  console.log(e);
                                  setViewedCities(
                                    e?.map((item) => item?.value)
                                  );
                                  setSelectedCities(e);
                                  // setSelectedCities([]);
                                }}
                                options={cities.map?.((city) => ({
                                  label: city,
                                  value: city,
                                }))}
                              />
                            </>
                          ) : null}
                        </>
                      </>
                    ) : null}
                    <div className="step" data-state="branchtype">
                      <h2
                        style={{ marginTop: "10px" }}
                        className="section_title"
                      >
                        Subjects
                      </h2>
                      <ReactSelect
                        isMulti={true}
                        options={subjects.map((city) => ({
                          label: city,
                          value: city,
                        }))}
                      />
                    </div>

                    {/* <div className="form-group">
                      <label className="container_radio version_2">
                        Instructor
                        <input
                          type="radio"
                          name="availability"
                          value="Full-time"
                          className="required"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="container_radio version_2">
                        IB Teacher
                        <input
                          type="radio"
                          name="availability"
                          value="Part-time"
                          className="required"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div> */}
                  </div>

                  {/* /Work Availability > Full-time ============================== */}
                  {/* <div className="branch" id="Full-time">
                    <div className="step" data-state="end">
                      <h2 className="section_title">Work Availability</h2>
                      <h3 className="main_question">
                        Additional info about "Full Time" availability
                      </h3>
                      <div className="form-group add_bottom_30">
                        <label>Minimum salary? (in USD)</label>
                        <label htmlFor="minimum_salary_full_time">
                          Choose a range
                        </label>
                        <div className="styled-select">
                          <select
                            className="form-control required"
                            id="minimum_salary_full_time"
                            name="minimum_salary_full_time"
                          >
                            <option value="">Choose a range</option>
                            <option value="&lt;10k">&lt;10k</option>
                            <option value="10-15k">10-15k</option>
                            <option value="15-20k">15-20k</option>
                            <option value="20-25k">20-25k</option>
                            <option value="25-30k">25-30k</option>
                            <option value="30-35k">30-35k</option>
                            <option value="35-40k">35-40k</option>
                            <option value="45-50k">45-50k</option>
                            <option value="&gt;50k">&gt;50k</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group add_bottom_30">
                        <label>How soon would you be looking to start?</label>
                        <label htmlFor="start_availability_full_time">
                          Choose your availability
                        </label>
                        <div className="styled-select">
                          <select
                            className="form-control required"
                            id="start_availability_full_time"
                            name="start_availability_full_time"
                          >
                            <option value="">Choose your availability</option>
                            <option value="I can start immediately">
                              I can start immediately
                            </option>
                            <option value="I need to give 2–4 weeks notice">
                              I need to give 2–4 weeks notice
                            </option>
                            <option value="I am passively browsing">
                              I am passively browsing
                            </option>
                            <option value="I will be available in 2-3 months">
                              I will be available in 2-3 months
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* /Work Availability > Part-time ============================== */}
                  {/* <div className="branch" id="Part-time">
                    <div className="step" data-state="end">
                      <h2 className="section_title">Work Availability</h2>
                      <h3 className="main_question">
                        Additional info about "Part-time" availability
                      </h3>
                      <div className="form-group add_bottom_30">
                        <label>
                          What days of the week do you prefer to work?
                        </label>
                        <label htmlFor="preferred_days_part_time">
                          Choose a range
                        </label>
                        <div className="styled-select">
                          <select
                            className="form-control required"
                            id="preferred_days_part_time"
                            name="preferred_days_part_time"
                          >
                            <option value="">Choose a range</option>
                            <option value="Monday-Friday">Monday-Friday</option>
                            <option value="Saturday-Sunday">
                              Saturday-Sunday
                            </option>
                            <option value="Specific days (specify in comments)">
                              Specific days (specify in comments)
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group add_bottom_30">
                        <label>How soon would you be looking to start?</label>
                        <label htmlFor="start_availability_part_time">
                          Choose your availability
                        </label>
                        <div className="styled-select">
                          <select
                            className="form-control required"
                            id="start_availability_part_time"
                            name="start_availability_part_time"
                          >
                            <option value="">Choose your availability</option>
                            <option value="I can start immediately">
                              I can start immediately
                            </option>
                            <option value="I need to give 2–4 weeks notice">
                              I need to give 2–4 weeks notice
                            </option>
                            <option value="I am passively browsing">
                              I am passively browsing
                            </option>
                            <option value="I will be available in 2-3 months">
                              I will be available in 2-3 months
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* /Work Availability > Freelance-Contract ============================== */}
                  {/* <div className="branch" id="Freelance-Contract">
                    <div className="step" data-state="end">
                      <h2 className="section_title">Work Availability</h2>
                      <h3 className="main_question">
                        Additional info about "Freelance/Contract" availability
                      </h3>
                      <div className="form-group add_bottom_30">
                        <label>Do you require an upfront fee?</label>
                        <label htmlFor="upfront_fee_freelance_contract">
                          Choose your preference
                        </label>
                        <div className="styled-select">
                          <select
                            className="form-control required"
                            id="upfront_fee_freelance_contract"
                            name="upfront_fee_freelance_contract"
                          >
                            <option value="">Choose your preference</option>
                            <option value="Yes, I require an upfront fee">
                              Yes, I require an upfront fee
                            </option>
                            <option value="No, I do not require an upfront fee">
                              No, I do not require an upfront fee
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group add_bottom_30">
                        <label>How soon would you be looking to start?</label>
                        <label htmlFor="start_availability_freelance_contract">
                          Choose your availability
                        </label>
                        <div className="styled-select">
                          <select
                            className="form-control required"
                            id="start_availability_freelance_contract"
                            name="start_availability_freelance_contract"
                          >
                            <option value="">Choose your availability</option>
                            <option value="I can start immediately">
                              I can start immediately
                            </option>
                            <option value="I need to give 2–4 weeks notice">
                              I need to give 2–4 weeks notice
                            </option>
                            <option value="I am passively browsing">
                              I am passively browsing
                            </option>
                            <option value="I will be available in 2-3 months">
                              I will be available in 2-3 months
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* /step */}
                </div>
                {/* /middle-wizard */}

                <div id="bottom-wizard">
                  {/* <button type="button" name="backward" className="backward">
                    Backward
                  </button>
                  <button type="button" name="forward" className="forward">
                    Forward
                  </button> */}
                  <button type="submit" name="process" className="submit">
                    Submit
                  </button>
                </div>
                {/* /bottom-wizard */}
              </form>
            </div>
            {/* /Wizard container */}
          </div>
        </div>
        {/* /row */}
      </div>
      <Modal
        style={{ width: "90%" }}
        open={openViewer}
        onCancel={() => setOpenViewer(false)}
        closable={true}
      >
        <div style={{ width: "min(650px, 100%)" }}>
          {items.map((item, index) => (
            <div key={index} className="input-item">
              <div className="item-input">
                <label htmlFor="">Image Certificate</label>
                <input
                  type="file"
                  onChange={(event) => handleFileChange(index, event)}
                />
              </div>
              <div className="item-input">
                <label htmlFor="">Name Of Certificate</label>
                <input
                  type="text"
                  value={item.text}
                  onChange={(event) => handleTextChange(index, event)}
                  placeholder="Enter text"
                />
              </div>
              <button type="button" onClick={() => removeItem(index)}>
                Remove
              </button>
            </div>
          ))}
          <button className="btn btn-success" type="button" onClick={addItem}>
            Add Item
          </button>
        </div>
      </Modal>
      {/* /container-fluid */}
    </div>
  );
};

export default App;
