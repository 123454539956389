import React, {useState} from "react";
import "./style.css";
import FormGroup from "./../../components/FormGroup/FormGroup";
import ReactSelect from "react-select";
import CheckBox from "./../../components/CheckBox/CheckBox";

const SetPassword = () => {

  const finalExams = [
    "November 2023",
    "May 2024",
    "November 2024",
    "May 2025",
    "November 2025",
    "May 2026",
    "November 2026",
  ];

  const chekcboxes = [
    {
      group: "Mathematics",
      subjects: ["Math AA SL", "Math AA HL", "Math AI SL", "Math AI HL"],
    },

    {
      group: "Biology",
      subjects: [
        "Biology SL - 2024",
        "Biology HL - 2024",
        "Biology SL - 2025",
        "Biology HL - 2025",
      ],
    },
    {
      group: "Chemistry",
      subjects: [
        "Chemistry SL - 2024",
        "Chemistry HL - 2024",
        "Chemistry SL - 2025",
        "Chemistry HL - 2025",
      ],
    },
    {
      group: "Physics",
      subjects: [
        "Physics SL - 2024",
        "Physics HL - 2024",
        "Physics SL - 2025",
        "Physics HL - 2025",
      ],
    },
    {
      group: "Environmental Systems and Societies (ESS)",
      subjects: ["ESS SL - 2025", "ESS SL - 2026", "ESS HL - 2026"],
    },
    {
      group: "Humanities and Social Sciences",
      subjects: [
        "Psych SL",
        "Psych HL",
        "Econ SL",
        "Econ HL",
        "Business Mgmt SL",
        "Business Mgmt HL",
      ],
    },
    {
      group: "Language and Literature",
      subjects: [
        "English LangLit SL",
        "English LangLit HL",
        "English Lit SL",
        "English Lit HL",
      ],
    },
  ];

  const [selectedGroup, setSelectedGroup] = useState([]);
  const [selectedFinalsExams, setSelectedFinalsExams] = useState([]);



   const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'transparent', // Background color of the control (the dropdown box)
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#ffffff', // Background color of the dropdown menu
      paddingInline:"20px"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#eaeaea' :  state.isFocused
      ? '#f3f3f3' // Background color when an option is hovered
      : '#ffffff', // Default background color, // Background color of each option
      color: '#000000', // Text color of each option
      marginBlock:"5px",
      borderRadius:"10px"

    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'transparent', // Background color of selected items in multi-select
      border:"1px solid green"
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#000000', // Text color of selected items in multi-select
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#ff0000', // Color of the remove icon in multi-select
    }),
  };
  

  return (
    <div
      className='signup_page  container'
      style={{
        marginBlock: "100px",
      }}
    >
      <h2 className='title fw-bolder' data-aos='zoom-in'>
        Set Password
      </h2>

      <div className='signup_content set_password_content'>
        <div className='wave_1'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='1000'
            height='900'
            viewBox='0 0 1920 1080'
          >
            <path
              fill='hsla(116, 74.70%, 82.90%, 0.84)'
              d='M724 4c2.333 108.213-54.232 210.731-130.154 286.343-75.922 75.611-211.058 99.029-325.376 167.326C154.152 525.967-13.913 690.72-92.063 700.13c-78.15 9.41-24.934-105.671-108.367-185.998-83.432-80.327-318.856-193.12-392.228-295.962-73.371-102.842-73.27-203.876-48-321.092C-615.387-220.14-539.13-409.731-441.03-485.131c98.1-75.4 266.282-80.687 388.968-70.194 122.687 10.493 241.831 100.42 347.15 133.152 105.318 32.732 213.274-7.788 284.76 63.24C651.331-287.903 721.666-104.213 724 4c2.333 108.213-54.232 210.731-130.154 286.343'
            ></path>
          </svg>
        </div>

        <FormGroup
          gap={10}
          rowCount={1}
          Buttons={
            <button
              className='green_btn w-100'
              onClick={() => (window.location.href = "/TeacherRegister")}
            >
              Submit
            </button>
          }
        >
          <FormGroup.Input required={true} label={"Email"} placeholder='' />
          <FormGroup.Input required={true} placeholder='' label={"Passwrod"} />
          <FormGroup.Input
            required={true}
            placeholder=''
            label={"Confirm Passwrod"}
          />
          <div className='d-block'>
            <label htmlFor=''>
              Select who you are <span>(*)</span>
            </label>
            <ReactSelect
              options={["Student", "Teacher"].map((item) => ({
                value: item,
                label: item,
              }))}
              styles={customStyles}
            />
          </div>
          <div className='d-block'>
            <label htmlFor=''>
              Which group(s) do you take from these groups?
              <span>(*)</span>
            </label>
            <ReactSelect
              options={chekcboxes.map((item) => ({
                value: item.group,
                label: item.group,
                item:item
              }))}
              isMulti
              onChange={(e) => {
                console.log(e);
                setSelectedGroup(e);
              }}
              styles={customStyles}

            />
          </div>

          <div>
            <div className='questions_container'>
              {selectedGroup?.map((group, index) => {
                return (
                  <div key={index} className='checkboxes_container'>
                    <h4 className='fs-6 '>{group.label}</h4>
                    <div>
                      {group?.item?.subjects?.map((subject, index) => {
                        return (
                          <div key={index} className='checkbox_item my-2'>
                            <CheckBox id={subject} checked={true} />
                            <label
                              className=''
                              style={{fontSize: "15px"}}
                              htmlFor={subject}
                            >
                              {subject}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='d-block'>
            <label htmlFor=''>
              When will you sit your final IB exams?
              <span>(*)</span>
            </label>
            <ReactSelect
              options={finalExams.map((item) => ({
                value: item,
                label: item,
              }))}
              isMulti
              value={selectedFinalsExams}
              onChange={(e) => {
                console.log(e);
                setSelectedFinalsExams(e.value);
              }}
              styles={customStyles}

            />
          </div>
        </FormGroup>
      </div>
    </div>
  );
};

export default SetPassword;
