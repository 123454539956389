import React from "react";
import "./style.css"

const CheckBoxContent = ({checked , onChange , title , id }) => {
  return (
    /* From Uiverse.io by WhiteNervosa */
    <div class='customCheckBoxHolder'>
      <input onChange={onChange}  checked={checked} type='checkbox' id={id} className='customCheckBoxInput' />
      <label for={id} className='customCheckBoxWrapper'>
        <div className='customCheckBox'>
          <div className='inner'>{title? title :"Toggle Me"}</div>
        </div>
      </label>
    </div>
  );
};

export default CheckBoxContent;
